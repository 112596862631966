import "./DataInput.css";
import React, { useState } from 'react';
import { useEffect, Component, useLayoutEffect } from 'react';
import { Link, json, useParams, } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UploadExcel from "./UploadExcel";

function DataInput(props) {

    return (
        <div>
            <div>
                <UploadExcel/>
            </div>
        </div >

    );
}

export default DataInput;