// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  min-width: auto;
  top: 100px;
  left: 500px;
  border-bottom: solid 1px gray;
  /* 백그라운드 칼라 추후 삭제 */
  /* background-color:aquamarine; */
}

.title-box {
  display: flex;
  margin-top: 10px;
  margin-left: 200px;
  margin-bottom: 10px;
}

.title-underline {
  background: linear-gradient(90.37deg, #f68912 35.18%, #1acc8f 115.32%);
  /* background-color: black; */
  height: 4px;
  width: 465px;
  margin-left: 200px;
} 

`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,UAAU;EACV,WAAW;EACX,6BAA6B;EAC7B,mBAAmB;EACnB,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,sEAAsE;EACtE,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".header {\r\n  display: flex;\r\n  min-width: auto;\r\n  top: 100px;\r\n  left: 500px;\r\n  border-bottom: solid 1px gray;\r\n  /* 백그라운드 칼라 추후 삭제 */\r\n  /* background-color:aquamarine; */\r\n}\r\n\r\n.title-box {\r\n  display: flex;\r\n  margin-top: 10px;\r\n  margin-left: 200px;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.title-underline {\r\n  background: linear-gradient(90.37deg, #f68912 35.18%, #1acc8f 115.32%);\r\n  /* background-color: black; */\r\n  height: 4px;\r\n  width: 465px;\r\n  margin-left: 200px;\r\n} \r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
