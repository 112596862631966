// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DrugResponsePage_drugResponsePageMain__E28hW {
    width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/components/graphComponents/DrugResponsePage.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd","sourcesContent":[".drugResponsePageMain {\n    width: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drugResponsePageMain": `DrugResponsePage_drugResponsePageMain__E28hW`
};
export default ___CSS_LOADER_EXPORT___;
