import styles from "./DashBoard.module.css";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';

//이미지 파일 임포트
import document_icon from '../../img/common/document_icon.png';
import stock_return from '../../img/common/stock_return.png';
import organoidStoreStatus from '../../img/common/organoidStoreStatus.png';

//컴포넌트 임포트
import CircularBar from "./CircularBar";
import BankingStatistics from './ManagementSubComponent/BankingStatistics'

function DashBoard() {
    const { t } = useTranslation();
    const [draftList, setDraftList] = useState([]);
    const [returnRate, setReturnRate] = useState();
    const [organoidStatus, setOrganoidStatus] = useState();
    const list = {
        Banking_Info: "Working Stock",
        Organoid_Info: "Master Stock",
        Patient_Info: "Patient info",
        StorageList: "Storage",
    }

    const formatLineName = (line, box) => {
        return `${line}-${box}`;
    };

    const renderCircularBars = (organoidStatus, vesselNo, masterYN) => {
        const filteredData = organoidStatus.filter(item => item.Vessel === vesselNo && item.Master === masterYN);

        // Line 별로 데이터 그룹화
        const groupedByLine = {};
        filteredData.forEach(item => {
            if (!groupedByLine[item.Line]) {
                groupedByLine[item.Line] = [];
            }
            groupedByLine[item.Line].push(item);
        });

        const result = [];

        Object.values(groupedByLine).forEach(items => {
            for (let i = 0; i < items.length; i += 5) {
                const chunk = items.slice(i, i + 5);
                result.push(
                    <div key={`${items[0].Line}-${i}`} className={styles.storageStatContentGrid}>
                        {chunk.map((data, index) => (
                            // <React.Fragment key={index}>
                            <CircularBar key={index} percentage={data['count']} title={formatLineName(data.Line, data.Box)} />
                            // </React.Fragment>
                        ))}
                    </div>
                );
            }
        });

        return result;
    };



    const formatAuth = (auth) => {
        switch (auth) {
            case 1:
                return "읽기"
            case 2:
                return "쓰기"
            case 3:
                return "수정"
            case 4:
                return "삭제"
            case 5:
                return "반입"
            case 6:
                return "반출"
        }
    }

    const formatDate = (dateString) => {
        if (!dateString) {
            return null;
        }
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    //기안 리스트 가져오기
    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/getDraftList", reqOption)
            .then((response) => response.json())
            .then(data => {
                setDraftList(data);
            });
    }, []);

    //오가노이드 보관 현황 가져오기
    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/getOrganoidStatus", reqOption)
            .then((response) => response.json())
            .then(data => {
                setOrganoidStatus(data);
            });
    }, []);

    //반납률 가져오기
    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/getReturnRate", reqOption)
            .then((response) => response.json())
            .then(data => {
                setReturnRate(data);
            });
    }, []);

    return (
        <div className={styles.threeGrid}>
            <BankingStatistics vessel='1'></BankingStatistics>
            <BankingStatistics vessel='2'></BankingStatistics>
            <BankingStatistics vessel='3'></BankingStatistics>
        </div>
        // <>
        //     <div className={styles.container}>
        //         <div className={styles.leftBox}>
        //             <div className={styles.기안함}>
        //                 <img src={document_icon} className={styles.document_icon}></img>
        //                 &nbsp;&nbsp;<Link to="/draftpage">{t('기안함')}</Link>
        //             </div>
        //             <div className={styles.draftTable_Dashboard}>
        //                 <table>
        //                     <thead>
        //                         <tr>
        //                             <th>{t('기안 ID')}</th>
        //                             <th>{t('기안 등록 일자')}</th>
        //                             <th>{t('결재 일자')}</th>
        //                             <th>{t('기안자')}</th>
        //                             <th>{t('결재자')}</th>
        //                             <th>{t('기안대상')}</th>
        //                             <th>{t('대상키값')}</th>
        //                             <th>{t('권한')}</th>
        //                             <th>{t('결재상태')}</th>
        //                         </tr>
        //                     </thead>
        //                     <tbody>
        //                         {draftList.map(draft => (
        //                             <tr key={draft.draftDocumentId}>
        //                                 <td>{draft.draftDocumentId}</td>
        //                                 <td>{formatDate(draft.regTime)}</td>
        //                                 <td>{formatDate(draft.draftTime)}</td>
        //                                 <td>{draft.draftUserName}</td>
        //                                 <td>{draft.authorizedPersonName}</td>
        //                                 <td>{list[draft.draftCategory]}</td>
        //                                 <td>{draft.targetKey}</td>
        //                                 <td>{formatAuth(draft.authLevel)}</td>
        //                                 <td>{draft.authorizedStatus}</td>
        //                             </tr>
        //                         ))}
        //                     </tbody>
        //                 </table>
        //             </div>
        //         </div>
        //     </div >
        //     <div className={styles.스탁보관현황container}>
        //         <div className={styles.leftStorageStatus}>
        //             <div className={styles.storageStatTitleContainer}>
        //                 <div className={styles.storageStatTitle}>
        //                     <img src={organoidStoreStatus} className={styles.document_icon}></img>
        //                     오가노이드 보관 현황
        //                 </div>
        //                 <div>

        //                 </div>
        //                 <div className={styles.storageStatTitleMasterStock}>
        //                     Master Stock
        //                 </div>
        //             </div>
        //             <div className={styles.storageStatContent}>
        //                 <div className={styles.vesselName}>
        //                     Vessel - 1
        //                 </div>
        //                 {organoidStatus && renderCircularBars(organoidStatus, '1', '1')}
        //             </div>
        //         </div>
        //         <div className={styles.rightStorageStatus}>
        //             <div className={styles.storageStatTitleContainer}>
        //                 <div className={styles.storageStatTitle}>
        //                     <img src={organoidStoreStatus} className={styles.document_icon}></img>
        //                     오가노이드 보관 현황
        //                 </div>
        //                 <div>

        //                 </div>
        //                 <div className={styles.storageStatTitleMasterStock}>
        //                     Master Stock
        //                 </div>
        //             </div>
        //             <div className={styles.storageStatContent}>
        //                 <div className={styles.vesselName}>
        //                     Vessel - 2
        //                 </div>
        //                 {organoidStatus && renderCircularBars(organoidStatus, '2', '1')}
        //             </div>
        //         </div>
        //         <div className={styles.leftStorageStatus}>
        //             <div className={styles.storageStatTitleContainer}>
        //                 <div className={styles.storageStatTitle}>
        //                     <img src={organoidStoreStatus} className={styles.document_icon}></img>
        //                     오가노이드 보관 현황
        //                 </div>
        //                 <div>

        //                 </div>
        //                 <div className={styles.storageStatTitleMasterStock}>
        //                     Working Stock
        //                 </div>
        //             </div>
        //             <div className={styles.storageStatContent}>
        //                 <div className={styles.vesselName}>
        //                     Vessel - 1
        //                 </div>
        //                 {organoidStatus && renderCircularBars(organoidStatus, '1', '0')}
        //             </div>
        //         </div>
        //         <div className={styles.leftStorageStatus}>
        //             <div className={styles.storageStatTitleContainer}>
        //                 <div className={styles.storageStatTitle}>
        //                     <img src={organoidStoreStatus} className={styles.document_icon}></img>
        //                     오가노이드 보관 현황
        //                 </div>
        //                 <div>

        //                 </div>
        //                 <div className={styles.storageStatTitleMasterStock}>
        //                     Working Stock
        //                 </div>
        //             </div>
        //             <div className={styles.storageStatContent}>
        //                 <div className={styles.vesselName}>
        //                     Vessel - 2
        //                 </div>
        //                 {organoidStatus && renderCircularBars(organoidStatus, '2', '0')}
        //             </div>
        //         </div>
        //     </div>
        // </>
    );
}

export default DashBoard;
