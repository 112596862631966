import React from "react";
import "./Footer.css";
import img1 from "../img/footer-g.png";
import img2 from "../img/footer-r.png";
import img3 from "../img/footer-a.png";
import img4 from "../img/footer-d.png";
import img5 from "../img/footer-i.png";
import img6 from "../img/footer-a2.png";
import img7 from "../img/footer-n.png";
import img8 from "../img/footer-t.png";

function Footer() {
    return (
        <div className="foot">
            <div className="company-name">
                <div className="x48">
                    <div className="x45">
                        <div className="x44">
                            <div className="flex-col">
                                <div className="x43"></div>
                                <div className="flex-row">
                                    <img className="x169" src={img1} alt="g" />
                                    <img className="x162" src={img2} alt="r" />
                                    <img className="x163" src={img3} alt="a" />
                                </div>
                            </div>
                            <img className="x165" src={img4} alt="d" />
                            <img className="x166" src={img5} alt="i" />
                            <img className="x164" src={img6} alt="a2" />
                            <img className="x167" src={img7} alt="n" />
                            <img className="x168" src={img8} alt="t" />
                        </div>
                    </div>
                    <div className="x47"></div>
                </div>
            </div>
            <p className="copyright-2022-gra">
                4th floor, PNS Homes Tower, 6, Jeongui-ro 7-gil, Songpa-gu, Seoul, Republic of Korea, 05835<br /><br />

                Tel +82.2.6483.1400 Fax +82.2.6483.3400<br /><br />

                Email gbcc@gradiantbio.com<br /><br />
                Copyright © 2022 Gradiant Bioconvergence All rights reserved.
            </p>
        </div>
    );
}

export default Footer;