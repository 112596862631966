import styles from "./BasicInfo.module.css";
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ImageLightMicroscope from "./ImageLightMicroscope";
import ImagePathologyPicture from "./ImagePathologyPicture";
import Graph from "./Graph";
import EditBasicInfoModal from "./EditBasicInfoModal";
import { useTranslation } from 'react-i18next';

//components
import OrganoidInfoTab from "./OrganoidInfoTab";
import PatientInfoTab from "./PatientInfoTab";
import ImageComponent from "../../components/ImageComponent";
import DrugResponsePage from "../../components/graphComponents/DrugResponsePage"

//모달창
import Button from 'react-bootstrap/Button';

function BasicInfo(props) {
    const { t } = useTranslation();
    const { tmpKey } = useParams();
    const [graphData, setGraphData] = useState([{
    }]);
    function hashMark(str) {
        return (String(str).replace('hashmark', '#'));
    }

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                name: tmpKey
            }),

        }

        fetch("/api/getGraphData", reqOption)
            .then((response) => response.json())
            .then(data => {
                setGraphData(data);
            });
    }, []);

    return (
        <div className={styles.rightTabBox}>
            <div className={styles.twoPartBox}>
                <OrganoidInfoTab />
                <PatientInfoTab />
            </div>
            <div className={styles.titleOutBox}>
                <div className={styles.title}>
                    Image
                </div>
            </div>
            <div className={styles.twoPartBox}>
                <ImageComponent type='Image' />
                <ImageComponent type='Histology' />
            </div>
            <div className={styles.titleOutBox}>
                <div className={styles.title}>
                    Drug response
                </div>
            </div>
            <div className={styles.graphBox}>
                <DrugResponsePage />
            </div>
        </div>
    );
}

export default BasicInfo;