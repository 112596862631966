// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageComponent_outBox__XJYY\\+ {
    margin-left: 40px;
    margin-top: 30px;
}

.ImageComponent_title__MEZFm {
    width: 90%;
    padding-bottom: 15px;
    border-bottom: solid 4px rgb(211, 211, 211);
    font-weight: 600;
    color: #7B878C;
}

.ImageComponent_imageContainer__gtPpP {
    margin-top: 10px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ImageComponent_pictureName__aBOWI {
    margin-left: 10%;
}

.ImageComponent_editButton__rvQiY {
    padding-left: 15px;
    padding-right: 15px;
    border: solid 1px gray;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5%;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.3);
}`, "",{"version":3,"sources":["webpack://./src/components/ImageComponent.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,oBAAoB;IACpB,2CAA2C;IAC3C,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,4CAA4C;AAChD","sourcesContent":[".outBox {\n    margin-left: 40px;\n    margin-top: 30px;\n}\n\n.title {\n    width: 90%;\n    padding-bottom: 15px;\n    border-bottom: solid 4px rgb(211, 211, 211);\n    font-weight: 600;\n    color: #7B878C;\n}\n\n.imageContainer {\n    margin-top: 10px;\n    width: 90%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.pictureName {\n    margin-left: 10%;\n}\n\n.editButton {\n    padding-left: 15px;\n    padding-right: 15px;\n    border: solid 1px gray;\n    border-radius: 5px;\n    cursor: pointer;\n    margin-left: 5%;\n    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outBox": `ImageComponent_outBox__XJYY+`,
	"title": `ImageComponent_title__MEZFm`,
	"imageContainer": `ImageComponent_imageContainer__gtPpP`,
	"pictureName": `ImageComponent_pictureName__aBOWI`,
	"editButton": `ImageComponent_editButton__rvQiY`
};
export default ___CSS_LOADER_EXPORT___;
