import React, { useState, useEffect } from "react";
import styles from "./InstitutionInfo.module.css";

//이미지 임포트
import institutionInfoIcon from "../../../img/common/institutionInfoIcon.png";
import editIcon from "../../../img/common/editIcon.png";

function InstitutionInfo(props) {
    const [institutionInfo, setInstitutionInfo] = useState();
    const [totalUserNum, setTotalUserNum] = useState();
    const [isEditing, setIsEditing] = useState(false); // 편집 모드 상태 관리

    // 편집 상태의 값을 관리하기 위한 상태
    const [editValues, setEditValues] = useState({
        businessRegNo: "",
        telNo: "",
        companyAddress: "",
        managerName: ""
    });

    const handleEditClick = () => {
        setIsEditing(true);
        // 현재 기관 정보로 편집 값 초기화
        setEditValues({
            businessRegNo: institutionInfo?.businessRegNo || "",
            telNo: institutionInfo?.telNo || "",
            companyAddress: institutionInfo?.companyAddress || "",
            managerName: institutionInfo?.managerName || "",
            bizCertFileName: institutionInfo?.bizCertFileName || ''
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditValues((prev) => ({ ...prev, [name]: value }));
    };

    const handleSaveClick = () => {
        // 서버에 데이터 업데이트 요청
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(editValues)
        };

        fetch("/api/updateInstitutionInfo", reqOption)
            .then((response) => response.json())
            .then(data => {
                // 업데이트된 정보로 상태 업데이트
                setInstitutionInfo(editValues);
                setIsEditing(false); // 편집 모드 종료
            });
    };

    const handleImageDownload = () => {
        const imageUrl = process.env.PUBLIC_URL + '/files/' + institutionInfo?.bizCertFileName; // tmp 변수에 저장된 이미지 파일 이름 (예: "tmp.png")

        const link = document.createElement('a');
        link.href = imageUrl;
        link.setAttribute('download', institutionInfo?.bizCertFileName); // 파일 이름을 지정합니다. (예: "tmp.png")
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/getInstitutionInfo", reqOption)
            .then((response) => response.json())
            .then(data => {
                setInstitutionInfo(data[0]);
                console.log(data[0]);
            });
    }, []);

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/getTotalUserNum", reqOption)
            .then((response) => response.json())
            .then(data => {
                setTotalUserNum(data[0]?.count);
            });
    }, []);

    console.log(props.authLevel);
    return (
        <>
            <div className={styles.기관정보}>
                기관 정보
            </div>
            <div className={styles.companyNameArea}>
                <span>
                    <img src={institutionInfoIcon} className={styles.institutionIcon}></img>
                </span>
                <span className={styles.companyName}>
                    GBCC
                </span>
                {props.authLevel >= 3 &&
                    <span className={styles.editButtonBox}>
                        <button className={styles.editButton} onClick={handleEditClick}>
                            <img src={editIcon} className={styles.editIcon}></img>
                            <span className={styles.기관정보편집}>
                                기관 정보 편집
                            </span>
                        </button>
                    </span>
                }
            </div>
            <div className={styles.content}>
                <div className={styles.institutionInfo}>
                    기관정보
                </div>


                <div className={styles.institutionContent}>
                    {isEditing ? (
                        <>
                            <div>
                                <div className={styles.institutionContentTitle}>
                                    사업자 등록 번호
                                </div>
                                <input type="text" name="businessRegNo" value={editValues.businessRegNo} onChange={handleInputChange} />
                            </div>
                            <div>
                                <div className={styles.institutionContentTitle}>
                                    연락처
                                </div>
                                <input type="text" name="telNo" value={editValues.telNo} onChange={handleInputChange} />
                            </div>
                            <div>
                                <div className={styles.institutionContentTitle}>
                                    주소
                                </div>
                                <input type="text" name="companyAddress" value={editValues.companyAddress} onChange={handleInputChange} className={styles.addressInput} />
                            </div>
                            <div>
                                <div className={styles.institutionContentTitle}>
                                    총 멤버 수
                                </div>
                                <div>
                                    {totalUserNum + "명"}
                                </div>
                            </div>
                            <div>
                                <div className={styles.institutionContentTitle}>
                                    시스템 관리자
                                </div>
                                <input type="text" name="managerName" value={editValues.managerName} onChange={handleInputChange} />
                            </div>
                            <div>
                                <div className={styles.institutionContentTitle}>
                                    사업자 등록증 다운로드
                                </div>
                                <div>
                                    {/* 사업자 등록증 pdf 다운로드 버튼 */}
                                    <button onClick={handleImageDownload}>파일 다운로드</button>
                                    {institutionInfo?.bizCertFileName}
                                </div>
                            </div>
                            <div>
                                <button onClick={handleSaveClick} className={styles.saveCancelButton}>저장</button>
                                <button onClick={() => setIsEditing(false)} className={styles.saveCancelButton}>취소</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div>
                                <div className={styles.institutionContentTitle}>
                                    사업자 등록 번호
                                </div>
                                <div>
                                    {institutionInfo?.businessRegNo}
                                </div>
                            </div>
                            <div>
                                <div className={styles.institutionContentTitle}>
                                    연락처
                                </div>
                                <div>
                                    {institutionInfo?.telNo}
                                </div>
                            </div>
                            <div>
                                <div className={styles.institutionContentTitle}>
                                    주소
                                </div>
                                <div>
                                    {institutionInfo?.companyAddress}
                                </div>
                            </div>
                            <div>
                                <div className={styles.institutionContentTitle}>
                                    총 멤버 수
                                </div>
                                <div>
                                    {totalUserNum + "명"}
                                </div>
                            </div>
                            <div>
                                <div className={styles.institutionContentTitle}>
                                    시스템 관리자
                                </div>
                                <div>
                                    {institutionInfo?.managerName}
                                </div>
                            </div>
                            <div>
                                <div className={styles.institutionContentTitle}>
                                    사업자 등록증 다운로드
                                </div>
                                <div>
                                    {/* 사업자 등록증 pdf 다운로드 버튼 */}
                                    <button onClick={handleImageDownload}>파일 다운로드</button>
                                    {institutionInfo?.bizCertFileName}
                                </div>
                            </div>
                        </>
                    )}

                </div>


            </div>
        </>
    );
}

export default InstitutionInfo;
