import "./PatientInfo_public.css";
import React, { useState } from 'react';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import EditPatientInfoPublic from "./EditPatientInfo_public";
import { useTranslation } from 'react-i18next';

function PatientInfo_public(props) {
    const { t } = useTranslation();
    const [patientInfoPublic, setPatientInfoPublic] = useState({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                param: props.param,
            })
        }

        fetch("/api/getPatientInfoPublic", reqOption)
            .then((response) => response.json())
            .then(data => {
                setPatientInfoPublic(data);
            });
    }, [props.param]);

    return (
        <>
            <div className="patientInfoPublicButtonCollection">
                <Button onClick={() => setShowModal(true)}>{t('정보 수정하기')}</Button>
            </div>
            <div className="basicInfo">
                <EditPatientInfoPublic
                    showModal={showModal}
                    setShowModal={setShowModal}
                    patientInfo={patientInfoPublic[0]}
                    setPatientInfoPublic={setPatientInfoPublic}
                />
                <div className="basicInfoTitle">Gender</div>
                <div className="basicInfoContent">{patientInfoPublic[0]?.Gender}</div>
                <div className="basicInfoTitle">Age</div>
                <div className="basicInfoContent">{patientInfoPublic[0]?.Age}</div>
                <div className="basicInfoTitle">Stage</div>
                <div className="basicInfoContent">{patientInfoPublic[0]?.Stage}</div>
                <div className="basicInfoTitle">Histologic type</div>
                <div className="basicInfoContent">{patientInfoPublic[0]?.HistologicType}</div>
                <div className="basicInfoTitle">Site</div>
                <div className="basicInfoContent">{patientInfoPublic[0]?.Site}</div>
                <div className="basicInfoTitle">Special feature</div>
                <div className="basicInfoContent">{patientInfoPublic[0]?.SpecialFeature}</div>
                <div className="basicInfoTitle">Treatment history</div>
                <div className="basicInfoContent">{
                    patientInfoPublic[0]?.TreatmentHistory?.split('\n')?.map((str, index) => (
                        <div key={`${str}-${index}`}>{str}</div>
                    ))
                }</div>
            </div>
        </>
    );
}

export default PatientInfo_public;