// DraftModal.js
import React, { useState } from 'react';
import styles from './DraftModal.module.css';
import { useTranslation } from 'react-i18next';

function DraftModal({ onClose, onSubmit }) {  // onSubmit prop 추가
    const { t } = useTranslation();
    const [draft, setDraft] = useState('');

    const handleDraftChange = (e) => {
        setDraft(e.target.value);
    };

    const handleDraftSubmit = () => {
        onSubmit(draft);  // 콜백 함수를 호출하여 상위 컴포넌트로 데이터 전달
        onClose();  // 모달 닫기
    };

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.modalHeader}>
                    <span>기안의견 입력</span>
                    <button className={styles.closeButton} onClick={onClose}>X</button>
                </div>
                <textarea
                    className={styles.textInput}
                    value={draft}
                    onChange={handleDraftChange}
                    placeholder="의견을 입력해주세요."
                />
                <div className={styles.draftButtonBox}>
                    <button className={styles.submitButton} onClick={handleDraftSubmit}>
                        기안 등록
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DraftModal;
