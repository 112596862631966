import React, { useState, useEffect } from 'react';
import plusIcon from "../../img/common/plusIcon.png";
import minusIcon from "../../img/common/minusIcon.png";
import downTriangleIcon from "../../img/icon/DownTriangleIcon.png";
import sideTriangleIcon from "../../img/icon/SideTriangleIcon.png";
import styles from './PatientList.module.css'; // 수정된 부분
import PatientName from './PatientName';

function PatientList(props) {
    const [visibleFirst, setVisibleFirst] = useState(true);
    const [patientInfo, setPatientInfo] = useState([{}]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // 한 페이지에 표시할 아이템 수

    // 현재 페이지에 표시할 데이터 계산
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = patientInfo.slice(indexOfFirstItem, indexOfLastItem);

    // 페이지 번호 변경 핸들러
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // 전체 페이지 수 계산
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(patientInfo.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    let str = '';
    if (props.search !== '') {
        str = "AND `PatientCode` LIKE '%" + props.search + "%'";
    }

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                param: str,
            })
        };

        fetch("/api/getPatientInfoList", reqOption)
            .then((response) => response.json())
            .then(data => {
                setPatientInfo(data);
            });
    }, [props.search]);

    let num = "(" + patientInfo?.length + ")";
    if (num === "()" || num === "(0)") {
        num = "";
    }

    return (
        <div className={styles.title}>
            <div onClick={() => setVisibleFirst(!visibleFirst)} className={styles.PatientCode}>
                <span>
                    {!visibleFirst && <img className={styles.plusImg} src={sideTriangleIcon} alt="Expand" />}
                    {visibleFirst && <img className={styles.minusImg} src={downTriangleIcon} alt="Collapse" />}
                </span>
                <span className={styles.PatientListFirstTitle}>Patient code</span>
                <span className={styles.PatientListNum}>{num}</span>
            </div>
            {/* {patientInfo?.map((map, index) => (
                visibleFirst && <PatientName name={map?.PatientCode} key={index} />
            ))} */}

            {
                visibleFirst &&
                <div className={styles.contentContainer}>
                    <div className={styles.cancerNamesContainer}>
                        {currentItems?.map((data, index) => (
                            <PatientName name={data?.PatientCode} key={index} />
                        ))}
                    </div>
                    <div className={styles.paginationContainer}>
                        {pageNumbers.map(number => (
                            <span key={number} className={currentPage === number ? styles.activePage : styles.pageNumber} onClick={() => handlePageClick(number)}>
                                {number}
                            </span>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
}

export default PatientList;
