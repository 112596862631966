import React, { useState, useEffect } from 'react';
import styles from './BankingList_Print.module.css';
import downtriangle from '../../img/down-triangle.png';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';


function BankingList_Print() {
    const [organoidList, setOrganoidList] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [showDetails, setShowDetails] = useState({});
    const [selectAll, setSelectAll] = useState({});

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
        }

        fetch("/api/getOrganoidList_All", reqOption)
            .then((response) => response.json())
            .then(data => {
                setOrganoidList(data);
            });
    }, []);

    const handleCheckboxChange = (key, isChecked) => {
        setSelectedKeys(prevKeys => {
            if (isChecked) {
                return [...prevKeys, key];
            } else {
                return prevKeys.filter(k => k !== key);
            }
        });
    };

    const handleSelectAllChange = (keys, key) => {
        setSelectAll(prevState => {
            const isChecked = !prevState[key];
            console.log('isChecked:', isChecked);
            if (isChecked) {
                setSelectedKeys(prevKeys => [...new Set([...prevKeys, ...keys])]);
            } else {
                console.log('Unchecking all');
                setSelectedKeys(prevKeys => prevKeys.filter(k => !keys.includes(k)));
            }
            return { ...prevState, [key]: isChecked };
        });
    };

    const handleToggleDetails = (key) => {
        setShowDetails(prevState => ({ ...prevState, [key]: !prevState[key] }));
    };

    const groupedOrganoidList = organoidList.reduce((grouped, item) => {
        const key = item.sampleSource === 'PDX' ? 'PDXO' : 'PDO';
        const type = item.Type;
        if (!grouped[key]) {
            grouped[key] = {};
        }
        if (!grouped[key][type]) {
            grouped[key][type] = [];
        }
        grouped[key][type].push(item);
        return grouped;
    }, {});

    const handleSubmit = () => {
        // selectedKeys를 서버에 POST하여 데이터 가져오기
        const reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(selectedKeys)
        }

        fetch("/api/getBankingListByKey", reqOption)
            .then(response => response.json())
            .then(data => {
                data.forEach(element => {
                    element.time = formatDate(element.time);
                    element.url = "https://banking.gradiantbio.com/organoid/" + element.Organoid_Info_Key + "/bank/" + element.time;
                    element.url = element.url.replace(' ', '%20');
                    element.Organoid_Info_Key = element.Organoid_Info_Key.replace('hashmark', '#');
                    console.log(element.time);
                });
                // 가져온 데이터를 엑셀 파일로 만들기
                const ws = utils.json_to_sheet(data);
                const wb = utils.book_new();
                utils.book_append_sheet(wb, ws, "Sheet1");

                // 엑셀 파일 다운로드
                const wbout = write(wb, { bookType: 'xlsx', type: 'array' });
                saveAs(new Blob([wbout], { type: "application/octet-stream" }), 'data.xlsx');
            });
    };

    return (
        <div className={styles.container}>
            {Object.entries(groupedOrganoidList).map(([sampleType, types]) =>
                <div key={sampleType}>
                    <h2>{sampleType}</h2>
                    {Object.entries(types).map(([type, items]) => {
                        const key = `${sampleType}_${type}`;
                        return (
                            <div key={type}>
                                <span onClick={() => handleToggleDetails(key)} className={styles.header}>
                                    <img src={downtriangle} alt="toggle" className={styles.toggleImage} />
                                    {type}
                                </span>
                                <input
                                    type="checkbox"
                                    onChange={() => handleSelectAllChange(items.map(item => item.Key), key)}
                                    checked={selectAll[key] || false}
                                />

                                {showDetails[key] && items.map(({ Key }) =>
                                    <div key={Key}>
                                        <input
                                            type="checkbox"
                                            onChange={e => handleCheckboxChange(Key, e.target.checked)}
                                            checked={selectedKeys.includes(Key)}
                                        />
                                        {Key.replace('hashmark', '#')}
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
            <button onClick={handleSubmit}>Submit</button>
        </div>
    );
}

export default BankingList_Print;
