import styles from "./BankingInfoTab.module.css";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { t } from "i18next";

//컴포넌트
import DraftModal from '../../components/modal/DraftModal';

function BankingInfoTab(props) {
    const [data, setData] = useState([]);
    const [positionInput, setPositionInput] = useState("");
    const [draftOpinion, setDraftOpinion] = useState("");
    // 모달에서 현재 작업 유형을 저장할 상태 추가
    const [currentAction, setCurrentAction] = useState(null);
    const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);
    const authLevel = props.authLevel;
    const [redraw, setRedraw] = useState(0);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    };
    

    const saveDraft = (draft) => {
        setDraftOpinion(draft);  // draftOpinion 상태 업데이트
        setIsDraftModalOpen(false);  // 모달 닫고 데이터 저장 로직 실행
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                key: formatDate(data?.time),
                position: positionInput,
                draftOpinion: draftOpinion,
                vessel: data?.Vessel,
                line: data?.Line,
                box: data?.Box,
            })
        }
        if (currentAction === 'save') {
            fetch('/api/updateBankingStorageDraft', reqOption)
                .then(response => response.json())
                .then(data => {
                    alert(data?.message || "");
                });
        } else if (currentAction === 'delete') {
            fetch('/api/deleteBankingStorageDraft', reqOption)
                .then(response => response.json())
                .then(data => {
                    alert(data?.message || "");
                });
        }
    }

    const closeDraftModal = () => {
        setIsDraftModalOpen(false);
    }

    const handleSave = () => {
        if (!data) {
            alert(t('항목을 선택해 주세요.'))
        } else {
            if (data.Position === positionInput) {
                alert(t('위치가 이전과 같습니다.'))
            } else {
                setCurrentAction('save');
                const newPosition = parseInt(positionInput);
                if (newPosition >= 1 && newPosition <= 100) {
                    setIsDraftModalOpen(true);
                } else {
                    alert("Please enter a position between 1 and 100.");
                }
            }
        }
    };

    const handleDeleteClick = () => {
        setCurrentAction('delete');
        if (authLevel >= 3) {
            // authLevel 3 이상일 경우 확인 창을 표시하고 삭제 진행
            const confirmDelete = window.confirm("Are you sure you want to delete this item?");
            if (confirmDelete) {
                let reqOption = {
                    method: "post",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify({
                        key: formatDate(data?.time),
                    })
                }
                fetch('/api/deleteBankingStorage', reqOption)
                    .then(response => response.json())
                    .then(data => {
                        alert(data?.message || "");
                        props.onDelete(); // 삭제 후 콜백 호출
                    });
            }
        } else {
            // authLevel 1 또는 2일 경우 기안의견 모달 창 표시
            setIsDraftModalOpen(true);
        }
    };

    const handlePositionChange = (event) => {
        const inputValue = event.target.value;
        setPositionInput(inputValue);
    };

    useEffect(() => {
        setPositionInput(props.data?.Position)
        setData(props.data);
    }, [data, props.data, redraw])

    return (
        <div className={styles.bankingTabMain}>
            {
                isDraftModalOpen &&
                <DraftModal onClose={closeDraftModal} onSubmit={saveDraft} />
            }
            <div className={styles.title}>
                <span>
                    Banking stock information
                </span>
                <span className={styles.editSaveButtonGroup}>
                    {
                        props.data &&
                        <>
                            <button onClick={handleSave}>save</button>
                            <button onClick={handleDeleteClick}>delete</button>
                        </>
                    }
                </span>
            </div>
            {
                !props.data &&
                <div>
                    no data
                </div>
            }
            {
                props.data &&
                <div className={styles.gridBox}>
                    <div className={styles.leftTitle}>
                        Organoid Info Key
                    </div>
                    <div className={styles.bankingInput}>
                        {data?.Organoid_Info_Key.replace('hashmark', '#') || ""}
                    </div>

                    <div className={styles.leftTitle}>
                        Patient Code
                    </div>
                    <div className={styles.bankingInput}>
                        {data?.PatientCode || ""}
                    </div>

                    <div className={styles.leftTitle}>
                        LabCode
                    </div>
                    <div className={styles.bankingInput}>
                        {data?.LabCode || ""}
                    </div>

                    <div className={styles.leftTitle}>
                        Vessel
                    </div>
                    <div className={styles.bankingInput}>
                        {data?.Vessel || ""}
                    </div>

                    <div className={styles.leftTitle}>
                        Line
                    </div>
                    <div className={styles.bankingInput}>
                        {data?.Line || ""}
                    </div>

                    <div className={styles.leftTitle}>
                        Box
                    </div>
                    <div className={styles.bankingInput}>
                        {data?.Box || ""}
                    </div>

                    <div className={styles.leftTitle}>
                        Position
                    </div>
                    <div className={styles.bankingInput}>
                        <input
                            type="text"
                            name="Position"
                            value={positionInput || ""}
                            className={styles.input}
                            onChange={handlePositionChange}
                        />
                    </div>

                    <div className={styles.leftTitle}>
                        Passage No.
                    </div>
                    <div className={styles.bankingInput}>
                        {data?.PassageNo || ""}
                    </div>

                    <div className={styles.leftTitle}>
                        Freezing Date
                    </div>
                    <div className={styles.bankingInput}>
                        {data?.FreezingDate || ""}
                    </div>

                    <div className={styles.leftTitle}>
                        Keeper
                    </div>
                    <div className={styles.bankingInput}>
                        {data?.Keeper || ""}
                    </div>

                    <div className={styles.leftTitle}>
                        Culture Media
                    </div>
                    <div className={styles.bankingInput}>
                        {data?.CultureMedia || ""}
                    </div>

                    <div className={styles.leftTitle}>
                        Dome/Vial or Cell number
                    </div>
                    <div className={styles.bankingInput}>
                        {data?.domePerVialOrCellNumber || ""}
                    </div>

                    <div className={styles.leftTitle}>
                        Thawing No.
                    </div>
                    <div className={styles.bankingInput}>
                        {data?.domePerVialOrCellNumber || ""}
                    </div>

                    <div className={styles.leftTitle}>
                        thawingNo
                    </div>
                    <div className={styles.bankingInput}>
                        {data?.thawingNo || ""}
                    </div>
                </div>
            }
        </div >
    );
}

export default BankingInfoTab;
