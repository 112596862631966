import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from "./ImageManagementModal.module.css";

//image import
import upTriangle from "../../img/common/UpTriangleBlack.png";
import downTriangle from "../../img/common/DownTriangleBlack.png";
import deleteButton from "../../img/common/deleteButton.png";

import { t } from 'i18next';

function ImageManagementModal({ onClose, onSubmit, endPoint }) {
    const { tmpKey } = useParams();
    const [draft, setDraft] = useState('');
    const [items, setItems] = useState([]);
    const [deletedItemIds, setDeletedItemIds] = useState([]);


    // 이미지 이름 변경 로직
    const nameChange = (name) => {
        if (name) {
            name = name.replace('.jpg', '');
            name = name.replace('.jpeg', '');
            name = name.replace(tmpKey + '_', '');
            name = name.replace(tmpKey.replace('hashmark', '#') + '_', '');
            let arr = name.split('_');
            let first = arr[0];
            let second = arr.find(part => part.charAt(0) === 'P');
            let third = arr.find(part => part.charAt(part.length - 1) === 'X');

            if (second === "P99") {
                name = third ? `${first}_${third}` : `${first}`; // `third`가 없는 경우 first만 사용
            } else {
                // second나 third가 없는 경우를 고려하여 조건부로 이름을 구성
                if (second && third) {
                    name = `${first}_${second}_${third}`;
                } else if (second) {
                    name = `${first}_${second}`;
                } else if (third) {
                    name = `${first}_${third}`;
                } else {
                    name = `${first}`;
                }
            }
        }
        return name;
    };


    useEffect(() => {
        const fetchData = async () => {
            const reqOption = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: tmpKey })
            };
            try {
                const response = await fetch(`${endPoint}`, reqOption);
                const data = await response.json();
                // 데이터를 주문 번호에 따라 정렬
                const sortedData = data.sort((a, b) => a.orderNumber - b.orderNumber);
                // items 배열을 설정, 사진 데이터는 제외하고 이름만 포함
                setItems(sortedData.map(item => ({
                    id: item.picture_key,
                    organoidInfoKey: item.Organoid_Info_Key,
                    orderNumber: item.orderNumber
                })));
            } catch (error) {
                console.error('Failed to fetch items:', error);
            }
        };
        fetchData();
    }, [endPoint, tmpKey]);

    const handleDraftChange = (e) => {
        setDraft(e.target.value);
    };

    const handleDraftSubmit = () => {

        const updatedItems = items.map((item, index) => ({
            id: item.id,
            organoidInfoKey: item.organoidInfoKey,
            orderNumber: index + 1  // 순서를 업데이트
        }));

        // 서버로 업데이트 및 삭제할 아이템 전송
        const reqOption = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                updatedItems,
                deletedItemIds,  // 삭제할 아이템 ID 목록
                table: endPoint === '/api/getImages' ? 'Light_Microscope_picture_data' : 'Pathology_Picture_picture_data',
                targetKey: tmpKey,
                draftOpinion: draft,
            })
        };

        fetch(`/api/updateImage`, reqOption)
            .then(response => response.json())
            .then(data => {
                console.log('Image updated:', data);
                onClose();
            })
            .catch(error => {
                alert('Failed to update Image:', error);
            });
    };

    const moveItem = (index, direction) => {
        const newPosition = index + direction;
        if (newPosition < 0 || newPosition >= items.length) {
            return; // 배열 범위 밖으로 이동할 수 없음
        }
        const newItems = [...items];
        const [removedItem] = newItems.splice(index, 1);
        newItems.splice(newPosition, 0, removedItem);
        setItems(newItems);
    };

    const removeItem = (index) => {
        const newItems = [...items];
        const itemToRemove = newItems.splice(index, 1)[0];  // 삭제된 아이템 정보
        setItems(newItems);
        setDeletedItemIds(prev => [...prev, itemToRemove.id]); // 삭제된 아이템 ID 저장
    };

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.modalHeader}>
                    <span>Image Editor</span>
                    <button className={styles.closeButton} onClick={onClose}>X</button>
                </div>
                <div>
                    {items.map((item, index) => (
                        <div key={item.id} className={styles.itemRow}>
                            <div>
                                {nameChange(item.id)}
                                <span>
                                    <img src={deleteButton} className={styles.deleteButton} onClick={() => removeItem(index)} />
                                    {
                                        index === 0 &&
                                        <span className={styles.repImg}>
                                            {t('대표이미지')}
                                        </span>
                                    }
                                </span>
                            </div>

                            <div className={styles.buttonGroup}>
                                {/* {
                                    index === 0 &&
                                    <div className={styles.repImg}>
                                        {t('대표이미지')}
                                    </div>
                                } */}
                                <div className={styles.upDownButtonGroup}>
                                    <img src={upTriangle} className={styles.upDownTriangleImg} onClick={() => moveItem(index, -1)} />
                                    <img src={downTriangle} className={styles.upDownTriangleImg} onClick={() => moveItem(index, 1)} />
                                </div>
                                {/* <button onClick={() => removeItem(index)} className={styles.removeButton}>x</button> */}
                            </div>
                        </div>
                    ))}
                </div>
                <textarea
                    className={styles.textInput}
                    value={draft}
                    onChange={handleDraftChange}
                    placeholder={t("의견을 입력해주세요.")}
                />
                <div className={styles.draftButtonBox}>
                    <button className={styles.submitButton} onClick={handleDraftSubmit}>
                        {t('기안 등록')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ImageManagementModal;
