// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/Pretendard-SemiBold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "Pretendard-SemiBold";
    font-style: normal;
    font-weight: 600;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}

.summary {
    display: flex;
    font-family: 'Pretendard-SemiBold';
}

.rightTab {
    margin-top: 80px;
    margin-left: 470.5px;
    padding-left: 29.5px;
    /* border-left: solid 1px gray; */
}

.titleRight {
    font-family: "Pretendard-SemiBold";
    font-size: 17px;
    border-bottom: solid 2px;
    height: 25px;
    width: 60vw;
}

.summaryBox {
    margin-top: 25px;
    width: 60vw;
    height: auto;
    padding-bottom: 30px;
    background-color: rgba(128, 128, 128, 0.2);
    margin-bottom: 30px;
}

.titleBox {
    margin-top: 25px;
    width: 60vw;
    height: auto;
    padding-bottom: 30px;
    margin-bottom: 30px;
    background-color: rgba(128, 128, 128, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/components/right_tab/RightTab.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,gBAAgB;IAChB,+DAAkE;AACtE;;AAEA;IACI,aAAa;IACb,kCAAkC;AACtC;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,oBAAoB;IACpB,iCAAiC;AACrC;;AAEA;IACI,kCAAkC;IAClC,eAAe;IACf,wBAAwB;IACxB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,0CAA0C;IAC1C,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,mBAAmB;IACnB,0CAA0C;AAC9C","sourcesContent":["@font-face {\n    font-family: \"Pretendard-SemiBold\";\n    font-style: normal;\n    font-weight: 600;\n    src: url(\"../../fonts/Pretendard-SemiBold.otf\") format(\"opentype\");\n}\n\n.summary {\n    display: flex;\n    font-family: 'Pretendard-SemiBold';\n}\n\n.rightTab {\n    margin-top: 80px;\n    margin-left: 470.5px;\n    padding-left: 29.5px;\n    /* border-left: solid 1px gray; */\n}\n\n.titleRight {\n    font-family: \"Pretendard-SemiBold\";\n    font-size: 17px;\n    border-bottom: solid 2px;\n    height: 25px;\n    width: 60vw;\n}\n\n.summaryBox {\n    margin-top: 25px;\n    width: 60vw;\n    height: auto;\n    padding-bottom: 30px;\n    background-color: rgba(128, 128, 128, 0.2);\n    margin-bottom: 30px;\n}\n\n.titleBox {\n    margin-top: 25px;\n    width: 60vw;\n    height: auto;\n    padding-bottom: 30px;\n    margin-bottom: 30px;\n    background-color: rgba(128, 128, 128, 0.2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
