// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobiletitle-box {
  margin-top: 10px;
  margin-left: 10vw;
  margin-bottom: 10px;
}

.mobiletitle-underline {
  background: linear-gradient(90.37deg, #f68912 35.18%, #1acc8f 115.32%);
  /* background-color: black; */
  height: 4px;
  width: 240px;
  margin-left: 10vw;
}

.mobileHeaderSize {
  width: 90vw;
  float: left;
}`, "",{"version":3,"sources":["webpack://./src/components/mobileComponents/MobileHeader.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,sEAAsE;EACtE,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,WAAW;AACb","sourcesContent":[".mobiletitle-box {\r\n  margin-top: 10px;\r\n  margin-left: 10vw;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.mobiletitle-underline {\r\n  background: linear-gradient(90.37deg, #f68912 35.18%, #1acc8f 115.32%);\r\n  /* background-color: black; */\r\n  height: 4px;\r\n  width: 240px;\r\n  margin-left: 10vw;\r\n}\r\n\r\n.mobileHeaderSize {\r\n  width: 90vw;\r\n  float: left;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
