// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileMainImage {
    width: 110vw;
    margin-top: 50px;
    min-height: 85vh;
    margin-left: 10vw;
}

.mobileMainPicture {
    width: 110vw;
}`, "",{"version":3,"sources":["webpack://./src/MobileMainImage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".mobileMainImage {\n    width: 110vw;\n    margin-top: 50px;\n    min-height: 85vh;\n    margin-left: 10vw;\n}\n\n.mobileMainPicture {\n    width: 110vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
