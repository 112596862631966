import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./Content.css";


function Content(props) {
    var str = "asdf";
    function hashing(str) {
        str = String(str).replace('hashmark', '#');
        if (!str) {
            str = "NA";
        }
        return str;
    }

    function isExist(num) {
        if (num == '1') {
            return "Yes";
        } else if (num == '2') {
            return "No";
        } else {
            return "NA";
        }
    }

    return (
        <div>
            {/* <div>
                <span className="content1">Lab code</span>
                <span className="content11">{hashing(props.data['Lab code'])}</span>
            </div>
            <div>
                <span className="content2">GBCC code</span>
                <span className="content11">{hashing(props.data['GBCC code'])}</span>
            </div> */}
            <div>
                <span className="content3">Code</span>
                <span className="content11">{hashing(props.data['Key'])}</span>
            </div>
            <div>
                <span className="content4">Sample type</span>
                <span className="content11">{hashing(props.data['SampleType'])}</span>
            </div>
            <div>
                <span className="content5">Organism</span>
                <span className="content51">{hashing(props.data['Organism'])}</span>
            </div>
            <div>
                <span className="content6">Cancer type</span>
                <span className="content11">{hashing(props.data['Type'])}</span>
            </div>
            <div>
                <span className="content7">Subtype</span>
                <span className="content11">{hashing(props.data['Subtype'])}</span>
            </div>
            <div>
                <span className="content8">Orgin</span>
                <span className="content11">{hashing(props.data['Orgin'])}</span>
            </div>
            <div>
                <span className="contentP6">Normal pair</span>
                <span className="content11">{(props.data['Pair'])}</span>
            </div>
            <div>
                <span className="contentP7">WES</span>
                <span className="content11">{(props.data['WES'])}</span>
            </div>
            <div>
                <span className="contentP8">RNA seq.</span>
                <span className="content11">{(props.data['RNASeq'])}</span>
            </div>
            <div>
                <span className="contentP4">Mutation information</span>
                <span className="contentP51">{hashing(props.data['MutationInformation'])}</span>
            </div>


        </div>
    );
}

export default Content;