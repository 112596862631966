import styles from './StockTracing.module.css';
import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useNavigate } from 'react-router-dom';
import { t } from 'i18next';

function StockTracing({ time, item, onCancel }) {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    };
    const timeParam = time;
    const [parentStockList, setParentStockList] = useState([]);
    const navigate = useNavigate();

    const handleBoxClick = (organoidInfoKey, time) => {
        navigate(`/organoid/${organoidInfoKey}/bank/${formatDate(time)}`);
    };

    useEffect(() => {
        const reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ time: timeParam }),
        };
        fetch('/api/getAllParentsStock', reqOption)
            .then(response => response.json())
            .then(data => setParentStockList(data.data));
    }, []);


    return (
        <div className={styles.container}>
            <div className={styles.title}>오가노이드 추적</div>
            {/* {parentStockList.reverse().map((stock, index) => ( */}
            {parentStockList &&
                parentStockList.map((stock, index) => (
                    <div key={index}>
                        <div
                            className={styles.box}
                            onClick={() => handleBoxClick(stock.Organoid_Info_Key, stock.time)}
                        >
                            {/* {index === parentStockList.length - 1 ? "현재 오가노이드" : "Box Content"} */}
                            {index === 0 ? "현재 Stock" : "부모 Stock"}
                        </div>
                        {index !== parentStockList.length - 1 && <div className={styles.line}></div>}
                    </div>
                ))
            }
            {
                (!parentStockList || parentStockList.length === 0) &&
                <div>
                    {t('부모 스탁이 없습니다.')}
                </div>
            }
        </div>
    );

}

export default StockTracing;
