import React, { useEffect, useState } from "react";
import "./RightTab.css";
import { useParams, } from 'react-router-dom';
import SummaryData from "./SummaryData";
import PatientData from "./PatientData";
import { useLocation } from 'react-router-dom';
import ImageLightMicroscope from './ImageLightMicroscope';
import Graph from './Graph';
import ImagePathologyPicture from './ImagePathologyPicture';

function RightTab(props) {

	const [graphData, setGraphData] = useState([{
	}]);
	const [authNum, setAuthNum] = useState({});

	const { tmpKey } = useParams();

	useEffect(() => {
		let reqOption = {
			method: "post",
			headers: {
				"content-type": "application/json"
			},
			body: JSON.stringify({
				name: tmpKey
			}),

		}

		fetch("/api/getGraphData", reqOption)
			.then((response) => response.json())
			.then(data => {
				setGraphData(data);
			});
	}, []);
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		let reqOption = {
			method: "post",
			headers: {
				"content-type": "application/json"
			},
			body: JSON.stringify({
				name: tmpKey
			}),
		}

		fetch("/api/getAuth", reqOption)
			.then((response) => response.json())
			.then(data => {
				setAuthNum(data);
			});
	}, []);
	const level = authNum[0];




	return (
		<div className="rightTab">
			<div>
				<div className="titleRight">Summary</div>
				<div className="summaryBox">
					<SummaryData cancerKey={tmpKey} level={level}></SummaryData>
				</div>
				<div className="titleRight">Patient Information</div>
				<div className="titleBox">
					<PatientData cancerKey={tmpKey} level={level}></PatientData>
				</div>
				<div className="titleRight">PDO Image(Bright Field)</div>
				<div>
					<ImageLightMicroscope cancerKey={tmpKey} level={level}></ImageLightMicroscope>
				</div>
				<div className="titleRight">PDO Histology</div>
				<div>
					<ImagePathologyPicture cancerKey={tmpKey} level={level}></ImagePathologyPicture>
				</div>
				<div className="titleRight">Drug Responsiveness</div>
				<div>
					<Graph graphData={graphData} level={level}></Graph>
				</div>
			</div>
		</div>
	);
}



export default RightTab;