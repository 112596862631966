import React, { useState, useEffect } from 'react';
import styles from './EtcModels.module.css';
import { useTranslation } from 'react-i18next';
import plus from "../../img/common/plusIcon.png";
import minus from "../../img/common/minusIcon.png";
import downTriangleIcon from "../../img/icon/DownTriangleIcon.png";
import sideTriangleIcon from "../../img/icon/SideTriangleIcon.png";

import CancerName from "./CancerName";

function EtcModels(props) {
    const { t } = useTranslation();
    const [visibleFirst, setVisibleFirst] = useState(false);
    const [cancerDatas, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // 한 페이지에 표시할 아이템 수

    // 현재 페이지에 표시할 데이터 계산
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = cancerDatas.slice(indexOfFirstItem, indexOfLastItem);

    // 페이지 번호 변경 핸들러
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // 전체 페이지 수 계산
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(cancerDatas.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                param: props.search,
            })
        }

        fetch("/api/GetEtcBanking", reqOption)
            .then((response) => response.json())
            .then(data => {
                setData(data);
            });
    }, [props.search]);

    function checkZero(num) {
        if (num === 0 || num === '') {
            return '';
        } else {
            return "(" + num + ")";
        }
    }

    function returnFalse(value) {
        props.returnFalse(value);
    }

    return (
        <>
            <div className={styles.title}>
                <span className={styles.outlinePdo} onClick={() => setVisibleFirst(!visibleFirst)}>
                    {!visibleFirst && <img className={styles.plusImg} src={sideTriangleIcon} alt="plusImg" />}
                    {visibleFirst && <img className={styles.minusImg} src={downTriangleIcon} alt="minusImg" />}
                </span>
                <span className={styles.pdoModels} onClick={() => setVisibleFirst(!visibleFirst)}>
                    {t('Etc models')}
                </span>
                <span className={styles.PDOnumber}>{checkZero(props.num.num)}</span>
                {visibleFirst && (
                    <div className={styles.contentContainer}>
                        <div className={styles.cancerNamesContainer}>
                            {currentItems.map((data, index) => (
                                <CancerName data={data} returnFalse={returnFalse} key={index} />
                            ))}
                        </div>
                        <div className={styles.paginationContainer}>
                            {pageNumbers.map(number => (
                                <span key={number} className={currentPage === number ? styles.activePage : styles.pageNumber} onClick={() => handlePageClick(number)}>
                                    {number}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default EtcModels;
