import React, { useState, useEffect } from 'react';
import styles from './DraftFilterModal.module.css';

function DraftFilterModal({ show, onClose, onApply, filterType }) {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const categoryList = ["Banking_Info", "Organoid_Info", "Patient_Info", "StorageList"];
    const statusList = ["pending", "rejected", "approved", "canceled"];

    useEffect(() => {
        if (filterType === 'team') {
            let reqOption = {
                method: "post",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(),
            };

            fetch("/api/getTeamList", reqOption)
                .then((response) => response.json())
                .then(data => {
                    console.log(data);
                    setTeamList(data);
                });
        }
    }, [filterType]);

    const handleApply = () => {
        if (filterType === 'date') {
            onApply({ filterType: 'date', startDate, endDate });
        } else if (filterType === 'team') {
            onApply({ filterType: 'team', selectedTeams });
        } else if (filterType === 'category') {
            onApply({ filterType: 'category', selectedCategories });
        } else if (filterType === 'status') {
            onApply({ filterType: 'status', selectedStatuses });
        }
        onClose();
    };

    const handleTeamChange = (team) => {
        setSelectedTeams(prevSelectedTeams => {
            if (prevSelectedTeams.includes(team.teamName)) {
                return prevSelectedTeams.filter(t => t !== team.teamName);
            } else {
                return [...prevSelectedTeams, team.teamName];
            }
        });
    };

    const handleCategoryChange = (category) => {
        setSelectedCategories(prevSelectedCategories => {
            if (prevSelectedCategories.includes(category)) {
                return prevSelectedCategories.filter(c => c !== category);
            } else {
                return [...prevSelectedCategories, category];
            }
        });
    };

    const handleStatusChange = (status) => {
        setSelectedStatuses(prevSelectedStatuses => {
            if (prevSelectedStatuses.includes(status)) {
                return prevSelectedStatuses.filter(s => s !== status);
            } else {
                return [...prevSelectedStatuses, status];
            }
        });
    };

    if (!show) {
        return null;
    }

    return (
        <div className={styles.popupBackground} onClick={onClose}>
            <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.popupHeader}>
                    <span>
                        {filterType === 'date' ? '기간 설정' : 
                         filterType === 'team' ? '팀 선택' :
                         filterType === 'category' ? '카테고리 선택' :
                         '승인 상태 선택'}
                    </span>
                    <button className={styles.closeButton} onClick={onClose}>&times;</button>
                </div>
                <div className={styles.popupBody}>
                    {filterType === 'date' ? (
                        <>
                            <label>시작 날짜</label>
                            <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            <label>종료 날짜</label>
                            <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </>
                    ) : filterType === 'team' ? (
                        <>
                            <label>팀 이름</label>
                            {teamList?.map(team => (
                                <div key={team.teamId}>
                                    <input
                                        type="checkbox"
                                        id={team.teamName}
                                        checked={selectedTeams.includes(team.teamName)}
                                        onChange={() => handleTeamChange(team)}
                                    />
                                    <label htmlFor={team.teamName}>{team.teamName}</label>
                                </div>
                            ))}
                        </>
                    ) : filterType === 'category' ? (
                        <>
                            <label>카테고리</label>
                            {categoryList.map(category => (
                                <div key={category}>
                                    <input
                                        type="checkbox"
                                        id={category}
                                        checked={selectedCategories.includes(category)}
                                        onChange={() => handleCategoryChange(category)}
                                    />
                                    <label htmlFor={category}>{category}</label>
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            <label>승인 상태</label>
                            {statusList.map(status => (
                                <div key={status}>
                                    <input
                                        type="checkbox"
                                        id={status}
                                        checked={selectedStatuses.includes(status)}
                                        onChange={() => handleStatusChange(status)}
                                    />
                                    <label htmlFor={status}>{status}</label>
                                </div>
                            ))}
                        </>
                    )}
                </div>
                <div className={styles.popupFooter}>
                    <button onClick={onClose}>취소</button>
                    <button onClick={handleApply}>적용</button>
                </div>
            </div>
        </div>
    );
}

export default DraftFilterModal;
