// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.relOrganoidList {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin-left: 3vw;
    padding-left: 2vw;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10vw;
    background-color: snow;
}

.patientBasicInfo {
    display: grid;
    grid-template-columns: 1fr 3fr;

}

.commonPatientInfo {
    margin-left: 3vw;
    padding-left: 2vw;
    margin-right: 10vw;
    margin-bottom: 10px;
    background-color: snow;
}

.basicInfoTitle {
    font-weight: bold;
    font-size: medium;
}

.basicInfoContent {
    font-size: medium;
    margin-bottom: 5px;
}

.relOrganoid {
    margin-left: 3vw;
    margin-bottom: 10px;
}

.relOrganoidName {
    color: black;
    padding-left: 5px;
    padding-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/organoid/rightTab/PatientInfo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,8BAA8B;;AAElC;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".relOrganoidList {\n    display: grid;\n    grid-template-columns: repeat(7, 1fr);\n    margin-left: 3vw;\n    padding-left: 2vw;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    margin-right: 10vw;\n    background-color: snow;\n}\n\n.patientBasicInfo {\n    display: grid;\n    grid-template-columns: 1fr 3fr;\n\n}\n\n.commonPatientInfo {\n    margin-left: 3vw;\n    padding-left: 2vw;\n    margin-right: 10vw;\n    margin-bottom: 10px;\n    background-color: snow;\n}\n\n.basicInfoTitle {\n    font-weight: bold;\n    font-size: medium;\n}\n\n.basicInfoContent {\n    font-size: medium;\n    margin-bottom: 5px;\n}\n\n.relOrganoid {\n    margin-left: 3vw;\n    margin-bottom: 10px;\n}\n\n.relOrganoidName {\n    color: black;\n    padding-left: 5px;\n    padding-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
