// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/Pretendard-SemiBold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../fonts/Pretendard-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "Pretendard-SemiBold";
    font-style: normal;
    font-weight: 600;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}

@font-face {
    font-family: "Pretendard-Regular";
    font-style: normal;
    font-weight: 400;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}

.StorageList_storageListMain__keEuh {
    align-items: center;
    /* margin-left: 1vw; */
}

.StorageList_storageList_1__0Dd3H {
    margin-bottom: 8px;
}

.StorageList_storage_PlusImg__rHhkk {
    width: 1.2vw;
    margin-bottom: 6px;
}

.StorageList_storage_MinusImg__h7yGr {
    width: 1.2vw;
    margin-bottom: 6px;
}

.StorageList_storageVesselTitle__98uU1 {
    font-family: "Pretendard-SemiBold";
    padding-left: 15px;
    /* position: relative; */
    font-size: 16px;
}

.StorageList_secondDropDownCategory__\\+4F0z {
    margin-left: 20px;
    margin-bottom: 3px;
}

.StorageList_plusImg__VoA4Q {
    width: 1.2vw;
    margin-bottom: 6px;
}

.StorageList_minusImg__ce5tO {
    width: 1.2vw;
    margin-bottom: 6px;
}`, "",{"version":3,"sources":["webpack://./src/organoid/leftTab/StorageList.module.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,gBAAgB;IAChB,+DAAkE;AACtE;;AAEA;IACI,iCAAiC;IACjC,kBAAkB;IAClB,gBAAgB;IAChB,+DAAiE;AACrE;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,wBAAwB;IACxB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: \"Pretendard-SemiBold\";\n    font-style: normal;\n    font-weight: 600;\n    src: url(\"../../fonts/Pretendard-SemiBold.otf\") format(\"opentype\");\n}\n\n@font-face {\n    font-family: \"Pretendard-Regular\";\n    font-style: normal;\n    font-weight: 400;\n    src: url(\"../../fonts/Pretendard-Regular.otf\") format(\"opentype\");\n}\n\n.storageListMain {\n    align-items: center;\n    /* margin-left: 1vw; */\n}\n\n.storageList_1 {\n    margin-bottom: 8px;\n}\n\n.storage_PlusImg {\n    width: 1.2vw;\n    margin-bottom: 6px;\n}\n\n.storage_MinusImg {\n    width: 1.2vw;\n    margin-bottom: 6px;\n}\n\n.storageVesselTitle {\n    font-family: \"Pretendard-SemiBold\";\n    padding-left: 15px;\n    /* position: relative; */\n    font-size: 16px;\n}\n\n.secondDropDownCategory {\n    margin-left: 20px;\n    margin-bottom: 3px;\n}\n\n.plusImg {\n    width: 1.2vw;\n    margin-bottom: 6px;\n}\n\n.minusImg {\n    width: 1.2vw;\n    margin-bottom: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"storageListMain": `StorageList_storageListMain__keEuh`,
	"storageList_1": `StorageList_storageList_1__0Dd3H`,
	"storage_PlusImg": `StorageList_storage_PlusImg__rHhkk`,
	"storage_MinusImg": `StorageList_storage_MinusImg__h7yGr`,
	"storageVesselTitle": `StorageList_storageVesselTitle__98uU1`,
	"secondDropDownCategory": `StorageList_secondDropDownCategory__+4F0z`,
	"plusImg": `StorageList_plusImg__VoA4Q`,
	"minusImg": `StorageList_minusImg__ce5tO`
};
export default ___CSS_LOADER_EXPORT___;
