import React, { useEffect, useState } from "react";
import styles from "./UserName.module.css";
import UserModal from "./UserModal";

//이미지 임포트
import basicProfile from "../../../img/common/grayPerson.png"

function UserName(props) {
    // props.closeModal();
    // 모달창 오픈 변수
    const [isModalOpen, setModalOpen] = useState(false);
    // 모달창 여는 함수
    const handleModalOpen = () => {
        setModalOpen(true);
    }

    // 모달창 닫는 함수
    const handleModalClose = () => {
        setModalOpen(false);
        props.closeModal(); // 상위 컴포넌트의 콜백 호출
    }

    return (
        <>
            <div className={styles.UserName} onClick={handleModalOpen}>
                <span className={styles.spanLeft}>
                    <img src={basicProfile} className={styles.basicProfile}></img>
                </span>
                <span className={styles.spanRight}>
                    <div>
                        {props.name}
                    </div>
                    <div>
                        {props.email}
                    </div>
                </span>
            </div>
            {isModalOpen && <UserModal onClose={handleModalClose} userInfo={props.userInfo} closeModal={props.closeModal} />}
        </>
    );
}

export default UserName;

