import React from "react";
import imgFile from "../../img/header-name.png";
import "./MobileHeader.css";

function MobileHeader() {
  return (
    <div className="mobileHeaderSize">
      <div className="mobileheader">
        <img
          className="mobiletitle-box"
          src={imgFile}
          height="40px"
          width="240px"
          alt="title"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "http://www.banking.gradiantbio.com";
          }}
        />
      </div>
      <div className="mobiletitle-underline"></div>
    </div>
  );
}

export default MobileHeader;
