import "./BankingRightTab_patient.css";
import React, { useState } from 'react';
import { useEffect, Component, useLayoutEffect } from 'react';
import { useParams, } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PatientInfo from './PatientInfo';


function BankingRigthTab() {
    function hashMark(str) {
        return (str.replace('hashmark', '#'));
    }

    const { tmpKey } = useParams();

    return (
        <div className="rightTabMain">
            <div className="currentPatient">
                현재 환자 : {hashMark(tmpKey)}
            </div>
            <div>
                <PatientInfo param={tmpKey}></PatientInfo>
            </div>
        </div>

    );
}

export default BankingRigthTab;