// 텍스트 파싱 함수 정의
const parseText = (text) => {
    // 텍스트를 줄바꿈으로 구분하여 각 줄을 탭 또는 쉼표로 분리
    const rows = text.split('\n').map(row => row.split(/\t|,/));
  
    // 처리된 데이터 생성
    const processedData = rows.slice(1).reduce((acc, row) => {
      const chemical = row[1];  // 화학물질 이름
      const nM = row[2];        // nM 값
      const viability = row[3]; // 세포 생존율

    // nM 값을 log10으로 변환
    const lognM = Math.log10(parseFloat(nM));
  
      // 화학물질 데이터가 없으면 객체 초기화
      if (!acc[chemical]) {
        acc[chemical] = { xdata: [], ydata: [] };
      }
      // xdata와 ydata에 데이터 추가
      acc[chemical].xdata.push(lognM);
      acc[chemical].ydata.push(viability);
  
      return acc;
    }, {});
  
    // 파싱된 데이터 반환
    return { rows, processedData };
  };
  
  export default parseText;
  