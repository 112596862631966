import styles from './BankingFrom.module.css';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function BankingForm({ mode, time, item, onSave, onCancel, onDelete, onRetrieve, isEditMode }) {
    const { t } = useTranslation();
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    };
    if (time && time.includes('T') && time.includes('Z')) {
        time = formatDate(time);
    }
    const { tmpKey } = useParams();

    // 모달 및 기안 의견 상태 관리
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [draftOpinion, setDraftOpinion] = useState('');

    // "Save" 버튼 클릭 핸들러 수정
    const handleSaveClick = (e) => {
        e.preventDefault();
        setIsModalOpen(true); // 모달 열기
    };



    const splitDate = (dateString) => {
        if (!dateString) return { year: "", month: "", day: "" };
        const [year, month, day] = dateString.split("-");
        return { year, month, day };
    };

    const initialFormData = {
        ...item,
        FreezingDateYear: splitDate(item?.FreezingDate).year,
        FreezingDateMonth: splitDate(item?.FreezingDate).month,
        FreezingDateDay: splitDate(item?.FreezingDate).day,
        ExporteDateYear: splitDate(item?.ExporteDate).year,
        ExporteDateMonth: splitDate(item?.ExporteDate).month,
        ExporteDateDay: splitDate(item?.ExporteDate).day,
    };
    const oldItem = initialFormData;

    const [formData, setFormData] = useState(initialFormData);
    const [lineList, setLineList] = useState([]);
    const [filteredLineList, setFilteredLineList] = useState([]);
    const [isMaster, setIsMaster] = useState(item?.Master === "1" ? 1 : 0);
    const [patientCode, setPateintCode] = useState({});

    useEffect(() => {
        if (formData.Vessel === '1' || formData.Vessel === '2' || formData.Vessel === '3') {
            setFilteredLineList(lineList.filter(lineItem => lineItem.vesselNo === Number(formData.Vessel)));
        } else {
            setFilteredLineList([]);
        }
    }, [formData.Vessel, lineList]);

    useEffect(() => {
        fetch("/api/getLineList", { method: "post", headers: { "content-type": "application/json" } })
            .then(response => response.json())
            .then(data => {
                setLineList(data);
            });
    }, []);

    useEffect(() => {
        const reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ param: tmpKey }),
        };
        fetch('/api/getPatientCode', reqOption)
            .then(response => response.json())
            .then(data => setPateintCode(data));
    }, [tmpKey]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setIsMaster(checked ? 1 : 0);
        } else {
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }
    };

    // 모달에서 "Submit" 버튼 클릭
    const handleModalSubmit = (e) => {
        e.preventDefault();
        // 기안 의견과 함께 onSave 함수 호출
        if (isEditMode && JSON.stringify(oldItem) === JSON.stringify(formData)) {
            alert("변경사항이 없습니다.");
            return;
        }
        const combinedData = {
            ...formData,
            FreezingDate: `${formData.FreezingDateYear}-${formData.FreezingDateMonth}-${formData.FreezingDateDay}`,
            ExporteDate: `${formData.ExporteDateYear}-${formData.ExporteDateMonth}-${formData.ExporteDateDay}`,
            Master: isMaster,
            ...(time && { ParentTime: time }),
            draftOpinion, // 기안 의견 추가
        };
        // console.log(combinedData);
        onSave(combinedData, isEditMode);
        setIsModalOpen(false); // 모달 닫기
    };

    const handleRetrieveClick = (formData) => {
        alert("반출 기안을 등록하였습니다.");
        onRetrieve(formData);
    }

    const inputComponents = [
        { label: "Patient Code", name: "PatientCode", value: patientCode[0]?.PatientCode || "", disabled: true },
        { label: "Master", name: "Master", type: "checkbox" },
        { label: "Organoid Info Key", name: "Organoid_Info_Key", value: tmpKey.replace("hashmark", "#"), disabled: true },
        { label: "Lab Code", name: "LabCode" },
        { label: "Vessel", name: "Vessel" },
        { label: "Line", name: "Line" },
        { label: "Box", name: "Box" },
        { label: "Position", name: "Position" },
        { label: "Passage No.", name: "PassageNo" },
        { label: "Freezing Date", name: "FreezingDate", type: "date" },
        { label: "Keeper", name: "Keeper" },
        { label: "Freezing Media", name: "FreezingMedia" },
        { label: "Dome/Vial or Cell Number", name: "domePerVialOrCellNumber" },
        { label: "Culture Media", name: "CultureMedia" },
        { label: "Mycoplasma", name: "Mycoplasma", type: "select" },
        { label: "Thawing no.", name: "thawingNo" },
        { label: t("비고"), name: "annotation" },
        { label: "Exporter", name: "Exporter" },
        { label: "Export Date", name: "ExporteDate", type: "date" },
    ].map(({ label, name, value, disabled, type }) => (
        <div className={styles.bankingItem} key={name}>
            <label className={styles.bankingLabel}>{label}:</label>
            {type === 'checkbox' ? (
                <input
                    type="checkbox"
                    name={name}
                    checked={isMaster}
                    onChange={handleChange}
                    className={styles.checkbox}
                    disabled={disabled}
                />
            ) : type === 'date' ? (
                <>
                    <input
                        type="number"
                        name={`${name}Year`}
                        value={formData[`${name}Year`] || ""}
                        onChange={handleChange}
                        className={styles.bankingInput}
                        disabled={disabled}
                        placeholder="YYYY"
                        maxLength="4"
                        min="1900"
                        max="2099"
                    />
                    <span>-</span>
                    <input
                        type="number"
                        name={`${name}Month`}
                        value={formData[`${name}Month`] || ""}
                        onChange={handleChange}
                        className={styles.bankingInput}
                        disabled={disabled}
                        placeholder="MM"
                        maxLength="2"
                        min="1"
                        max="12"
                    />
                    <span>-</span>
                    <input
                        type="number"
                        name={`${name}Day`}
                        value={formData[`${name}Day`] || ""}
                        onChange={handleChange}
                        className={styles.bankingInput}
                        disabled={disabled}
                        placeholder="DD"
                        maxLength="2"
                        min="1"
                        max="31"
                    />
                </>
            ) : type === 'select' ? (
                <select
                    name={name}
                    value={formData[name] || ""}
                    onChange={handleChange}
                    className={styles.bankingInput}
                    disabled={disabled}
                >
                    <option value="">Select</option>
                    <option value="positive">Positive</option>
                    <option value="negative">Negative</option>
                    <option value="NA">NA</option>
                </select>
            ) : name === "Vessel" ? (
                <select
                    name={name}
                    value={formData[name] || ""}
                    onChange={handleChange}
                    className={styles.bankingInput}
                    disabled={disabled}
                >
                    <option value="">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                </select>
            ) : name === "Box" ? (
                <select
                    name={name}
                    value={formData[name] || ""}
                    onChange={handleChange}
                    className={styles.bankingInput}
                    disabled={disabled}
                >
                    <option value="">Select</option>
                    {[...Array(13).keys()].map((i) => (
                        <option key={`BOX${i + 1}`} value={`BOX${i + 1}`}>
                            BOX{i + 1}
                        </option>
                    ))}
                </select>
            ) : name === "Position" ? (
                <input
                    type="number"
                    name={name}
                    value={formData[name] || "0"}
                    onChange={handleChange}
                    className={styles.bankingInput}
                    disabled={disabled}
                    min="0"
                    max="100"
                />
            ) : name === "Line" ? (
                <select
                    name={name}
                    value={formData[name] || ""}
                    onChange={handleChange}
                    className={styles.bankingInput}
                    disabled={disabled}
                >
                    <option value="">Select</option>
                    {filteredLineList.map((lineItem) => (
                        <option key={lineItem.lineName} value={lineItem.lineName}>
                            {lineItem.lineName}
                        </option>
                    ))}
                </select>
            ) : (
                <input
                    type="text"
                    name={name}
                    value={value || formData[name] || ""}
                    onChange={handleChange}
                    className={styles.bankingInput}
                    disabled={disabled}
                />
            )}
        </div>
    ))
    return (
        <form>
            {inputComponents}
            <div className={styles.bankingInputButton}>
                {isEditMode && <button onClick={() => onDelete(formData)}>Delete</button>}
                {isEditMode && formData.Position !== 0 && <button onClick={() => handleRetrieveClick(formData)}>Retrieve</button>}
                <button onClick={handleSaveClick}>Save</button>
                <button onClick={onCancel}>Cancel</button>
            </div>
            {isModalOpen && (
                <div className={styles.modal} onClick={() => setIsModalOpen(false)}>
                    <div className={styles.modalBackground} onClick={e => e.stopPropagation()}>
                        <h2>기안 의견 입력</h2>
                        <textarea
                            value={draftOpinion}
                            onChange={(e) => setDraftOpinion(e.target.value)}
                            placeholder="의견을 입력하세요."
                        />
                        <button onClick={handleModalSubmit}>Submit</button>
                        <button onClick={() => setIsModalOpen(false)}>Cancel</button>
                    </div>
                </div>
            )}

        </form>
    );
}

export default BankingForm;
