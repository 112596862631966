import React, { useState } from 'react';
import styles from "./EditAuthPage.module.css";
import { useTranslation } from 'react-i18next';

function ToggleButton({ defaultValue, label, onToggle, permissionKey }) {
    const [isOn, setIsOn] = useState(defaultValue);

    const handleToggle = () => {
        const newStatus = !isOn;
        setIsOn(newStatus);
        onToggle(permissionKey, newStatus);
    };

    return (
        <span className={styles.buttonLabel}>
            {label}
            <button className={isOn ? styles.on : styles.off} onClick={handleToggle}>
                {isOn ? 'ON' : 'OFF'}
            </button>
        </span>
    );
}


function EditAuthPage(props) {
    const { t } = useTranslation();
    const [userPermissions, setUserPermissions] = useState({
        orgView: props.user.orgView,
        orgEdit: props.user.orgEdit,
        orgChartView: props.user.orgChartView,
        orgChartEdit: props.user.orgChartEdit,
        groupCreate: props.user.groupCreate,
        groupView: props.user.groupView,
        groupEdit: props.user.groupEdit,
        groupDelete: props.user.groupDelete,
        memberAdd: props.user.memberAdd,
        memberView: props.user.memberView,
        memberEdit: props.user.memberEdit,
        authCreate: props.user.authCreate,
        authView: props.user.authView,
        authEdit: props.user.authEdit,
        authDelete: props.user.authDelete,
        basicInfoRead: props.user.basicInfoRead,
        basicInfoWrite: props.user.basicInfoWrite,
        basicInfoDraft: props.user.basicInfoDraft,
        basicInfoApproval: props.user.basicInfoApproval,
        patientPublicRead: props.user.patientPublicRead,
        patientPublicWrite: props.user.patientPublicWrite,
        patientPublicDraft: props.user.patientPublicDraft,
        patientPublicApproval: props.user.patientPublicApproval,
        patientPrivateRead: props.user.patientPrivateRead,
        patientPrivateWrite: props.user.patientPrivateWrite,
        patientPrivateDraft: props.user.patientPrivateDraft,
        patientPrivateApproval: props.user.patientPrivateApproval,
        masterStockRead: props.user.masterStockRead,
        masterStockWrite: props.user.masterStockWrite,
        masterStockDraft: props.user.masterStockDraft,
        masterStockApproval: props.user.masterStockApproval,
        workingStockRead: props.user.workingStockRead,
        workingStockWrite: props.user.workingStockWrite,
        workingStockDraft: props.user.workingStockDraft,
        workingStockApproval: props.user.workingStockApproval,
        masterManageRead: props.user.masterManageRead,
        masterManageWrite: props.user.masterManageWrite,
        masterManageDraft: props.user.masterManageDraft,
        masterManageApproval: props.user.masterManageApproval,
        workingManageRead: props.user.workingManageRead,
        workingManageWrite: props.user.workingManageWrite,
        workingManageDraft: props.user.workingManageDraft,
        workingManageApproval: props.user.workingManageApproval
    });

    const handleToggleChange = (key, value) => {
        setUserPermissions(prevState => ({ ...prevState, [key]: value }));
    };
    const handleSave = () => {
        if (props.mode) {
            console.log(userPermissions);
            console.log(props.user.authName);
            fetch("/api/updateAuthList", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userPermissions,
                    authName: props.user.authName
                }),
            }).then((response) => response.json())
                .then((data) => {
                });
            props.onClose();
        } else {
            fetch("/api/updateUserAuth", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userPermissions,
                    userName: props.user.userName
                }),
            }).then((response) => response.json())
                .then((data) => {
                });
            props.onClose();
        }
    };
    return (
        <div className={styles.modalBackdrop} onClick={props.onClose}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                <h2>{props.user.userName}</h2>
                <h2>{props.user.authName}</h2>

                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>{t('카테고리')}</th>
                            <th>{t('권한명')}</th>
                            <th>{t('설정')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* 기관정보 */}
                        <tr>
                            <td rowSpan="2">{t('기관정보')}</td>
                            <td>{t('기관정보조회')}</td>
                            <td><ToggleButton defaultValue={props.user.orgView} onToggle={handleToggleChange} permissionKey="orgView" /></td>
                        </tr>
                        <tr>
                            <td>{t('기관정보편집')}</td>
                            <td><ToggleButton defaultValue={props.user.orgEdit} onToggle={handleToggleChange} permissionKey="orgEdit" /></td>
                        </tr>

                        {/* 조직도 */}
                        <tr>
                            <td rowSpan="6">{t('조직도')}</td>
                            <td>{t('조직도 조회')}</td>
                            <td><ToggleButton defaultValue={props.user.orgChartView} onToggle={handleToggleChange} permissionKey="orgChartView" /></td>
                        </tr>
                        <tr>
                            <td>{t('조직도 편집')}</td>
                            <td><ToggleButton defaultValue={props.user.orgChartEdit} onToggle={handleToggleChange} permissionKey="orgChartEdit" /></td>
                        </tr>
                        <tr>
                            <td>{t('그룹 생성')}</td>
                            <td><ToggleButton defaultValue={props.user.groupCreate} onToggle={handleToggleChange} permissionKey="groupCreate" /></td>
                        </tr>
                        <tr>
                            <td>{t('그룹 조회')}</td>
                            <td><ToggleButton defaultValue={props.user.groupView} onToggle={handleToggleChange} permissionKey="groupView" /></td>
                        </tr>
                        <tr>
                            <td>{t('그룹 정보 편집')}</td>
                            <td><ToggleButton defaultValue={props.user.groupEdit} onToggle={handleToggleChange} permissionKey="groupEdit" /></td>
                        </tr>
                        <tr>
                            <td>{t('그룹 삭제')}</td>
                            <td><ToggleButton defaultValue={props.user.groupDelete} onToggle={handleToggleChange} permissionKey="groupDelete" /></td>
                        </tr>

                        {/* 멤버 */}
                        <tr>
                            <td rowSpan="3">{t('멤버')}</td>
                            <td>{t('멤버 등록')}</td>
                            <td><ToggleButton defaultValue={props.user.memberAdd} onToggle={handleToggleChange} permissionKey="memberAdd" /></td>
                        </tr>
                        <tr>
                            <td>{t('멤버 정보 조회')}</td>
                            <td><ToggleButton defaultValue={props.user.memberView} onToggle={handleToggleChange} permissionKey="memberView" /></td>
                        </tr>
                        <tr>
                            <td>{t('멤버 정보 편집')}</td>
                            <td><ToggleButton defaultValue={props.user.memberEdit} onToggle={handleToggleChange} permissionKey="memberEdit" /></td>
                        </tr>

                        {/* 권한 */}
                        <tr>
                            <td rowSpan="4">{t('권한')}</td>
                            <td>{t('권한 생성')}</td>
                            <td><ToggleButton defaultValue={props.user.authCreate} onToggle={handleToggleChange} permissionKey="authCreate" /></td>
                        </tr>
                        <tr>
                            <td>{t('권한 조회')}</td>
                            <td><ToggleButton defaultValue={props.user.authView} onToggle={handleToggleChange} permissionKey="authView" /></td>
                        </tr>
                        <tr>
                            <td>{t('권한 편집')}</td>
                            <td><ToggleButton defaultValue={props.user.authEdit} onToggle={handleToggleChange} permissionKey="authEdit" /></td>
                        </tr>
                        <tr>
                            <td>{t('권한 삭제')}</td>
                            <td><ToggleButton defaultValue={props.user.authDelete} onToggle={handleToggleChange} permissionKey="authDelete" /></td>
                        </tr>

                        {/* 오가노이드 */}
                        <tr>
                            <td rowSpan="7">{t('오가노이드')}</td>
                            <td>{t('기본정보')}</td>
                            <td>
                                <ToggleButton label="읽기: " defaultValue={props.user.basicInfoRead} onToggle={handleToggleChange} permissionKey="basicInfoRead" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="쓰기/수정: " defaultValue={props.user.basicInfoWrite} onToggle={handleToggleChange} permissionKey="basicInfoWrite" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="기안: " defaultValue={props.user.basicInfoDraft} onToggle={handleToggleChange} permissionKey="basicInfoDraft" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="결재: " defaultValue={props.user.basicInfoApproval} onToggle={handleToggleChange} permissionKey="basicInfoApproval" />
                            </td>
                        </tr>
                        <tr>
                            <td>{t('환자정보 (공개)')}</td>
                            <td>
                                <ToggleButton label="읽기: " defaultValue={props.user.patientPublicRead} onToggle={handleToggleChange} permissionKey="patientPublicRead" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="쓰기/수정: " defaultValue={props.user.patientPublicWrite} onToggle={handleToggleChange} permissionKey="patientPublicWrite" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="기안: " defaultValue={props.user.patientPublicDraft} onToggle={handleToggleChange} permissionKey="patientPublicDraft" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="결재: " defaultValue={props.user.patientPublicApproval} onToggle={handleToggleChange} permissionKey="patientPublicApproval" />
                            </td>
                        </tr>
                        <tr>
                            <td>{t('환자정보 (비공개)')}</td>
                            <td>
                                <ToggleButton label="읽기: " defaultValue={props.user.patientPrivateRead} onToggle={handleToggleChange} permissionKey="patientPrivateRead" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="쓰기/수정: " defaultValue={props.user.patientPrivateWrite} onToggle={handleToggleChange} permissionKey="patientPrivateWrite" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="기안: " defaultValue={props.user.patientPrivateDraft} onToggle={handleToggleChange} permissionKey="patientPrivateDraft" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="결재: " defaultValue={props.user.patientPrivateApproval} onToggle={handleToggleChange} permissionKey="patientPrivateApproval" />
                            </td>
                        </tr>
                        <tr>
                            <td>[Master Stock] {t('정보')}</td>
                            <td>
                                <ToggleButton label="읽기: " defaultValue={props.user.masterStockRead} onToggle={handleToggleChange} permissionKey="masterStockRead" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="쓰기/수정: " defaultValue={props.user.masterStockWrite} onToggle={handleToggleChange} permissionKey="masterStockWrite" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="기안: " defaultValue={props.user.masterStockDraft} onToggle={handleToggleChange} permissionKey="masterStockDraft" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="결재: " defaultValue={props.user.masterStockApproval} onToggle={handleToggleChange} permissionKey="masterStockApproval" />
                            </td>
                        </tr>
                        <tr>
                            <td>[Working Stock] {t('정보')}</td>
                            <td>
                                <ToggleButton label="읽기: " defaultValue={props.user.workingStockRead} onToggle={handleToggleChange} permissionKey="workingStockRead" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="쓰기/수정: " defaultValue={props.user.workingStockWrite} onToggle={handleToggleChange} permissionKey="workingStockWrite" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="기안: " defaultValue={props.user.workingStockDraft} onToggle={handleToggleChange} permissionKey="workingStockDraft" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="결재: " defaultValue={props.user.workingStockApproval} onToggle={handleToggleChange} permissionKey="workingStockApproval" />
                            </td>
                        </tr>
                        <tr>
                            <td>[Master Stock] {t('반입/반납/반출')}</td>
                            <td>
                                <ToggleButton label="읽기: " defaultValue={props.user.masterManageRead} onToggle={handleToggleChange} permissionKey="masterManageRead" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="쓰기/수정: " defaultValue={props.user.masterManageWrite} onToggle={handleToggleChange} permissionKey="masterManageWrite" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="기안: " defaultValue={props.user.masterManageDraft} onToggle={handleToggleChange} permissionKey="masterManageDraft" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="결재: " defaultValue={props.user.masterManageApproval} onToggle={handleToggleChange} permissionKey="masterManageApproval" />
                            </td>
                        </tr>
                        <tr>
                            <td>[Working Stock] {t('반입/반출')}</td>
                            <td>
                                <ToggleButton label="읽기: " defaultValue={props.user.workingManageRead} onToggle={handleToggleChange} permissionKey="workingManageRead" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="쓰기/수정: " defaultValue={props.user.workingManageWrite} onToggle={handleToggleChange} permissionKey="workingManageWrite" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="기안: " defaultValue={props.user.workingManageDraft} onToggle={handleToggleChange} permissionKey="workingManageDraft" />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleButton label="결재: " defaultValue={props.user.workingManageApproval} onToggle={handleToggleChange} permissionKey="workingManageApproval" />
                            </td>
                        </tr>

                        {/* Add other organoid sections similarly... */}
                    </tbody>
                </table>
                <div className={styles.footerButtons}>
                    <button onClick={handleSave}>{t('저장')}</button>
                    <button onClick={props.onClose}>{t('취소')}</button>
                </div>
            </div>
        </div>
    );
}

export default EditAuthPage;
