// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DraftBankingDetailInfoComponent_main__pHFQm {
    height: calc(90vh - 440px);
    overflow-y: auto;
    padding-top: 30px;
}

.DraftBankingDetailInfoComponent_draftBankingTitle__P4Vcm {
    font-weight: 600;
    font-size: 130%;
    color: #7B878C;
    border-bottom: solid 3px #DEDEDE;
    margin-right: 50px;
    padding-bottom: 15px;
}

.DraftBankingDetailInfoComponent_bankingInfoInDraft__obAtc {
    display: grid;
    grid-template-columns: 32% 1fr;
    padding-top: 20px;
}

.DraftBankingDetailInfoComponent_leftTitle__wIaPZ {
    border-right: solid 3px #DEDEDE;
    font-weight: 600;
    font-size: 100%;
    color: #7B878C;
    padding-top: 10px;
}

.DraftBankingDetailInfoComponent_rightContent__36sAO {
    font-weight: 600;
    font-size: 100%;
    padding-left: 20px;
    padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/DraftBankingDetailInfoComponent.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,gCAAgC;IAChC,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;AACrB;;AAEA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".main {\n    height: calc(90vh - 440px);\n    overflow-y: auto;\n    padding-top: 30px;\n}\n\n.draftBankingTitle {\n    font-weight: 600;\n    font-size: 130%;\n    color: #7B878C;\n    border-bottom: solid 3px #DEDEDE;\n    margin-right: 50px;\n    padding-bottom: 15px;\n}\n\n.bankingInfoInDraft {\n    display: grid;\n    grid-template-columns: 32% 1fr;\n    padding-top: 20px;\n}\n\n.leftTitle {\n    border-right: solid 3px #DEDEDE;\n    font-weight: 600;\n    font-size: 100%;\n    color: #7B878C;\n    padding-top: 10px;\n}\n\n.rightContent {\n    font-weight: 600;\n    font-size: 100%;\n    padding-left: 20px;\n    padding-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `DraftBankingDetailInfoComponent_main__pHFQm`,
	"draftBankingTitle": `DraftBankingDetailInfoComponent_draftBankingTitle__P4Vcm`,
	"bankingInfoInDraft": `DraftBankingDetailInfoComponent_bankingInfoInDraft__obAtc`,
	"leftTitle": `DraftBankingDetailInfoComponent_leftTitle__wIaPZ`,
	"rightContent": `DraftBankingDetailInfoComponent_rightContent__36sAO`
};
export default ___CSS_LOADER_EXPORT___;
