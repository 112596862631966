import React, { useState, useEffect } from 'react';
import styles from './DraftOrganoidDetailInfoComponent.module.css';

function DraftOrganoidDetailInfoComponent(props) {
    const [organoidData, setOrganoidData] = useState();

    const hashmarkToSharp = (str) => {
        return str.replace('hashmark', '#');
    }

    useEffect(() => {
        let reqOption = {
            method: 'post',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                param: props.Key
            }),
        };

        fetch("/api/getOrganoidInfo_noLog", reqOption)
            .then((response) => response.json())
            .then(data => {
                if (data && data.length > 0) {
                    setOrganoidData(data[0]);
                }
            });
    }, []);

    return (
        <>
            {organoidData && (
                <div className={styles.main}>
                    <div className={styles.draftBankingTitle}>Organoid information</div>
                    <div className={styles.bankingInfoInDraft}>
                        <div className={styles.leftTitle}>GBCC Code</div>
                        <div className={styles.rightContent}>{hashmarkToSharp(organoidData.Key)}</div>

                        <div className={styles.leftTitle}>Disease type</div>
                        <div className={styles.rightContent}>{organoidData.Type}</div>

                        <div className={styles.leftTitle}>Subtype</div>
                        <div className={styles.rightContent}>{organoidData.Subtype}</div>

                        <div className={styles.leftTitle}>Sample source</div>
                        <div className={styles.rightContent}>{organoidData.sampleSource}</div>

                        <div className={styles.leftTitle}>Sample type</div>
                        <div className={styles.rightContent}>{organoidData.SampleType}</div>

                        <div className={styles.leftTitle}>Site</div>
                        <div className={styles.rightContent}>{organoidData.site}</div>

                        <div className={styles.leftTitle}>Sugery date</div>
                        <div className={styles.rightContent}>{organoidData.surgeryDate}</div>

                        <div className={styles.leftTitle}>Establishment no.</div>
                        <div className={styles.rightContent}>{organoidData.establishmentNo}</div>

                        <div className={styles.leftTitle}>N/T pair</div>
                        <div className={styles.rightContent}>{organoidData.Pair}</div>

                        <div className={styles.leftTitle}>WES</div>
                        <div className={styles.rightContent}>{organoidData.WES}</div>

                        <div className={styles.leftTitle}>RNA seq.</div>
                        <div className={styles.rightContent}>{organoidData.RNASeq}</div>

                        <div className={styles.leftTitle}>Mutation information</div>
                        <div className={styles.rightContent}>
                            {organoidData.MutationInformation?.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>

                        <div className={styles.leftTitle}>PDO annotation</div>
                        <div className={styles.rightContent}>
                            {organoidData.MSS?.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default DraftOrganoidDetailInfoComponent;
