// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserName_UserName__iRYh7 {
    display: grid;
    grid-template-columns: 1fr 16fr;
}

.UserName_basicProfile__TTt7w {
    width: 100%;
}

.UserName_spanLeft__ahphw {
    width: 100%;
}

.UserName_spanRight__dDIiC {
    margin-left: 1%;
    margin-bottom: 20px;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/organoid/managementComponent/ManagementSubComponent/UserName.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,+BAA+B;AACnC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".UserName {\n    display: grid;\n    grid-template-columns: 1fr 16fr;\n}\n\n.basicProfile {\n    width: 100%;\n}\n\n.spanLeft {\n    width: 100%;\n}\n\n.spanRight {\n    margin-left: 1%;\n    margin-bottom: 20px;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserName": `UserName_UserName__iRYh7`,
	"basicProfile": `UserName_basicProfile__TTt7w`,
	"spanLeft": `UserName_spanLeft__ahphw`,
	"spanRight": `UserName_spanRight__dDIiC`
};
export default ___CSS_LOADER_EXPORT___;
