import React, { useEffect, useState } from "react";
import styles from "./PDOModel.module.css";
import CancerName from "./CancerName";
import downTriangleIcon from "../../img/icon/DownTriangleIcon.png";
import sideTriangleIcon from "../../img/icon/SideTriangleIcon.png";


function PDOModel(props) {
    const [visibleFirst, setVisibleFirst] = useState(false);
    const [cancerDatas, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // 한 페이지에 표시할 아이템 수

    // 현재 페이지에 표시할 데이터 계산
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = cancerDatas.slice(indexOfFirstItem, indexOfLastItem);

    // 페이지 번호 변경 핸들러
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // 전체 페이지 수 계산
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(cancerDatas.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    function checkZero(num) {
        if (num === 0 || num === '') {
            return '';
        } else {
            return "(" + num + ")";
        }
    }

    let str = '';
    if (props.search != '') {
        if (props.search.includes('hashmark')) {
            str = "AND `Key` LIKE '%" + props.search + "%'";
        } else {
            str = "AND REPLACE(`Key`, 'hashmark', '') LIKE '%" + props.search + "%'";
        }
    }

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                param: props.name,
                condition: str,
            })

        }

        fetch('/api/getCancerData', reqOption)
            .then((response) => response.json())
            .then(data => {
                setData(data);
            });
    }, [props.search, props.name]);


    function returnFalse(value) {
        props.returnFalse(value);
    }

    return (
        <div className={styles.pdoObject}>
            <div>
                <span className={styles.outlinePdo} onClick={(e) => {
                    setVisibleFirst(!visibleFirst);
                }}>
                    {!visibleFirst && <img className={styles.plusImg_low} src={sideTriangleIcon} alt="Expand" />}
                    {visibleFirst && <img className={styles.minusImg_low} src={downTriangleIcon} alt="Collapse" />}
                </span>
                <span className={styles.pdoName} onClick={(e) => {
                    setVisibleFirst(!visibleFirst);
                }}>
                    {props.name}
                    <span className={styles.cancerDataNum}>
                        {checkZero(cancerDatas.length)}
                    </span>
                </span>
            </div>
            {visibleFirst && (
                <div className={styles.contentContainer}>
                    <div className={styles.cancerNamesContainer}>
                        {currentItems.map((data, index) => (
                            <CancerName data={data} returnFalse={returnFalse} key={index} />
                        ))}
                    </div>
                    <div className={styles.paginationContainer}>
                        {pageNumbers.map(number => (
                            <span key={number} className={currentPage === number ? styles.activePage : styles.pageNumber} onClick={() => handlePageClick(number)}>
                                {number}
                            </span>
                        ))}
                    </div>
                </div>
            )}

        </div>
    );
}

export default PDOModel;