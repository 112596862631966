import React from "react";
import "./MobileFooter.css";
import logo from "../../img/logo.png";
import convergence from "../../img/bioconvergence.png";
import img1 from "../../img/footer-g.png";
import img2 from "../../img/footer-r.png";
import img3 from "../../img/footer-a.png";
import img4 from "../../img/footer-d.png";
import img5 from "../../img/footer-i.png";
import img6 from "../../img/footer-a2.png";
import img7 from "../../img/footer-n.png";
import img8 from "../../img/footer-t.png";

function MobileFooter() {
    return (
        <div className="mobileFoot">
            <div className="mobilecompany-name">
                {/* <div className="mobilex48">
                    <div className="mobilex45">
                        <div className="mobilex44">
                            <div className="mobileflex-col">
                                <div className="mobilex43"></div>
                                <div className="mobileflex-row">
                                    <img className="mobilex169" src={img1} alt="mg" />
                                    <img className="mobilex162" src={img2} alt="mr" />
                                    <img className="mobilex163" src={img3} alt="ma" />
                                </div>
                            </div>
                            <img className="mobilex165" src={img4} alt="md" />
                            <img className="mobilex166" src={img5} alt="mi" />
                            <img className="mobilex164" src={img6} alt="ma2" />
                            <img className="mobilex167" src={img7} alt="mn" />
                            <img className="mobilex168" src={img8} alt="mt" />
                        </div>
                    </div>
                    <div className="mobilex47"></div>
                </div> */}
                <div className="mobileFooterCompanyNameBox">
                    <span>
                        <img src={logo} className="mobileFooterCompanyName" />
                    </span>
                    <span>
                        <img src={convergence} className="mobileFooterConvergence" />
                    </span>
                </div>
                <p className="mobileCopyright-2022-gra">
                    4th floor, PNS Homes Tower, 6, Jeongui-ro 7-gil, Songpa-gu, Seoul, Republic of Korea, 05835<br /><br />

                    Tel +82.2.6483.1400 Fax +82.2.6483.3400<br /><br />

                    Email gbcc@gradiantbio.com<br /><br />
                    Copyright © 2022 Gradiant Bioconvergence All rights reserved.
                </p>
            </div>
            <div className="mobileTextBox">

            </div>
        </div>
    );
}

export default MobileFooter;