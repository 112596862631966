import React, { useState, useEffect } from "react";
import styles from "./UserModal.module.css";

function UserModal(props) {
    const { onClose, userInfo } = props;
    const [editing, setEditing] = useState(false);
    const [editedInfo, setEditedInfo] = useState({
        userTeamName: userInfo.userTeamName,
        authLevel: userInfo.authLevel, // authLevel 추가
    });

    const [authLevel, setAuthLevel] = useState(0);
    const [teamList, setTeamList] = useState(null);

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/getAuthLevel", reqOption)
            .then((response) => response.json())
            .then(data => {
                setAuthLevel(data?.authLevel);
            });
    }, []);

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/getTeamList", reqOption)
            .then((response) => response.json())
            .then(data => {
                setTeamList(data);
            });
    }, []);



    const authLevelMap = {
        0: "권한 없음",
        1: "연구원",
        2: "팀장",
        3: "LM",
        4: "대표이사",
        5: "시스템 관리자",
    };

    const handleEdit = () => {
        setEditing(true);
    };

    const handleSave = () => {
        // 백엔드에 업데이트 로직 추가
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                userTeamName: editedInfo.userTeamName,
                authLevel: editedInfo.authLevel,
                userName: userInfo.userName,
            }),
        }

        fetch("/api/updateUserList", reqOption)
            .then((response) => response.json())
            .then(data => {
                userInfo.userTeamName = editedInfo.userTeamName;
                userInfo.authLevel = editedInfo.authLevel;
            });
        setEditing(false);
        // props.closeModal();
        onClose();
    };

    const handleCancel = () => {
        setEditing(false);
        setEditedInfo({
            userTeamName: userInfo.userTeamName,
            authLevel: userInfo.authLevel,
        });
    };

    const handleExport = () => {
        if (window.confirm("내보내시겠습니까?")) {
            let reqOption = {
                method: "post",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    userName: userInfo.userName,
                }),
            }

            fetch("/api/deleteUserList", reqOption)
                .then((response) => response.json())
                .then(data => {
                });
            setEditing(false);
            // props.closeModal();
            onClose();
        }
    };

    return (
        <div className={styles.modal}>
            <div className={styles['modal-content']}>
                <div className={styles.name}>
                    {userInfo.userName}
                </div>
                {editing ? (
                    <>
                        <div className={styles.title}>이메일</div>
                        <div className={styles.value}>
                            {userInfo.email}
                        </div>
                        <div className={styles.title}>소속</div>
                        <select className={styles.value} value={editedInfo.userTeamName} onChange={(e) => setEditedInfo({ ...editedInfo, userTeamName: e.target.value })}>
                            {teamList?.map((team) => (
                                <option key={team.teamId} value={team.teamName}>
                                    {team.teamName}
                                </option>
                            ))}
                        </select>
                        <div className={styles.title}>권한</div>
                        <select className={styles.value} value={editedInfo.authLevel} onChange={(e) => setEditedInfo({ ...editedInfo, authLevel: e.target.value })}>
                            <option value={0}>{authLevelMap[0]}</option>
                            <option value={1}>{authLevelMap[1]}</option>
                            <option value={2}>{authLevelMap[2]}</option>
                            <option value={3}>{authLevelMap[3]}</option>
                        </select>
                        {/* {authLevelMap[userInfo.authLevel]} */}
                    </>
                ) : (
                    <>
                        <div className={styles.title}>이메일</div>
                        <div className={styles.value}>{userInfo.email}</div>
                        <div className={styles.title}>소속</div>
                        <div className={styles.value}>{userInfo.userTeamName}</div>
                        <div className={styles.title}>권한</div>
                        <div className={styles.value}>{authLevelMap[userInfo.authLevel]}</div>
                    </>
                )}
                <br />
                {editing ? (
                    <>
                        <button onClick={handleSave}>저장</button>
                        <button onClick={handleCancel}>취소</button>
                    </>
                ) : (
                    <>
                        {authLevel >= 3 && authLevel >= userInfo.authLevel && (
                            <>
                                <button onClick={handleExport}>내보내기</button>
                                <button onClick={handleEdit}>정보 편집</button>
                            </>
                        )}
                        <button onClick={props.onClose}>닫기</button>
                    </>
                )}
            </div>
        </div>

    );
}

export default UserModal;
