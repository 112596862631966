// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BankingInfoStatsModal_statsTable__g8JSm {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin-top: 20px;
}

.BankingInfoStatsModal_statsTable__g8JSm th,
.BankingInfoStatsModal_statsTable__g8JSm td {
    border: 1px solid #ddd;
    padding: 8px;
}

.BankingInfoStatsModal_statsTable__g8JSm th {
    background-color: #f2f2f2;
}

/* 추가된 셀 병합 스타일 */
.BankingInfoStatsModal_groupHeader__1C90U {
    background-color: #e7e7e7;
    font-weight: bold;
}

/* 셀 병합을 위한 CSS 클래스 */
.BankingInfoStatsModal_spanCell__XrhtR {
    grid-column: span 2 / auto; /* 2개의 열을 합치기 */
}

.BankingInfoStatsModal_quantityCell__Y\\+dfk {
    text-align: center; /* 수량은 중앙 정렬로 표시 */
}
`, "",{"version":3,"sources":["webpack://./src/organoid/rightTab/BankingInfoStatsModal.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;;IAEI,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA,iBAAiB;AACjB;IACI,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA,qBAAqB;AACrB;IACI,0BAA0B,EAAE,eAAe;AAC/C;;AAEA;IACI,kBAAkB,EAAE,kBAAkB;AAC1C","sourcesContent":[".statsTable {\n    width: 100%;\n    border-collapse: collapse;\n    text-align: left;\n    margin-top: 20px;\n}\n\n.statsTable th,\n.statsTable td {\n    border: 1px solid #ddd;\n    padding: 8px;\n}\n\n.statsTable th {\n    background-color: #f2f2f2;\n}\n\n/* 추가된 셀 병합 스타일 */\n.groupHeader {\n    background-color: #e7e7e7;\n    font-weight: bold;\n}\n\n/* 셀 병합을 위한 CSS 클래스 */\n.spanCell {\n    grid-column: span 2 / auto; /* 2개의 열을 합치기 */\n}\n\n.quantityCell {\n    text-align: center; /* 수량은 중앙 정렬로 표시 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statsTable": `BankingInfoStatsModal_statsTable__g8JSm`,
	"groupHeader": `BankingInfoStatsModal_groupHeader__1C90U`,
	"spanCell": `BankingInfoStatsModal_spanCell__XrhtR`,
	"quantityCell": `BankingInfoStatsModal_quantityCell__Y+dfk`
};
export default ___CSS_LOADER_EXPORT___;
