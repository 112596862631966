import React, { useState, useEffect } from 'react';
import { useParams, } from 'react-router-dom';
import styles from './PatientInfo_private.module.css';
import { useTranslation } from 'react-i18next';

function PatientInfo_private({ }) {
    const { t } = useTranslation();
    const { tmpKey } = useParams();
    const [patientInfoData, setPatientInfoData] = useState({
        bigCategories: [],
        smallCategories: [],
        rowData: [],
    });


    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                param: tmpKey,
            })
        }
        fetch("/api/getPrivatePatientInfo", reqOption)
            .then((response) => response.json())
            .then(data => {
                const formattedData = formatData(data);
                setPatientInfoData(formattedData);
            });
    }, [tmpKey]);

    const formatData = (data) => {
        const bigCategoriesSet = new Set();
        const smallCategoriesSet = new Set();
        const rowData = {};
        const bigCategoryCounts = {}; // 추가

        data.forEach((item) => {
            bigCategoriesSet.add(item.Big_Category);
            smallCategoriesSet.add(item.Small_Category);

            if (!rowData[item.Patient_Info_Key]) {
                rowData[item.Patient_Info_Key] = {};
            }
            rowData[item.Patient_Info_Key][item.Small_Category] = item.Value;

            // 각 bigCategory별 개수를 저장
            if (!bigCategoryCounts[item.Big_Category]) {
                bigCategoryCounts[item.Big_Category] = 0;
            }
            bigCategoryCounts[item.Big_Category]++;
        });

        const bigCategories = [...bigCategoriesSet];
        const smallCategories = [...smallCategoriesSet];

        return {
            bigCategories,
            smallCategories,
            rowData: Object.values(rowData),
            bigCategoryCounts, // 추가
        };
    };

    return (
        <div className={styles.tableContainer}>
            {patientInfoData.rowData.length > 0 ? (
                <table className={styles.table}>
                    <thead>
                        <tr>
                            {patientInfoData.bigCategories.map((bigCategory, index) => (
                                <th
                                    key={index}
                                    className={styles.bigCategoryTh}
                                    colSpan={patientInfoData.bigCategoryCounts[bigCategory]}
                                >
                                    {bigCategory}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            {patientInfoData.smallCategories.map((smallCategory, index) => (
                                <th key={index} className={styles.smallCategoryTh}>
                                    {smallCategory}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {patientInfoData.rowData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {patientInfoData.smallCategories.map((smallCategory, index) => (
                                    <td key={index} className={styles.td}>
                                        {row[smallCategory]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className={styles.noDataMessage}>{t('비공개 환자 데이터가 없습니다')}</div>
            )}
        </div>
    );

}

export default PatientInfo_private;
