// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserModal_modal__nBuj\\+ {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* 배경 반투명 검정색 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* 다른 요소보다 위에 나타나도록 함 */
}

.UserModal_modal-content__ZOXs2 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 400px;
    /* 원하는 최대 너비 설정 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

button {
    cursor: pointer;
    background-color: #f2f2f2;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.UserModal_name__fPVrP {
    font-size: 120%;
    font-weight: 700;
}

.UserModal_title__\\+G\\+p1 {
    font-weight: 600;
    margin-top: 30px;
}

.UserModal_value__\\+RG-A {
    font-size: 90%;
    color: gray;
}`, "",{"version":3,"sources":["webpack://./src/organoid/managementComponent/ManagementSubComponent/UserModal.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,gBAAgB;IAChB,iBAAiB;IACjB,uCAAuC;IACvC,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,WAAW;AACf","sourcesContent":[".modal {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    /* 배경 반투명 검정색 */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n    /* 다른 요소보다 위에 나타나도록 함 */\n}\n\n.modal-content {\n    background-color: #ffffff;\n    padding: 20px;\n    border-radius: 10px;\n    width: 80%;\n    max-width: 400px;\n    /* 원하는 최대 너비 설정 */\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    text-align: center;\n}\n\nbutton {\n    cursor: pointer;\n    background-color: #f2f2f2;\n    border: none;\n    padding: 5px 10px;\n    border-radius: 5px;\n    margin-bottom: 10px;\n}\n\n.name {\n    font-size: 120%;\n    font-weight: 700;\n}\n\n.title {\n    font-weight: 600;\n    margin-top: 30px;\n}\n\n.value {\n    font-size: 90%;\n    color: gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `UserModal_modal__nBuj+`,
	"modal-content": `UserModal_modal-content__ZOXs2`,
	"name": `UserModal_name__fPVrP`,
	"title": `UserModal_title__+G+p1`,
	"value": `UserModal_value__+RG-A`
};
export default ___CSS_LOADER_EXPORT___;
