// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BankingList_Print_container__h-SAa {
    width: 100%;
    padding: 20px;
    font-family: Arial, sans-serif;
}

h2,
h3 {
    margin: 0;
    padding: 5px 0;
}

h2 {
    font-size: 1.5em;
}

h3 {
    font-size: 1.2em;
}

button {
    margin: 10px 0;
    padding: 5px;
    font-size: 1em;
    cursor: pointer;
}

.BankingList_Print_header__eokSr {
    cursor: pointer;
    text-decoration: underline;
}

.BankingList_Print_toggleImage__DItDK {
    margin-right: 5px;
    vertical-align: middle;
    width: 10px;
    height: 10px;
}`, "",{"version":3,"sources":["webpack://./src/organoid/detail/BankingList_Print.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;AAClC;;AAEA;;IAEI,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".container {\n    width: 100%;\n    padding: 20px;\n    font-family: Arial, sans-serif;\n}\n\nh2,\nh3 {\n    margin: 0;\n    padding: 5px 0;\n}\n\nh2 {\n    font-size: 1.5em;\n}\n\nh3 {\n    font-size: 1.2em;\n}\n\nbutton {\n    margin: 10px 0;\n    padding: 5px;\n    font-size: 1em;\n    cursor: pointer;\n}\n\n.header {\n    cursor: pointer;\n    text-decoration: underline;\n}\n\n.toggleImage {\n    margin-right: 5px;\n    vertical-align: middle;\n    width: 10px;\n    height: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BankingList_Print_container__h-SAa`,
	"header": `BankingList_Print_header__eokSr`,
	"toggleImage": `BankingList_Print_toggleImage__DItDK`
};
export default ___CSS_LOADER_EXPORT___;
