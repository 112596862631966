import React, { useState } from "react";
import './Image.css';

function Image(props) {
    // 모달 및 기안 의견 상태 관리
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [draftOpinion, setDraftOpinion] = useState('');
    var name = props.picture.picture_key;
    var nameString = "";
    var str = String(props.picture.picture_key);
    str = str.replace('.jpg', '');
    str = str.replace('.jpeg', '');
    str = str.replace(String(props.picture.cancer_data_Key) + '_', '');
    var first = '';
    var second = '';
    var third = '';
    var arr = str.split('_');
    first = arr[0];
    for (var i = 1; i < arr.length; i++) {
        if (arr[i].charAt(0) == 'P') {
            second = arr[i];
            break;
        }
    }
    for (var i = 1; i < arr.length; i++) {
        if (arr[i].charAt(arr[i].length - 1) == 'X') {
            third = arr[i];
            break;
        }
    }
    if (second == "P99") {
        var result = first + '_' + third;
    } else {
        var result = first + '_' + second + '_' + third;
    }
    const [authLevel, setAuthLevel] = useState();

    fetch('/api/getAuthLevel', { method: 'POST' })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            if (data) {
                setAuthLevel(data.authLevel);
            }
        })

    // "삭제" 버튼 클릭 핸들러 수정
    const handleDeleteClick = () => {
        setIsModalOpen(true); // 모달 열기
    };

    const handleConfirmDelete = () => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                picture_key: props.picture?.picture_key,
                draftOpinion: draftOpinion,
                tableName: props.tableName,
            }),
        }
        fetch("/api/deleteImage", reqOption)
            .then((response) => response.json())
            .then(data => {
                alert(data?.message);
            });

        // 모달 닫기
        setIsModalOpen(false);
    }
    // var arr = str.split('X_');
    // console.log(arr);
    // console.log("arrarrarr");
    // var tmp = "";
    // if (arr.length >= 2) {
    //     for (var i = 0; i < arr.length - 1; i++) {
    //         if (i != 0) {
    //             tmp += 'X_';
    //         }
    //         tmp += arr[i];
    //     }
    //     tmp += 'X';
    //     str = tmp;
    // }
    // arr = str.split("_");
    // if (arr.length == 4) {
    //     str = arr[0] + '_' + arr[1] + '_' + arr[3];
    // }



    // console.log(props.picture.picture);
    const newBlob = new Blob([new Uint8Array(props.picture.picture?.data)], { type: 'image/jpg' });

    // console.log(props.picture.picture);
    const url = URL.createObjectURL(newBlob);

    return (
        <div className="imageOne">
            <img src={url} width="200px"></img>
            <div className="imageSetting">{result}</div>
            {
                authLevel && authLevel > 1 &&
                <div className="삭제div">
                    <button className="삭제button" onClick={handleDeleteClick}>삭제</button>
                </div>
            }
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>기안 의견 입력</h2>
                        <textarea
                            value={draftOpinion}
                            onChange={(e) => setDraftOpinion(e.target.value)}
                            placeholder="기안 의견을 입력하세요."
                        ></textarea>
                        <button onClick={handleConfirmDelete}>확인</button>
                        <button onClick={() => setIsModalOpen(false)}>취소</button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Image;