import React, { useEffect, useState } from "react";
import "./CancerName.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';

var before = '';

function CancerName(props) {
    const value = props.data['Key'] || props.data['Organoid_Info_Key'];
    function hashmark(str) {
        if (str) {
            return (str.replace('hashmark', '#'));
        } else {
            return str;
        }
    }

    return (
        <div className="cancerName">
            <div className="customLink">
                {/* {hashmark(props.data['Key'])} */}
                <Link to={"/organoid/" + value} className="customLink">
                    {
                        hashmark(props.data['Key'] || hashmark(props.data['Organoid_Info_Key']))
                    }
                </Link>
            </div>
        </div>
    )
}

export default CancerName;