import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./MobilePatientContent.css";


function MobilePatientContent(props) {
    function hashing(str) {
        str = str.replace('hashmark', '#');
        if (!str) {
            str = "NA";
        }
        return str;
    }
    function genderName(str) {
        if (str == '1') {
            return "Male";
        } else if (str == '2') {
            return 'Female';
        } else {
            return "Not define yet";
        }
    }

    function convertHistologyType(num) {
        switch (num) {
            case '1':
                return "papillary";
            case '2':
                return "WD";
            case '3':
                return "MD";
            case '4':
                return "PD";
            case '5':
                return "mucinous";
            case '6':
                return "SRc";
            case '7':
                return "others";
            default:
                return "NA";
        }
    }

    function convertStage(num) {
        switch (num) {
            case '1':
                return "IA";
            case '2':
                return "IB";
            case '3':
                return "IIA";
            case '4':
                return "IIB";
            case '5':
                return "IIIA";
            case '6':
                return "IIIB";
            case '7':
                return "IIIC";
            case '8':
                return "IV";
            default:
                return "NA";
        }
    }

    function convertSpecialFeature(num) {
        switch (num) {
            case '1':
                return "intestinal";
            case '2':
                return "diffuse";
            case '3':
                return "mixed";
            case '4':
                return "indeterminate";
            case '5':
                return "other";
            default:
                return "NA";
        }
    }

    function checkColorectalTreatmentHistory(str) {
        if (str == "1") {
            return "Yes";
        } else if (str == "2") {
            return "NA";
        }
        return str;
    }

    function checkNA(str) {
        if (str == "na" || str == "N/A" || str == "") {
            return "NA";
        }
        return str;
    }

    var tmp = JSON.stringify(props.data['Treatment history']);

    // function nextLine(str){
    //     str = JSON.stringify(str);
    //     str = str.split('\n');
    //     console.log(str);
    //     // return str.split('\n');
    // }

    return (
        <div className="">
            <div className="summaryLine">
                <span className="mobileContentP1">Gender</span>
                <span className="mobileContent11">{genderName(props.data['Gender'])}</span>
            </div>
            <div>
                <span className="mobileContentP2">Age</span>
                <span className="mobileContent11">{hashing(props.data['Age'])}</span>
            </div>
            <div>
                <span className="mobileContentP3">Stage</span>
                <span className="mobileContent11">{convertStage(props.data['Stage'])}</span>
            </div>
            <div>
                <span className="mobileContentPT4">Histologic type</span>
                <span className="mobileContentP51">
                    {/* {hashing(props.data['Histologic type'])} */}
                    {
                        JSON.stringify(props.data['Histologic type']).replace(/"/gi, '').split("+").map(function (item, idx) {
                            // "1+2+3+4".split("+").map(function (item, idx) {
                            return (
                                <span key={idx}>
                                    {convertHistologyType(item)}
                                    <br />
                                </span>
                            )
                        })
                    }
                </span>
            </div>
            <div>
                <span className="mobileContentPP4">Site</span>
                <span className="mobileContentP51">{hashing(props.data['Site'])}</span>
            </div>
            <div>
                <span className="mobileContentP5">Special feature</span>
                <span className="mobileContent11">{convertSpecialFeature(props.data['Special Feature'])}</span>
            </div>
            <div>
                <span className="mobileContentPP4">Treatment history</span>
                <span className="mobileContentP51">
                    {
                        JSON.stringify(props.data['Treatment history']).split("\\n").map(function (item, idx) {
                            return (
                                <span key={idx}>
                                    {checkColorectalTreatmentHistory(checkNA(item.replace(/\\/g, '').replace(/"/g, '')))}
                                    <br />
                                </span>
                            )
                        })
                    }
                </span>
            </div>


        </div>
    );
}

export default MobilePatientContent;