import React, { useState, useEffect } from "react";
import styles from "./MemberManagement.module.css";
import EditAuthPage from "./EditAuthPage";
import UploadMemberExcel from "./UploadMemberExcel";

//이미지 임포트
import filterIcon from "../../../img/common/filterIcon.png";

function MemberManagement() {
    const [userList, setUserList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchWord, setSearchWord] = useState("");
    const [teamList, setTeamList] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null); // 현재 선택된 사용자의 상태
    const [userPermissions, setUserPermissions] = useState({}); // 현재 선택된 사용자의 권한 상태
    const [reDraw, setReDraw] = useState(0); // 화면 새로 고침용 변수

    // 멤버일괄추가 변수
    const [showAddAllUser, setShowAddAllUser] = useState(false);
    const handleShowUpload = () => {
        setShowAddAllUser(true);
    }

    const handleCloseUpload = () => {
        setShowAddAllUser(false);
    }

    // 페이지네이션을 위한 상태 변수
    const [currentPage, setCurrentPage] = useState(1);  // 현재 페이지
    const itemsPerPage = 10;  // 한 페이지에 보여줄 항목 수

    // 페이지 변경 핸들러
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const filteredUsers = userList
        ?.filter(user => searchWord === "" || user.userName.includes(searchWord))
        .filter(user => selectedTeams.length === 0 || selectedTeams.includes(user.userTeamName));

    const paginatedUsers = filteredUsers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    //멤버 추가 변수
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [newUser, setNewUser] = useState({
        name: "",
        email: "",
        team: "",
        authLevel: 0,
    });

    //모달 표시 함수
    const openAddUserModal = () => {
        setShowAddUserModal(true);
        setNewUser({
            team: "미지정",
            name: "",
            email: "",
            authLevel: 0,
        })
    };

    const closeAddUserModal = () => {
        setShowAddUserModal(false);
        setNewUser({
            name: "",
            email: "",
            team: "",
            authLevel: 0,
        });
        setReDraw(reDraw + 1);
    };

    //사용자 정보 변경 헨들러
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    //서버에 사용자 추가 함수
    const handleAddMember = () => {
        // 서버에 newUser 정보 전송하는 로직 구현 필요.
        // 예시: fetch API 사용
        fetch("/api/addMember", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newUser),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    // 사용자 리스트를 다시 가져오거나, 상태에 직접 추가
                    setUserList([...userList, data.user]);
                    closeAddUserModal();
                } else {
                    // 에러 처리 로직
                    console.error(data.message);
                }
            });
        closeAddUserModal();
    };

    // 권한 모달 보여주기
    const showPermissionModal = (user) => {
        setSelectedUser(user);
    };

    const offPermissionModal = () => {
        setReDraw(reDraw + 1);
        setSelectedUser(null);
    }



    // 권한 저장 함수
    const savePermissions = () => {
        // DB에 userPermissions 상태를 저장하는 로직 필요
        setSelectedUser(null); // 모달 닫기
    };

    const inputChange = (event) => {
        setSearchWord(event.target.value);
    }

    //유저리스트 가져오기
    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/getUserList", reqOption)
            .then((response) => response.json())
            .then(data => {
                setUserList(data);
                console.log(data);
            });
    }, [reDraw]);

    //팀리스트 가져오기
    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/getTeamList", reqOption)
            .then((response) => response.json())
            .then(data => {
                setTeamList(data.map(t => t.teamName)); // 팀 이름만 저장
            });
    }, []);

    const handleUserCheck = (user, isChecked) => {
        if (isChecked) {
            setSelectedUsers([...selectedUsers, user]);
        } else {
            setSelectedUsers(selectedUsers.filter(u => u !== user));
        }
    };

    const handleCheckAll = (isChecked) => {
        if (isChecked) {
            setSelectedUsers(userList);
        } else {
            setSelectedUsers([]);
        }
    };

    const authLevelToString = (level) => {
        const authMap = ["권한없음", "연구원", "팀장", "LM", "대표이사", "시스템관리자"];
        return authMap[level] || "알 수 없는 권한";
    }

    const handleTeamCheck = (teamName, isChecked) => {
        if (isChecked) {
            setSelectedTeams([...selectedTeams, teamName]);
        } else {
            setSelectedTeams(selectedTeams.filter(t => t !== teamName));
        }
    };

    const handleCheckAllTeams = (isChecked) => {
        if (isChecked) {
            setSelectedTeams(teamList);
        } else {
            setSelectedTeams([]);
        }
    };

    const applyTeamFilter = () => {
        setShowFilterModal(false);
    };

    return (
        <>
            <div className={styles.멤버}>
                멤버
            </div>
            <div className={styles.멤버메뉴바}>
                <div className={styles.검색필드}>
                    <span>
                        <input type="text" value={searchWord} onChange={inputChange} placeholder="멤버 이름으로 검색해주세요" className={styles.멤버이름검색창}></input>
                    </span>
                    <span>
                        <button className={styles.commonButton} onClick={() => setShowFilterModal(true)}>
                            <img src={filterIcon} className={styles.iconImgSize}></img>
                            필터
                        </button>
                    </span>
                </div>
                <div className={styles.멤버추가버튼}>
                    <button onClick={openAddUserModal} className={styles.멤버버튼}>멤버 추가</button>
                    {showAddUserModal && (
                        <div className={styles.modalOverlay}>
                            <div className={styles.modal}>
                                <h3>멤버 추가</h3>
                                <label>
                                    이름:
                                    <input
                                        type="text"
                                        name="name"
                                        value={newUser.name}
                                        onChange={handleInputChange}
                                    />
                                </label>
                                <label>
                                    이메일:
                                    <input
                                        type="email"
                                        name="email"
                                        value={newUser.email}
                                        onChange={handleInputChange}
                                    />
                                </label>
                                <label>
                                    소속:
                                    <select
                                        name="team"
                                        value={newUser.team}
                                        onChange={handleInputChange}
                                    >
                                        {teamList.map(teamName => (
                                            <option key={teamName} value={teamName}>
                                                {teamName}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                                <label>
                                    권한:
                                    <select
                                        name="authLevel"
                                        value={newUser.authLevel}
                                        onChange={handleInputChange}
                                    >
                                        <option value="0" >권한 없음</option>
                                        <option value="1">연구원</option>
                                        <option value="2">팀장</option>
                                        <option value="3">LM</option>
                                    </select>
                                </label>
                                <button className={styles.addButton} onClick={handleAddMember}>추가</button>
                                <button className={styles.cancelButton} onClick={closeAddUserModal}>취소</button>

                            </div>
                        </div>
                    )}
                    <button onClick={handleShowUpload}>멤버 일괄 추가</button>
                    {showAddAllUser && (
                        <UploadMemberExcel onClose={handleCloseUpload} />
                    )}
                </div>
            </div>
            {showFilterModal && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modal}>
                        <h3>팀 선택</h3>
                        <div>
                            <input type="checkbox" onChange={(e) => handleCheckAllTeams(e.target.checked)} />전체 선택
                        </div>
                        {teamList.map(teamName => (
                            <div key={teamName}>
                                <input
                                    type="checkbox"
                                    checked={selectedTeams.includes(teamName)}
                                    onChange={(e) => handleTeamCheck(teamName, e.target.checked)}
                                />
                                {teamName}
                            </div>
                        ))}
                        <button onClick={applyTeamFilter}>저장</button>
                        <button onClick={() => setShowFilterModal(false)}>취소</button>
                    </div>
                </div>
            )}
            <div>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>
                                <input type="checkbox" onChange={(e) => handleCheckAll(e.target.checked)} />
                            </th>
                            <th>멤버정보</th>
                            <th>소속</th>
                            <th>권한</th>
                            <th>권한</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedUsers.map(user => (
                            <tr key={user.personalId}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedUsers.includes(user)}
                                        onChange={(e) => handleUserCheck(user, e.target.checked)}
                                    />
                                </td>
                                <td>{user.userName} ({user.email})</td>
                                <td>{user.userTeamName}</td>
                                <td>{authLevelToString(user.authLevel)}</td>
                                <td>
                                    <button onClick={() => showPermissionModal(user)} className={styles.관리버튼}>관리</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* 페이지네이션 버튼들 */}
            <div className={styles.pagination}>
                {Array.from({ length: Math.ceil(filteredUsers.length / itemsPerPage) }, (_, index) => index + 1).map(page => (
                    <button
                        key={page}
                        className={page === currentPage ? styles.activePage : ""}
                        onClick={() => handlePageChange(page)}
                    >
                        {page}
                    </button>
                ))}
            </div>
            {/* 권한 설정 모달 */}
            {/* {selectedUser && (
                <div className={styles.permissionModal}>
                    <h3>{selectedUser.userName}의 권한 설정</h3>
                    {permissionsList.map(permissionKey => (
                        <div key={permissionKey}>
                            <label>{permissionKey}</label>
                            <button onClick={() => handlePermissionToggle(permissionKey)}>
                                {userPermissions[permissionKey] ? 'ON' : 'OFF'}
                            </button>
                        </div>
                    ))}
                    <button onClick={savePermissions}>저장</button>
                    <button onClick={() => setSelectedUser(null)}>취소</button>
                </div>
            )} */}
            {selectedUser &&
                <EditAuthPage user={selectedUser} onClose={offPermissionModal} />
            }
        </>
    );
}

export default MemberManagement;
