import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import styles from './UploadMemberExcel.module.css';
import { useTranslation } from 'react-i18next';


function UploadMemberExcel(props) {
    const { t } = useTranslation();
    const [fileData, setFileData] = useState([]);
    const [alertMsg, setAlertMsg] = useState({ message: '', type: 'danger' });

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const workbook = XLSX.read(bstr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '' });
            setFileData(data);
        };

        reader.readAsBinaryString(file);
    };

    const handleSubmit = async () => {
        try {
            const apiUrl = '/api/addMembersBulk';
            const res = await axios.post(apiUrl, { data: fileData });

            if (res.data !== '') {
                const alertType = res.data === 'Success' ? 'success' : 'danger';
                setAlertMsg({ message: res.data, type: alertType });
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleDownload = () => {
        const fileName = 'member_register_excel_file.xlsx';
        const link = document.createElement('a');
        link.href = process.env.PUBLIC_URL + '/files/' + fileName;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modal}>
                <div className={styles.header}>
                    <h3>멤버 일괄 추가</h3>
                    <button onClick={props.onClose} className={styles.closeButton}>✖</button>
                </div>

                <p>엑셀 파일을 이용하여 멤버를 일괄로 추가하세요.</p>

                {alertMsg.message && (
                    <Alert variant={alertMsg.type} onClose={() => setAlertMsg({ message: "", type: "danger" })} dismissible>
                        <pre>{alertMsg.message}</pre>
                    </Alert>
                )}

                <div className={styles.buttons}>
                    <button onClick={handleDownload} className={styles.downloadButton}>{t('엑셀 폼 다운로드')}</button>
                    <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} className={styles.fileInput} />
                    <button onClick={handleSubmit} className={styles.submitButton}>멤버 추가</button>
                </div>
            </div>
        </div>
    );
}

export default UploadMemberExcel;
