import React, { useEffect, useState } from "react";
import "./MobileCancerModels.css";
import downTriangle from "../../img/down-triangle.png";
import RightTab from "../right_tab/RightTab";
import vertical from "../../img/bar-vertical.png";
import horizontal from "../../img/bar-horizontal.png";
import PDO from "./PDO";
import PDXO from "./PDXO";


function MobileCancerModels(props) {
    const [visibleFirst, setVisibleFirst] = useState(true);
    const [visibleSecond, setVisibleSecond] = useState(true);
    const [models, setModels] = useState([{
    }])

    const [numPDOModels, setNum] = useState([{
        num: ''
    }]);
    const [numPDXOModels, setNumX] = useState([{
        num: ''
    }]);
    const [test, setTest] = useState([{
        test: ''
    }]);

    useEffect(() => {
        let reqOption = {
            method: "get",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/CountPDOModels", reqOption)
            .then((response) => response.json())
            .then(data => {
                setNum(data);
            });
    }, []);

    useEffect(() => {
        let reqOption = {
            method: "get",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/CountPDXOModels", reqOption)
            .then((response) => response.json())
            .then(data => {
                setNumX(data);
            });
    }, []);

    useEffect(() => {
        let reqOption = {
            method: "get",
            headers: {
                "content-type": "application/json"
            },

        }

        fetch("/api/getModels", reqOption)
            .then((response) => response.json())
            .then(data => {
                setModels(data);
            });
    }, []);
    function returnFalse(value){
        props.returnFalse(value);
    }

    return (
        <div className="mobileCancerModels">
            <div className="mobileTitle" onClick={(e) => {
                setVisibleFirst(!visibleFirst);
                // props.returnFalse(false);
            }}>
                <span>
                    Cancer Models
                </span>
                <span className="number">
                    ( {props.num.num} )
                </span>
            </div>
            <div className="mobileUnderLine"></div>
            {visibleFirst && <div>
                {
                    numPDOModels.map((num, index) => (
                        <PDO num={num} returnFalse={returnFalse}  key={index}></PDO>
                    ))
                }
                {
                    numPDXOModels.map((num, index) => (
                        <PDXO num={num} key={index}></PDXO>
                    ))
                }
            </div>}
        </div >
    );
}

export default MobileCancerModels;