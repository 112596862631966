import React from "react";
import imgFile from "../img/header-name.png";
import "./Header.css";

function Header() {
  return (
    <div>
      <div className="header">
        <img
          className="title-box"
          src={imgFile}
          height="77px"
          width="457"
          alt="title"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "http://www.banking.gradiantbio.com";
          }}
        />
      </div>
      <div className="title-underline"></div>
    </div>
  );
}

export default Header;
