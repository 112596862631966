// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageOne{
    margin-top: 20px;
    width: 200px;
    float: left;
    padding-left: 10px;
}

.imageSetting{
    text-align: center;
    width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/components/right_tab/Image.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".imageOne{\n    margin-top: 20px;\n    width: 200px;\n    float: left;\n    padding-left: 10px;\n}\n\n.imageSetting{\n    text-align: center;\n    width: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
