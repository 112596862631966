// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/Pretendard-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "Pretendard-Regular";
    font-style: normal;
    font-weight: 400;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}

.cancerName {
    margin-top: 3px;
    margin-left: 40px;
}

.customLink {
    color: black;
    text-decoration: none;
    font-family: "Pretendard-Regular";
    font-size: small;

}

.customLink:hover {
    color: rgb(150, 150, 212);
}

.customLink:active {
    color: rgb(12, 136, 12);
}

/* .customLink:visited {
    color: rgb(235, 134, 19);
} */

.customLink2 {
    color: orange;
    text-decoration: none;
    font-family: "Pretendard-Regular";
    font-size: small;
}

.customLink2:hover {
    color: rgb(150, 150, 212);
}

.customLink2:active {
    color: rgb(12, 136, 12);
}`, "",{"version":3,"sources":["webpack://./src/components/left_tab/CancerName.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,kBAAkB;IAClB,gBAAgB;IAChB,+DAAiE;AACrE;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,iCAAiC;IACjC,gBAAgB;;AAEpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;;GAEG;;AAEH;IACI,aAAa;IACb,qBAAqB;IACrB,iCAAiC;IACjC,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["@font-face {\n    font-family: \"Pretendard-Regular\";\n    font-style: normal;\n    font-weight: 400;\n    src: url(\"../../fonts/Pretendard-Regular.otf\") format(\"opentype\");\n}\n\n.cancerName {\n    margin-top: 3px;\n    margin-left: 40px;\n}\n\n.customLink {\n    color: black;\n    text-decoration: none;\n    font-family: \"Pretendard-Regular\";\n    font-size: small;\n\n}\n\n.customLink:hover {\n    color: rgb(150, 150, 212);\n}\n\n.customLink:active {\n    color: rgb(12, 136, 12);\n}\n\n/* .customLink:visited {\n    color: rgb(235, 134, 19);\n} */\n\n.customLink2 {\n    color: orange;\n    text-decoration: none;\n    font-family: \"Pretendard-Regular\";\n    font-size: small;\n}\n\n.customLink2:hover {\n    color: rgb(150, 150, 212);\n}\n\n.customLink2:active {\n    color: rgb(12, 136, 12);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
