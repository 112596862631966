import React, { useState, useEffect } from "react";
import styles from "./LevelManagement.module.css";
import EditAuthPage from "./EditAuthPage";
import { json } from "body-parser";
import { useTranslation } from 'react-i18next';
import EditLineNameModal from '../../leftTab/editLineNameModal';
import EditImage from '../../../img/common/editIcon.png';

function LevelManagement() {
    const [searchWord, setSearchWord] = useState("");
    const [authList, setAuthList] = useState([]);
    const [authEditPage, setAuthEditPage] = useState(null);
    const [reDraw, setReDraw] = useState(0); // 화면 새로 고침용 변수
    const [currentPage, setCurrentPage] = useState(1);  // 현재 페이지 번호
    const itemsPerPage = 10; // 페이지당 항목 수
    const { t } = useTranslation();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [newAuthListName, setNewAuthListName] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false); // 모달의 상태를 관리하는 상태 변수
    const [selectedName, setSelectedName] = useState("");

    //수정 버튼 함수
    const handleEditClicked = (name) => {
        setSelectedName(name);
    }

    useEffect(() => {
        if (selectedName) {
            setIsModalOpen(true);
        }
    }, [selectedName]);

    const handleModalSubmit = (newText) => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                oldText: selectedName,
                newText: newText,
            })
        };

        fetch("/api/changeAuthName", reqOption)
            .then((response) => response.json())
            .then(data => {
                alert(data.message);
                setReDraw(reDraw + 1);
                // setName(newText);
            })
            .catch(error => {
                console.error('An error occurred:', error);
            });
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedName("");
    }


    // Function to open the create modal
    const openCreateModal = () => {
        setShowCreateModal(true);
    };

    // Function to close the create modal
    const closeCreateModal = () => {
        setShowCreateModal(false);
    };

    const handleCreate = () => {
        const reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ authListName: newAuthListName })
        };
        fetch("/api/createAuthList", reqOption)
            .then((response) => response.json())
            .then(data => {
                if (data.success) {
                    closeCreateModal();
                    setReDraw(reDraw + 1);
                    setNewAuthListName(""); // Clear the input
                    alert(data.message);
                } else {
                    // alert("fail" + data.message);
                    alert(data?.message);
                }
            });
    };

    // 필터링된 권한 리스트 가져오기
    const getFilteredAuthList = () => {
        if (searchWord.trim() === "") return authList;
        return authList.filter(auth => auth.authName.includes(searchWord));
    }

    const totalPages = Math.ceil(getFilteredAuthList().length / itemsPerPage);

    // 현재 페이지의 권한 리스트를 가져옵니다.
    const getCurrentPageAuthList = () => {
        const start = (currentPage - 1) * itemsPerPage;
        return getFilteredAuthList().slice(start, start + itemsPerPage);
    }

    // 권한 모달 보여주기
    const showPermissionModal = (user) => {
        setAuthEditPage(user);
    };

    const offPermissionModal = () => {
        setReDraw(reDraw + 1);
        setAuthEditPage(null);
    }

    const inputChange = (event) => {
        setSearchWord(event.target.value);
    }

    const handleEdit = (authItem) => {
        setAuthEditPage(authItem);
        // 여기에서 EditAuthPage 컴포넌트를 띄워주세요.
        // 예: 모달 표시 등의 로직
    };

    const handleDuplicate = (authItem) => {
        if (window.confirm(authItem.authName + t("을 복제하시겠습니까?"))) {
            let reqOption = {
                method: "post",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(authItem)
            }

            fetch("/api/copyAuthList", reqOption)
                .then((response) => response.json())
                .then(data => {
                    setReDraw(reDraw + 1);
                });
        }
    };

    const handleDelete = (authItem) => {
        if (window.confirm(authItem.authName + "을 삭제하시겠습니까?")) {
            let reqOption = {
                method: "post",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(authItem)
            }

            fetch("/api/deleteAuthList", reqOption)
                .then((response) => response.json())
                .then(data => {
                    setReDraw(reDraw + 1);
                });
        }
    };


    //권한 리스트 가져오기
    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/getAuthList", reqOption)
            .then((response) => response.json())
            .then(data => {
                setAuthList(data);
            });
    }, [reDraw]);
    return (
        <div className={styles.테두리}>
            <div className={styles.권한}>
                권한
            </div>
            <div className={styles.권한메뉴바}>
                <div className={styles.검색및생성}>
                    <div className={styles.검색필드}>
                        <input type="text" value={searchWord} onChange={inputChange} placeholder="권한 이름으로 검색해주세요" className={styles.권한이름검색창} />
                    </div>
                    <button className={styles.생성버튼} onClick={openCreateModal}>생성</button>
                </div>
            </div>

            {showCreateModal && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <h2>새 권한 목록 생성</h2>
                        <input
                            type="text"
                            value={newAuthListName}
                            onChange={(e) => setNewAuthListName(e.target.value)}
                            placeholder="새 권한 목록 이름을 입력하세요"
                        />
                        <button onClick={handleCreate}>생성</button>
                        <button onClick={closeCreateModal}>취소</button>
                    </div>
                </div>
            )}

            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>정렬순서</th>
                        <th>권한</th>
                        <th>편집일</th>
                        <th>관리</th>
                    </tr>
                </thead>
                <tbody>
                    {getCurrentPageAuthList().map((authItem, index) => (
                        <tr key={authItem.id}>
                            <td>{index + 1}</td>
                            <td>
                                {authItem.authName}
                                &nbsp;
                                <img
                                    src={EditImage}
                                    className={styles.lineNameEditImg}
                                    onClick={() => handleEditClicked(authItem.authName)} // 이미지 클릭 시 모달을 열도록 설정
                                />
                            </td>
                            <td>{authItem.editDate.split("T")[0]}</td>
                            <td>
                                <button onClick={() => handleDuplicate(authItem)}>복제</button>
                                <button onClick={() => handleEdit(authItem)}>편집</button>
                                <button onClick={() => handleDelete(authItem)}>삭제</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <EditLineNameModal
                isOpen={isModalOpen}
                onClose={() => handleModalClose()} // 모달 닫기 함수 전달
                onSubmit={handleModalSubmit} // 텍스트를 받아 처리할 함수 전달
                lineName={selectedName}
            />
            <div className={styles.pagination}>
                {Array.from({ length: totalPages }).map((_, index) => (
                    <button key={index} onClick={() => setCurrentPage(index + 1)} className={index + 1 === currentPage ? styles.active : ''}>
                        {index + 1}
                    </button>
                ))}
            </div>

            {
                authEditPage &&
                (
                    <EditAuthPage user={authEditPage} onClose={offPermissionModal} mode="authList" />
                )
            }
        </div>
    );
}

export default LevelManagement;
