// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PatientInfoTab_outerBox__uPwan {
    margin-left: 40px;
}

.PatientInfoTab_basicInformation__mLw2U {
    padding-bottom: 15px;
    width: 90%;
    display: flex;
    align-items: center;
    border-bottom: solid 4px rgb(211, 211, 211);
}

.PatientInfoTab_title__EqdQs {
    font-weight: 600;
    color: #7B878C;
}

.PatientInfoTab_editButton__3Bzyk {
    padding-left: 15px;
    padding-right: 15px;
    border: solid 1px gray;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5%;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.3);
}

.PatientInfoTab_contents__yfjSO {
    margin-top: 20px;
    width: 90%;
    display: grid;
    grid-template-columns: 40% 1fr;
}

.PatientInfoTab_leftContent__PS9os {
    border-right: solid 4px rgb(211, 211, 211);
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: 600;
    color: #7B878C;
}

.PatientInfoTab_rightContent__RDpBY {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 20px;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/organoid/rightTab/PatientInfoTab.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;IACpB,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,2CAA2C;AAC/C;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,4CAA4C;AAChD;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,0CAA0C;IAC1C,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".outerBox {\n    margin-left: 40px;\n}\n\n.basicInformation {\n    padding-bottom: 15px;\n    width: 90%;\n    display: flex;\n    align-items: center;\n    border-bottom: solid 4px rgb(211, 211, 211);\n}\n\n.title {\n    font-weight: 600;\n    color: #7B878C;\n}\n\n.editButton {\n    padding-left: 15px;\n    padding-right: 15px;\n    border: solid 1px gray;\n    border-radius: 5px;\n    cursor: pointer;\n    margin-left: 5%;\n    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.3);\n}\n\n.contents {\n    margin-top: 20px;\n    width: 90%;\n    display: grid;\n    grid-template-columns: 40% 1fr;\n}\n\n.leftContent {\n    border-right: solid 4px rgb(211, 211, 211);\n    padding-top: 2px;\n    padding-bottom: 2px;\n    font-weight: 600;\n    color: #7B878C;\n}\n\n.rightContent {\n    margin-top: 2px;\n    margin-bottom: 2px;\n    margin-left: 20px;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerBox": `PatientInfoTab_outerBox__uPwan`,
	"basicInformation": `PatientInfoTab_basicInformation__mLw2U`,
	"title": `PatientInfoTab_title__EqdQs`,
	"editButton": `PatientInfoTab_editButton__3Bzyk`,
	"contents": `PatientInfoTab_contents__yfjSO`,
	"leftContent": `PatientInfoTab_leftContent__PS9os`,
	"rightContent": `PatientInfoTab_rightContent__RDpBY`
};
export default ___CSS_LOADER_EXPORT___;
