// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainImage {
    margin-top: 100px;
    min-height: 1010px;
}

.mainPicture {
    margin-left: 30px;
    width: 60vw;
}

.circlePercentage {
    width: 100px;
    height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/MainImage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".mainImage {\n    margin-top: 100px;\n    min-height: 1010px;\n}\n\n.mainPicture {\n    margin-left: 30px;\n    width: 60vw;\n}\n\n.circlePercentage {\n    width: 100px;\n    height: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
