import React, { useEffect, useState } from "react";
import styles from "./OrganizationChart.module.css";
import { Modal, Button } from 'react-bootstrap';

//이미지 임포트
import plusInCircle from "../../../img/common/plusInCircle.png";
import trashCase from "../../../img/common/trashCase.png";
import plusIcon from "../../../img/common/plusIcon.png";
import minusIcon from "../../../img/common/minusIcon.png";
import buildingIcon from "../../../img/common/buildingIcon.png";
import twoPeopleIcon from "../../../img/common/twoPeopleIcon.png";
import personBlack from "../../../img/common/personInCircle.png";

//컴포넌트
import UserName from "./UserName";

function OrganizationChart() {
    const [searchWord, setSearchWord] = useState("");
    const [userList, setUserList] = useState();
    const [teamList, setTeamList] = useState();
    const [isVisibleList, setIsVisibleList] = useState(true);
    // 선택된 팀의 이름을 저장하는 상태 추가
    const [selectedTeam, setSelectedTeam] = useState(null);
    // 전체 멤버를 보는 상태
    const [isVisibleAllMember, setIsVisibleAllMember] = useState(false);
    const [reDraw, setReDraw] = useState(0);

    const handleModalClose = () => {
        // 그리드를 다시 그리는 로직
        setReDraw(reDraw + 1);
    };

    //생성
    const [showAddModal, setShowAddModal] = useState(false);
    const [teamName, setTeamName] = useState('');

    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => {
        setReDraw(reDraw + 1);
        setShowAddModal(false);
    };

    const handleTeamSubmit = () => {
        teamList.map(team => {
            if (team.teamName === teamName) {
                alert("이미 존재하는 팀명 입니다.");
            }
        })
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                teamName: teamName
            }),
        }

        fetch("/api/addTeam", reqOption)
            .then((response) => response.json())
            .then(data => {
            });

        // 모달 닫기
        handleCloseAddModal();
    }

    //삭제
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState([]);

    const handleShowDeleteModal = () => setShowDeleteModal(true);
    const handleCloseDeleteModal = () => {
        setSelectedTeams([]);
        setShowDeleteModal(false);
        setReDraw(reDraw + 1);
        handleModalClose();
    };

    const handleTeamDelete = () => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                teams: selectedTeams
            }),
        }

        fetch("/api/deleteTeam", reqOption)
            .then((response) => response.json())
            .then(data => {
            });

        // 모달 닫기
        handleCloseDeleteModal();

        // 팀 리스트 새로고침
        // setReDraw(reDraw + 1);
    }


    //페이지네이션 변수
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 10; // 페이지당 사용자 수
    // const usersPerPage = 2; // 페이지당 사용자 수

    // 현재 페이지의 사용자만 가져오는 함수
    const currentUsers = () => {
        if (isVisibleAllMember) {
            const indexOfLastUser = currentPage * usersPerPage;
            const indexOfFirstUser = indexOfLastUser - usersPerPage;
            return userList?.slice(indexOfFirstUser, indexOfLastUser);
        } else if (selectedTeam) {
            const filteredUsers = userList?.filter(user => user.userTeamName === selectedTeam);
            const indexOfLastUser = currentPage * usersPerPage;
            const indexOfFirstUser = indexOfLastUser - usersPerPage;
            return filteredUsers?.slice(indexOfFirstUser, indexOfLastUser);
        }
        return [];
    };

    // 페이지 번호 리스트 생성
    const pageNumbers = [];
    if (isVisibleAllMember) {
        for (let i = 1; i <= Math.ceil(userList?.length / usersPerPage); i++) {
            pageNumbers.push(i);
        }
    } else if (selectedTeam) {
        const teamUsersCount = userList?.filter(user => user.userTeamName === selectedTeam).length || 0;
        for (let i = 1; i <= Math.ceil(teamUsersCount / usersPerPage); i++) {
            pageNumbers.push(i);
        }
    }

    // 페이지 변경 핸들러
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleVisibleAllMember = () => {
        setIsVisibleAllMember(true);
        setSelectedTeam("전체");
    }

    // 팀 이름 클릭 핸들러
    const handleTeamClick = (teamName) => {
        setSelectedTeam(teamName);
        setIsVisibleAllMember(false);
        setCurrentPage(1);
    };

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/getUserList", reqOption)
            .then((response) => response.json())
            .then(data => {
                setUserList(data);
            });
    }, [reDraw]);

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/getTeamList", reqOption)
            .then((response) => response.json())
            .then(data => {
                setTeamList(data);
            });
    }, [reDraw]);

    const inputChange = (event) => {
        setSearchWord(event.target.value);
    }

    const GBCCClicked = () => {
        setIsVisibleList(!isVisibleList);
    }
    return (
        <>
            <div className={styles.조직도관리}>
                조직도 관리
            </div>
            <div className={styles.조직도좌우그리드}>
                <div className={styles.조직도왼쪽그리드}>
                    <div className={styles.왼쪽메뉴바}>
                        <input type="text" className={styles.organiztionInputBox} value={searchWord} onChange={inputChange} placeholder="그룹명으로 검색"></input>
                        <button className={styles.createDeleteButton} onClick={handleShowAddModal}>
                            <img src={plusInCircle} className={styles.plusInCircle}></img>
                            생성
                        </button>
                        <Modal show={showAddModal} onHide={handleCloseAddModal} className={styles.modalClass}>
                            <Modal.Header closeButton>
                                <Modal.Title>팀 추가</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <input type="text" value={teamName} onChange={e => setTeamName(e.target.value)} placeholder="팀 이름" />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseAddModal}>취소</Button>
                                <Button variant="primary" onClick={handleTeamSubmit}>추가</Button>
                            </Modal.Footer>
                        </Modal>
                        <button className={styles.createDeleteButton} onClick={handleShowDeleteModal}>
                            <img src={trashCase} className={styles.plusInCircle}></img>
                            삭제
                        </button>

                        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} className={styles.modalClass}>
                            <Modal.Header closeButton>
                                <Modal.Title>팀 삭제</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {teamList?.filter(team => team.teamName !== "미지정").map(team => (
                                    <div key={team.teamName}>
                                        <input type="checkbox" value={team.teamName} onChange={e => {
                                            if (e.target.checked) {
                                                setSelectedTeams([...selectedTeams, e.target.value]);
                                            } else {
                                                setSelectedTeams(selectedTeams.filter(name => name !== e.target.value));
                                            }
                                        }} />
                                        {team.teamName}
                                    </div>
                                ))}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseDeleteModal}>취소</Button>
                                <Button variant="danger" onClick={handleTeamDelete}>삭제</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div>
                        {
                            isVisibleList ? (
                                <>
                                    <div className={styles.verticalCenter} onClick={GBCCClicked}>
                                        <div className={styles.minusBox}>
                                            <img src={minusIcon} className={styles.minusIcon}></img>
                                        </div>
                                        <div>
                                            <img src={buildingIcon} className={styles.buildingIcon}></img>
                                            <span className={styles.orangeGBCC}>
                                                GBCC
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        teamList?.filter(team => searchWord === "" || team.teamName.includes(searchWord))
                                            .map((team, index) => (
                                                <div key={index} onClick={() => handleTeamClick(team.teamName)} className={styles.teamName}>
                                                    <img src={twoPeopleIcon} className={styles.twoPeopleIcon}></img>
                                                    {team.teamName}({userList?.filter(user => user.userTeamName === team.teamName).length})
                                                </div>
                                            ))
                                    }
                                </>
                            ) : (
                                <div className={styles.verticalCenter} onClick={GBCCClicked}>
                                    <div className={styles.minusBox}>
                                        <img src={plusIcon} className={styles.plusIcon}></img>
                                    </div>
                                    <div>
                                        <img src={buildingIcon} className={styles.buildingIcon}></img>
                                        <span className={styles.orangeGBCC}>
                                            GBCC
                                        </span>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
                <div className={styles.조직도오른쪽그리드}>
                    <div className={styles.하단공백}>
                        <div className={styles.기관멤버}>
                            기관 멤버 ({selectedTeam})
                        </div>
                        <div className={styles.전체멤버보기}>
                            <button className={styles.버튼전체멤버보기} onClick={handleVisibleAllMember}>
                                <img src={personBlack} className={styles.personBlack}></img>
                                <span className={styles.전체멤버보기글씨}>전체멤버보기</span>
                            </button>
                        </div>
                    </div>
                    {
                        isVisibleAllMember ? (
                            // 선택된 팀의 사용자 목록 렌더링
                            currentUsers()?.map((user, index) => (
                                <UserName key={index} name={user.userName} email={user.email} userInfo={user} closeModal={handleModalClose}></UserName>
                            ))

                        ) : selectedTeam && userList?.filter(user => user.userTeamName === selectedTeam).length > 0 ? (
                            // 선택된 팀의 사용자 목록 렌더링
                            currentUsers()?.filter(user => user.userTeamName === selectedTeam)
                                .map((user, index) => (
                                    <UserName key={index} name={user.userName} email={user.email} userInfo={user} closeModal={handleModalClose}></UserName>
                                ))
                        ) : (
                            <>
                                멤버 없음
                            </>
                        )
                    }
                    {/* 페이징 컨트롤 */}
                    <div className={styles.pagination}>
                        {pageNumbers.map(number => (
                            <button key={number} onClick={() => handlePageClick(number)} style={currentPage === number ? { backgroundColor: '#007bff', color: '#ffffff', borderColor: '#007bff' } : {}}>
                                {number}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrganizationChart;
