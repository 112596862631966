import "./PatientInfo.css";
import React, { useState } from 'react';
import { useEffect, Component, useLayoutEffect } from 'react';
import { Link, json, useParams, } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function PatientInfo(props) {
    function hashMark(str) {
        return (String(str).replace('hashmark', '#'));
    }
    const [commonGender, setCommonGender] = useState(true);
    const [commonAge, setCommonAge] = useState(true);
    const [commonStage, setCommonStage] = useState(true);
    const [commonHistologicType, setCommonHistologicType] = useState(true);
    const [commonSite, setCommonSite] = useState(true);
    const [commonSpecialFeature, setCommonSpecialFeature] = useState(true);
    const [commonTreatmentHistory, setCommonTreatmentHistory] = useState(true);


    const [patientInfo, setPatientInfo] = useState([{}]);

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                param: props.param,
            })
        }

        fetch("/api/getPatientInfo", reqOption)
            .then((response) => response.json())
            .then(data => {
                setPatientInfo(data);
            });
    }, [props.param]);

    for (let i = 0; i < patientInfo?.length - 1; i++) {
        if (commonGender && patientInfo[i]?.Gender !== patientInfo[i + 1]?.Gender) {
            setCommonGender(false);
        }
        if (commonAge && patientInfo[i]?.Age !== patientInfo[i + 1]?.Age) {
            setCommonAge(false);
        }
        if (commonStage && patientInfo[i]?.Stage !== patientInfo[i + 1]?.Stage) {
            setCommonStage(false);
        }
        if (commonHistologicType && patientInfo[i]?.HistologicType !== patientInfo[i + 1]?.HistologicType) {
            setCommonHistologicType(false);
        }
        if (commonSite && patientInfo[i]?.Site !== patientInfo[i + 1]?.Site) {
            setCommonSite(false);
        }
        if (commonSpecialFeature && patientInfo[i]?.SpecialFeature !== patientInfo[i + 1]?.SpecialFeature) {
            setCommonSpecialFeature(false);
        }
        if (commonTreatmentHistory && patientInfo[i]?.TreatmentHistory !== patientInfo[i + 1]?.TreatmentHistory) {
            setCommonTreatmentHistory(false);
        }
    }

    // const arr = patientInfo[0]?.TreatmentHistory.split('\n');

    return (
        <div>
            <div className="relOrganoid">공통 환자 정보</div>
            <div className="commonPatientInfo">
                {
                    commonGender &&
                    <div className="patientBasicInfo">
                        <div className="basicInfoTitle">Gender</div>
                        <div className="basicInfoContent">{patientInfo[0]?.Gender}</div>
                    </div>
                }
                {
                    commonAge &&
                    <div className="patientBasicInfo">
                        <div className="basicInfoTitle">Age</div>
                        <div className="basicInfoContent">{patientInfo[0]?.Age}</div>
                    </div>
                }
                {
                    commonStage &&
                    <div className="patientBasicInfo">
                        <div className="basicInfoTitle">Stage</div>
                        <div className="basicInfoContent">{patientInfo[0]?.Stage}</div>
                    </div>
                }
                {
                    commonHistologicType &&
                    <div className="patientBasicInfo">
                        <div className="basicInfoTitle">Histologic type</div>
                        <div className="basicInfoContent">{patientInfo[0]?.HistologicType}</div>
                    </div>
                }
                {
                    commonSite &&
                    <div className="patientBasicInfo">
                        <div className="basicInfoTitle">Site</div>
                        <div className="basicInfoContent">{patientInfo[0]?.Site}</div>
                    </div>
                }
                {
                    commonSpecialFeature &&
                    <div className="patientBasicInfo">
                        <div className="basicInfoTitle">Special feature</div>
                        <div className="basicInfoContent">{patientInfo[0]?.SpecialFeature}</div>
                    </div>
                }
                {
                    commonTreatmentHistory &&
                    <div className="patientBasicInfo">
                        <div className="basicInfoTitle">Treatment history</div>
                        <div className="basicInfoContent">{
                            patientInfo[0]?.TreatmentHistory?.split('\n')?.map((str) => (
                                <div key={str}>{str}</div>
                            ))
                        }</div>
                    </div>
                }
            </div>
            <div className="relOrganoid">관련 오가노이드</div>
            {
                patientInfo?.map((map, index) => (
                    <div className="relOrganoid"  key={index}>
                        <Link to={"/organoid/" + map[`Key`]} className="relOrganoidName">{hashMark(map[`Key`])}</Link>
                        <div className="commonPatientInfo">
                            {
                                !commonGender &&
                                <div className="patientBasicInfo">
                                    <div className="basicInfoTitle">Gender</div>
                                    <div className="basicInfoContent">{map[`Gender`]}</div>
                                </div>
                            }
                            {
                                !commonAge &&
                                <div className="patientBasicInfo">
                                    <div className="basicInfoTitle">Age</div>
                                    <div className="basicInfoContent">{map[`Age`]}</div>
                                </div>
                            }
                            {
                                !commonStage &&
                                <div className="patientBasicInfo">
                                    <div className="basicInfoTitle">Stage</div>
                                    <div className="basicInfoContent">{map[`Stage`]}</div>
                                </div>
                            }
                            {
                                !commonHistologicType &&
                                <div className="patientBasicInfo">
                                    <div className="basicInfoTitle">Histologic type</div>
                                    <div className="basicInfoContent">{map[`HistologicType`]}</div>
                                </div>
                            }
                            {
                                !commonSite &&
                                <div className="patientBasicInfo">
                                    <div className="basicInfoTitle">Site</div>
                                    <div className="basicInfoContent">{map[`Site`]}</div>
                                </div>
                            }
                            {
                                !commonSpecialFeature &&
                                <div className="patientBasicInfo">
                                    <div className="basicInfoTitle">Special feature</div>
                                    <div className="basicInfoContent">{map[`SpecialFeature`]}</div>
                                </div>
                            }
                            {
                                !commonTreatmentHistory &&
                                <div className="patientBasicInfo">
                                    <div className="basicInfoTitle">Treatment history</div>
                                    <div className="basicInfoContent">{
                                        map[`TreatmentHistory`]?.split('\n')?.map((str) => (
                                            <div>{str}</div>
                                        ))
                                    }</div>
                                </div>
                            }
                        </div>
                    </div>
                ))
            }
        </div >

    );
}

export default PatientInfo;