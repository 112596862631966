// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BasicInfo_basicInfo__FQSuZ {
    display: grid;
    grid-template-columns: 1fr 3fr;
    margin-left: 40px;
    padding-left: 2vw;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 60vw;
    /* margin-right: 10vw; */
    background-color: snow;
    margin-bottom: 20px;
}

.BasicInfo_basicInfoTitle__T7iVx {
    font-weight: bold;
    font-size: medium;
}

.BasicInfo_basicInfoContent__sS9P- {
    font-size: medium;
    margin-bottom: 5px;
}

.BasicInfo_imageAndGraph__68t2k {
    margin-left: 40px;
}

.BasicInfo_오가노이드수정버튼__YPWtB {
    width: -moz-fit-content;
    width: fit-content;
}

.BasicInfo_오가노이드버튼모음__Q3w42 {
    margin-left: 40px;
}

.BasicInfo_rightTabBox__qdjjj {
    padding: 15px;
}

.BasicInfo_twoPartBox__GaSce {
    display: grid;
    grid-template-columns: 50% 50%;
}

.BasicInfo_title__kmKz2 {
    margin-right: 40px;
    width: 95%;
    margin-left: 40px;
    margin-top: 50px;
    padding-bottom: 15px;
    font-weight: 600;
    color: #7B878C;
    border-bottom: solid 4px rgb(211, 211, 211);
}

.BasicInfo_titleOutBox__oRPoa {
    margin-right: 40px;
    padding: 0;
}

.BasicInfo_graphBox__o-72z {
    margin-left: 40px;
    margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/organoid/rightTab/BasicInfo.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;IACjB,oBAAoB;IACpB,WAAW;IACX,wBAAwB;IACxB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,iBAAiB;IACjB,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;IACd,2CAA2C;AAC/C;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".basicInfo {\n    display: grid;\n    grid-template-columns: 1fr 3fr;\n    margin-left: 40px;\n    padding-left: 2vw;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    width: 60vw;\n    /* margin-right: 10vw; */\n    background-color: snow;\n    margin-bottom: 20px;\n}\n\n.basicInfoTitle {\n    font-weight: bold;\n    font-size: medium;\n}\n\n.basicInfoContent {\n    font-size: medium;\n    margin-bottom: 5px;\n}\n\n.imageAndGraph {\n    margin-left: 40px;\n}\n\n.오가노이드수정버튼 {\n    width: fit-content;\n}\n\n.오가노이드버튼모음 {\n    margin-left: 40px;\n}\n\n.rightTabBox {\n    padding: 15px;\n}\n\n.twoPartBox {\n    display: grid;\n    grid-template-columns: 50% 50%;\n}\n\n.title {\n    margin-right: 40px;\n    width: 95%;\n    margin-left: 40px;\n    margin-top: 50px;\n    padding-bottom: 15px;\n    font-weight: 600;\n    color: #7B878C;\n    border-bottom: solid 4px rgb(211, 211, 211);\n}\n\n.titleOutBox {\n    margin-right: 40px;\n    padding: 0;\n}\n\n.graphBox {\n    margin-left: 40px;\n    margin-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"basicInfo": `BasicInfo_basicInfo__FQSuZ`,
	"basicInfoTitle": `BasicInfo_basicInfoTitle__T7iVx`,
	"basicInfoContent": `BasicInfo_basicInfoContent__sS9P-`,
	"imageAndGraph": `BasicInfo_imageAndGraph__68t2k`,
	"오가노이드수정버튼": `BasicInfo_오가노이드수정버튼__YPWtB`,
	"오가노이드버튼모음": `BasicInfo_오가노이드버튼모음__Q3w42`,
	"rightTabBox": `BasicInfo_rightTabBox__qdjjj`,
	"twoPartBox": `BasicInfo_twoPartBox__GaSce`,
	"title": `BasicInfo_title__kmKz2`,
	"titleOutBox": `BasicInfo_titleOutBox__oRPoa`,
	"graphBox": `BasicInfo_graphBox__o-72z`
};
export default ___CSS_LOADER_EXPORT___;
