import React, { useEffect, useState } from "react";
import "./SummaryData.css";
import { useParams } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PatientContent from "./PatientContent";


function SummaryData(props) {
    const [visibleFirst, setVisibleFirst] = useState(false);
    const [cancerInfo, setInfo] = useState([]);

    useEffect(() => {
        // const ProductOption = props.detail.option

        // if(ProductOption != 'undefined' && ProductOption != null) {
        //    setOptions(ProductOption)
        // }


        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                param: props.cancerKey
            })

        }

        fetch('/api/getPatientInfo', reqOption)
            .then((response) => response.json())
            .then(data => {
                setInfo(data);
            });
    }, []);

    // const level = 1;//추후 세션에서 가져오기
    // var permission = true;
    // if (props.level != null) {
    //     if (props.level['cancer_level'] != null) {
    //         if (props.level['cancer_level'] < level) {
    //             permission = false;
    //         } else {
    //             permission = true;
    //         }
    //     }
    // }
    // if (!permission) {
    //     return (
    //         <div className="summaryNotEx">
    //             Not Authorized
    //         </div>
    //     )
    // } else 
    if (cancerInfo['length'] == 0) {
        return (
            <div className="summaryNotEx">
                {/* Not authorized */}
                Not Available
            </div>
        )
    } else {

        return (
            <div className="summaryDataMain">
                <div>
                    {
                        cancerInfo.map((data, index) => (
                            <PatientContent data={data} key={index}></PatientContent>
                        ))
                    }
                </div>


            </div>
        );
    }
}

export default SummaryData;