// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileSummaryDataMain {
    padding-top: 30px;
    height: auto;
}

.mobileSummaryNotEx {
    margin-left: 20px;
    padding-top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/right_tab/MobileSummaryData.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".mobileSummaryDataMain {\n    padding-top: 30px;\n    height: auto;\n}\n\n.mobileSummaryNotEx {\n    margin-left: 20px;\n    padding-top: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
