import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./MobileContent.css";


function MobileContent(props) {
    var str = "asdf";
    function hashing(str) {
        str = str.replace('hashmark', '#');
        if (!str) {
            str = "NA";
        }
        return str;
    }

    function isExist(num) {
        if (num == '1') {
            return "Yes";
        } else if (num == '2') {
            return "No";
        } else {
            return "NA";
        }
    }

    return (
        <div>
            {/* <div>
                <span className="mobileContent1">Lab code</span>
                <span className="mobileContentT11">{hashing(props.data['Lab code'])}</span>
            </div>
            <div>
                <span className="mobileContent2">GBCC code</span>
                <span className="mobileContentT11">{hashing(props.data['GBCC code'])}</span>
            </div> */}
            <div>
                <span className="mobileContent3">Code</span>
                <span className="mobileContentT11">{hashing(props.data['Key'])}</span>
            </div>
            <div>
                <span className="mobileContent4">Sample type</span>
                <span className="mobileContentT11">{hashing(props.data['Sample type'])}</span>
            </div>
            <div>
                <span className="mobileContent5">Organism</span>
                <span className="mobileContent51">{hashing(props.data['Organism'])}</span>
            </div>
            <div>
                <span className="mobileContent6">Cancer type</span>
                <span className="mobileContentT11">{hashing(props.data['Cancer Type'])}</span>
            </div>
            <div>
                <span className="mobileContent7">Subtype</span>
                <span className="mobileContentT11">{hashing(props.data['Subtype'])}</span>
            </div>
            <div>
                <span className="mobileContent8">Orgin</span>
                <span className="mobileContentT11">{hashing(props.data['Orgin'])}</span>
            </div>
            <div>
                <span className="mobileContentP6">Normal pair</span>
                <span className="mobileContentT11">{isExist(props.data['Normal pair'])}</span>
            </div>
            <div>
                <span className="mobileContentP7">WES</span>
                <span className="mobileContentT11">{isExist(props.data['WES'])}</span>
            </div>
            <div>
                <span className="mobileContentP8">RNA seq.</span>
                <span className="mobileContentT11">{isExist(props.data['RNA seq.'])}</span>
            </div>
            <div>
                <span className="mobileContentP4">Mutation information</span>
                <span className="mobileContentPP51">{hashing(props.data['Mutation information'])}</span>
            </div>


        </div>
    );
}

export default MobileContent;