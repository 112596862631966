import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import styles from './AddNewOrganoid.module.css';

function AddNewOrganoid(props) {
    const sharpToHash = (str) => {
        if (str) {
            return (str.replace('#', 'hashmark'));
        }
        else {
            return str;
        }
    }
    const [formData, setFormData] = useState({
        code: '',
        labCode: '',
        sampleType: '',
        organism: '',
        cancerType: '',
        subtype: '',
        orgin: '',
        pair: '',
        mutationInformation: '',
        surgeryDate: '',
        establishmentNo: '',
        ngsStatusWES: '',
        ngsStatusRNASeq: '',
        notes: ''
    });
    const [result, setResult] = useState();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        console.log(formData);
        e.preventDefault();
    };

    const handleSubmitClick = () => {
        if (formData.code === '' || !formData.code) {
            alert(t('Code 값은 필수 입력입니다.'));
        } else {
            formData.code = sharpToHash(formData.code);
            let reqOption = {
                method: "post",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    param: formData
                })
            };
            fetch('/api/draftAddOrganoidInfo', reqOption)
                .then((response) => response.json())
                .then(data => {
                    if (data.message) {
                        alert(t(data.message));
                    }
                    props.onClose();
                })
                .catch(error => console.error('Error:', error)); // 에러 처리 추가
        }
    };
    // const handleSubmitClick = () => {
    //     console.log(formData);
    //     let reqOption = {
    //         method: "post",
    //         headers: {
    //             "content-type": "application/json"
    //         },
    //         body: JSON.stringify({
    //             param: formData
    //         })
    //     }
    //     fetch('/api/draftAddOrganoidInfo', reqOption)
    //         .then((response) => response.json())
    //         .then(data => {
    //             setResult(data);
    //             // setData(data);
    //         });
    //     console.log('data');
    //     console.log(result);
    //     props.onClose();
    // }

    // useEffect(() => {
    //     if (result) {
    //         console.log('data');
    //         console.log(result);
    //     }
    // }, [result]);

    return (
        <div className={styles.modalBackground} onClick={(e) => e.target === e.currentTarget && props.onClose()}>
            <div className={styles.modalBackground} onClick={(e) => e.target === e.currentTarget && props.onClose()}>
                <div className={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
                    <button type="button" className={styles.closeButton} onClick={props.onClose}>&times;</button>
                    <div className={styles.title}>{t('Add New Organoid Info')}</div>
                    <div className={styles.submitGroup}>
                        <div className={styles.formLabel}>Code</div>
                        <input type="text" name="code" value={formData.code} onChange={handleChange} className={styles.rightValue} />
                        <div className={styles.formLabel}>Lab Code</div>
                        <input type="text" name="labCode" value={formData.labCode} onChange={handleChange} className={styles.rightValue} />
                        <div className={styles.formLabel}>Sample Type</div>
                        <input type="text" name="sampleType" value={formData.sampleType} onChange={handleChange} className={styles.rightValue} />
                        <div className={styles.formLabel}>Organism</div>
                        <input type="text" name="organism" value={formData.organism} onChange={handleChange} className={styles.rightValue} />
                        <div className={styles.formLabel}>Cancer Type</div>
                        <input type="text" name="cancerType" value={formData.cancerType} onChange={handleChange} className={styles.rightValue} />
                        <div className={styles.formLabel}>Subtype</div>
                        <input type="text" name="subtype" value={formData.subtype} onChange={handleChange} className={styles.rightValue} />
                        <div className={styles.formLabel}>Orgin</div>
                        <input type="text" name="orgin" value={formData.orgin} onChange={handleChange} className={styles.rightValue} />
                        <div className={styles.formLabel}>Pair</div>
                        <select name="pair" value={formData.pair} onChange={handleChange} className={styles.rightValue}>
                            <option value="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                        <div className={styles.formLabel}>Mutation Information</div>
                        <textarea name="mutationInformation" value={formData.mutationInformation} onChange={handleChange} className={styles.rightValue}></textarea>
                        <div className={styles.formLabel}>Surgery Date</div>
                        <input type="date" name="surgeryDate" value={formData.surgeryDate} onChange={handleChange} className={styles.rightValue} />
                        <div className={styles.formLabel}>Establishment No.</div>
                        <input type="text" name="establishmentNo" value={formData.establishmentNo} onChange={handleChange} className={styles.rightValue} />
                        <div className={styles.formLabel}>NGS Status WES</div>
                        <select name="ngsStatusWES" value={formData.ngsStatusWES} onChange={handleChange} className={styles.rightValue}>
                            <option value="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                        <div className={styles.formLabel}>NGS Status RNA Seq</div>
                        <select name="ngsStatusRNASeq" value={formData.ngsStatusRNASeq} onChange={handleChange} className={styles.rightValue}>
                            <option value="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                        {/* <div className={styles.formLabel}>Notes</div> */}
                    </div>
                    <textarea name="notes" value={formData.notes} onChange={handleChange} placeholder={t('Draft your opinion here.')} className={styles.draftOpinion}></textarea>
                    <div className={styles.submitDiv}>
                        <button type="button" className={styles.submitButton} onClick={handleSubmitClick}>{t('Submit')}</button>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AddNewOrganoid;
