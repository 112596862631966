import React, { useState, useEffect } from 'react';
import styles from './DraftBankingDetailInfoComponent.module.css';

function DraftBankingDetailInfoComponent(props) {
    const [bankingData, setBankingData] = useState();

    useEffect(() => {
        let reqOption = {
            method: 'post',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                param: props.Key
            }),
        };

        fetch("/api/getBankingInfo", reqOption)
            .then((response) => response.json())
            .then(data => {
                if (data && data.length > 0) {
                    setBankingData(data[0]);
                }
            });
    }, []);

    return (
        <>
            {bankingData && (
                <div className={styles.main}>
                    <div className={styles.draftBankingTitle}>Banking information</div>
                    <div className={styles.bankingInfoInDraft}>
                        <div className={styles.leftTitle}>Patient Code</div>
                        <div className={styles.rightContent}>{bankingData.PatientCode}</div>

                        <div className={styles.leftTitle}>Master</div>
                        <div className={styles.rightContent}>
                            {bankingData?.Master === 1 ? (
                                <input type="radio" checked readOnly disabled />
                            ) : (
                                <input type="radio" readOnly disabled />
                            )}
                        </div>

                        <div className={styles.leftTitle}>LabCode</div>
                        <div className={styles.rightContent}>{bankingData.LabCode}</div>

                        <div className={styles.leftTitle}>Vessel</div>
                        <div className={styles.rightContent}>{bankingData.Vessel}</div>

                        <div className={styles.leftTitle}>Line</div>
                        <div className={styles.rightContent}>{bankingData.Line}</div>

                        <div className={styles.leftTitle}>Box</div>
                        <div className={styles.rightContent}>{bankingData.Box}</div>

                        <div className={styles.leftTitle}>Position</div>
                        <div className={styles.rightContent}>{bankingData.Position}</div>

                        <div className={styles.leftTitle}>Passage No.</div>
                        <div className={styles.rightContent}>{bankingData.PassageNo}</div>

                        <div className={styles.leftTitle}>Keeper</div>
                        <div className={styles.rightContent}>{bankingData.Keeper}</div>

                        <div className={styles.leftTitle}>Freezing Date</div>
                        <div className={styles.rightContent}>{bankingData.FreezingDate}</div>

                        <div className={styles.leftTitle}>Dome/Vial or Cell number</div>
                        <div className={styles.rightContent}>{bankingData.domePerVialOrCellNumber}</div>

                        <div className={styles.leftTitle}>Culture Media</div>
                        <div className={styles.rightContent}>{bankingData.CultureMedia}</div>

                        <div className={styles.leftTitle}>Mycoplasma</div>
                        <div className={styles.rightContent}>{bankingData.Mycoplasma}</div>

                        <div className={styles.leftTitle}>Thawing No.</div>
                        <div className={styles.rightContent}>{bankingData.thawingNo}</div>

                        <div className={styles.leftTitle}>Note</div>
                        <div className={styles.rightContent}>
                            {bankingData.annotation?.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>

                        <div className={styles.leftTitle}>Exporter</div>
                        <div className={styles.rightContent}>{bankingData.Exporter}</div>

                        <div className={styles.leftTitle}>Export Date</div>
                        <div className={styles.rightContent}>{bankingData.ExporteDate}</div>
                    </div>
                </div>
            )}
        </>
    );
}

export default DraftBankingDetailInfoComponent;
