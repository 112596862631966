import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from './EditBoxMemo.module.css';
import { useTranslation } from 'react-i18next';

//image import

function EditBoxMemo(props) {
    const { t } = useTranslation();
    const [memo, setMemo] = useState(''); // 메모 입력 상태 관리

    const handleSave = () => {
        props.onSubmit(memo); // 상위 컴포넌트로 메모 데이터 전달
        props.onClose(); // 모달 닫기
    };

    return (
        <div className={styles.modalBackground} onClick={(e) => e.target === e.currentTarget && props.onClose()}>
            <div className={styles.modalBackground} onClick={(e) => e.target === e.currentTarget && props.onClose()}>
                <div className={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.editMemoTitle}>
                        Edit memo
                    </div>
                    <textarea
                        placeholder={t('Please write a new memo')}
                        className={styles.editMemoTextArea}
                        value={memo}
                        onChange={(e) => setMemo(e.target.value)}
                    />
                    <div className={styles.editMemoSaveButton}>
                        <div className={styles.saveButton} onClick={handleSave}>
                            {t('Save')}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default EditBoxMemo;