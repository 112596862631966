import numeric from 'numeric'; 

// 별도의 함수로 로직 분리
export const performOptimization = (xdata, ydata) => {
  // 모델 함수 정의
  function model(x, ic50, hillSlope) {
    return 100 / (1 + Math.pow(10, (ic50 - x) * hillSlope));
  }

  // 목적 함수 정의
  function objectiveFunction(parameters) {
    return xdata.reduce((sum, x, i) => {
      const y = ydata[i];
      const modelY = model(x, parameters[0], parameters[1]);
      return sum + Math.pow(y - modelY, 2);
    }, 0);
  }

  // 초기 추정값
  const initialGuess = [1, -1];

  // 최적화 수행
  return {
    solution: numeric.uncmin(objectiveFunction, initialGuess).solution,
    model
  };
};