// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageBox {
    display: flex;
    width: 60vw;
    height: 250px;
    overflow: auto;
    margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/right_tab/ImageLightMicroscope.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".imageBox {\n    display: flex;\n    width: 60vw;\n    height: 250px;\n    overflow: auto;\n    margin-bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
