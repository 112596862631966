// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from './locals/en/translation.json';
import translationKo from './locals/ko/translation.json';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: translationEn,
        },
        ko: {
            translation: translationKo,
        }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
