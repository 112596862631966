// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.currentPatient{
    font-weight: bold;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/organoid/rightTab/BankingRightTab_patient.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":[".currentPatient{\n    font-weight: bold;\n    padding-left: 20px;\n    padding-top: 10px;\n    padding-bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
