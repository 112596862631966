import React, { useState } from 'react';
import { useEffect, Component, useLayoutEffect } from 'react';
import { useParams, } from 'react-router-dom';


function BankingRigthTab() {

    const { tmpKey } = useParams();

    return (
        <div className="">
            잘못된 주소 접근입니다.
        </div>

    );
}

export default BankingRigthTab;