import "./App.css";
import Header from "./components/Header";
import RightTab1 from "./components/right_tab/RightTab";
import RightTab2 from "./components/right_tab/RightTab copy";
import RightTab3 from "./components/right_tab/RightTab copy 2";
import RightTab4 from "./components/right_tab/RightTab copy 3";
import RightTab5 from "./components/right_tab/RightTab copy 4";
import RightTab6 from "./components/right_tab/RightTab copy 5";
import RightTab7 from "./components/right_tab/RightTab copy 6";
import React, { useEffect, useState, Component, useLayoutEffect } from 'react'
import CancerModels from "./components/left_tab/CancerModels";
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navi from './components/Navi';
import MainImage from './MainImage';
import { useMediaQuery } from 'react-responsive'
import MobileHeader from "./components/mobileComponents/MobileHeader";
import MobileFooter from "./components/mobileComponents/MobileFooter";
import MobileCancerModels from "./components/left_tab/MobileCancerModels";
import MobileMainImage from "./MobileMainImage";
import MobileRightTab1 from "./components/right_tab/MobileRightTab1";
import MobileRightTab2 from "./components/right_tab/MobileRightTab2";
import MobileRightTab3 from "./components/right_tab/MobileRightTab3";
import MobileRightTab4 from "./components/right_tab/MobileRightTab4";
import MobileRightTab5 from "./components/right_tab/MobileRightTab5";
import MobileRightTab6 from "./components/right_tab/MobileRightTab6";
import MobileRightTab7 from "./components/right_tab/MobileRightTab7";


function App(props) {

  const isPc = useMediaQuery({
    query: "(min-width:768px)"
  });
  // const isTablet = useMediaQuery({
  //   query: "(min-width:768px) and (max-width:1023px)"
  // });
  const isMobile = useMediaQuery({
    query: "(max-width:767px)"
  });

  const [numCancerModels, setNum] = useState([{
    num: ''
  }]);
  const [visibleMenu, setVisibleMenu] = useState(false);
  useEffect(() => {
    let reqOption = {
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    }

    fetch("/api/CountCancerModels", reqOption)
      .then((response) => response.json())
      .then(data => {
        setNum(data);
      });
  }, []);

  function returnFalse(value) {
    setVisibleMenu(value);
  }

  return (
    <div className="mainApp">
      <BrowserRouter>
        <div className="wrapper">
          <Header></Header>
          <div className="left-tab">
            {
              numCancerModels.map((num, index) => (
                <CancerModels num={num} key={index}></CancerModels>
              ))
            }
          </div>
          <Routes>
            <Route path={"/"} element={<MainImage />}></Route>

            <Route path={"/:tmpKey"} element={<RightTab1 />}></Route>
            <Route path={"/1/:tmpKey"} element={<Navi />}></Route>

            <Route path={"*"} element={<RightTab7 />}></Route>


          </Routes>
          <Footer></Footer>

        </div>
      </BrowserRouter >
    </div>

  );
}

export default App;
