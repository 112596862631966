import React from 'react';
import * as d3 from 'd3-scale-chromatic';

const test = ({ onClose }) => {
  // D3에서 연속적인 색상 스케일을 사용하여 100개의 고유한 색상을 생성합니다.
  const colors = Array.from({ length: 100 }, (_, i) => d3.interpolateSpectral(i / 99));

  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', gap: '10px' }} onClick={onClose}>
      {colors.map((color, index) => (
        <div key={index} style={{ backgroundColor: color, width: '50px', height: '50px' }} />
      ))}
    </div>
  );
};

export default test;
