import React, { useState, useEffect } from 'react';
import styles from "./DraftPage.module.css";
import { useTranslation } from 'react-i18next';
import DraftFilterModal from '../../components/modal/DraftFilterModal';
import DraftInfoModal from '../../components/modal/DraftInfoModal';

//--------------------------------- 이미지 파일 임포트
import filterIcon from '../../img/common/filter.png';
import draftListIcon from '../../img/common/draftListIcon.png';
import upArrowIcon from '../../img/common/UpTriangleBlack.png';
import downArrowIcon from '../../img/common/DownTriangleBlack.png';

function DraftPage() {
    const { t } = useTranslation();
    //--------------------------------- 변수
    const [selectedTab, setSelectedTab] = useState('default');
    const [allDraftList, setAllDraftList] = useState([]);
    const [userName, setUserName] = useState('');
    const [userInfo, setUserInfo] = useState({});
    const [onlyMine, setOnlyMine] = useState(false);
    const [reDraw, setReDraw] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [filterType, setFilterType] = useState('');
    const [selectedDraft, setSelectedDraft] = useState({});
    const [dateFilter, setDateFilter] = useState({ startDate: '', endDate: '' });
    const [teamFilter, setTeamFilter] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRange, setPageRange] = useState([1, 10]);
    const itemsPerPage = 20;

    const handleCloseModal = (shouldRefresh = false) => {
        if (shouldRefresh) {
            setReDraw(reDraw + 1);
        }
    };

    const openFilterModal = (type) => {
        setFilterType(type);
        setShowFilterModal(true);
    };

    const openInfoModal = (draft) => {
        setShowInfoModal(true);
        setSelectedDraft(draft);
    };

    const applyFilter = (filter) => {
        if (filterType === 'date') {
            setDateFilter(filter);
        } else if (filterType === 'team') {
            setTeamFilter(filter.selectedTeams);
        } else if (filterType === 'category') {
            setCategoryFilter(filter.selectedCategories);
        } else if (filterType === 'status') {
            setStatusFilter(filter.selectedStatuses);
        }
    };

    const filteredDraftList = allDraftList.filter(draft => {
        const draftDate = new Date(draft.regTime);
        const startDate = dateFilter.startDate ? new Date(dateFilter.startDate) : null;
        const endDate = dateFilter.endDate ? new Date(dateFilter.endDate) : null;

        let dateMatch = true;
        if (startDate && draftDate < startDate.setHours(0, 0, 0, 0)) dateMatch = false;
        if (endDate && draftDate > endDate.setHours(23, 59, 59, 999)) dateMatch = false;

        let teamMatch = true;
        if (teamFilter.length > 0 && !teamFilter.includes(draft.draftUserTeamName)) teamMatch = false;

        let categoryMatch = true;
        if (categoryFilter.length > 0 && !categoryFilter.includes(draft.draftCategory)) categoryMatch = false;

        let statusMatch = true;
        if (statusFilter.length > 0 && !statusFilter.includes(draft.authorizedStatus)) statusMatch = false;

        return dateMatch && teamMatch && categoryMatch && statusMatch && (!onlyMine || draft.draftUserName === userName || draft.authorizedPersonName === userName);
    });

    function hashmark(str) {
        if (str) {
            return (str.replace('hashmark', '#'));
        } else {
            return str;
        }
    }

    const list = {
        Banking_Info: "Working Stock",
        Organoid_Info: "Master Stock",
        Patient_Info: "Patient info",
        StorageList: "Storage",
    };

    const formatStat = (stat) => {
        switch (stat) {
            case "pending":
                return t("대기");
            case "rejected":
                return t("반려");
            case "approved":
                return t("승인");
            case "canceled":
                return t("취소");
            default:
                return '';
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    //--------------------------------- DB데이터 가져오기
    // 모든 기안 리스트 가져오기
    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: { "content-type": "application/json" }
        };

        fetch("/api/getAllDraftList", reqOption)
            .then((response) => response.json())
            .then(data => {
                setAllDraftList(data);
            });
    }, [reDraw]);

    // 현재 유저 정보 가져오기
    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: { "content-type": "application/json" }
        };

        fetch("/api/getLoginedUserInfo", reqOption)
            .then((response) => response.json())
            .then(data => {
                setUserName(data.data[0]?.userName);
                setUserInfo(data?.data[0]);
            });
    }, []);

    const sortedDraftList = () => {
        let sortableItems = [...filteredDraftList];
        if (sortConfig.key) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = '';
        }
        setSortConfig({ key, direction });
    };

    const clearSort = () => {
        setDateFilter({ startDate: '', endDate: '' });
        setTeamFilter([]);
        setCategoryFilter([]);
        setStatusFilter([]);
        setSortConfig({ key: '', direction: '' });
    };

    const currentDrafts = sortedDraftList().slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const totalPages = Math.ceil(sortedDraftList().length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);

        const newPageRange = [Math.floor((pageNumber - 1) / 10) * 10 + 1, Math.floor((pageNumber - 1) / 10) * 10 + 10];
        setPageRange(newPageRange);
    };


    const handleNextPageRange = () => {
        setPageRange([pageRange[0] + 10, pageRange[1] + 10]);
    };

    const handlePreviousPageRange = () => {
        setPageRange([pageRange[0] - 10, pageRange[1] - 10]);
    };

    return (
        <div className={styles.rightTab}>
            <div className={styles.topMenu}>
                <div className={styles.curOrganoid}>
                    <img src={draftListIcon} className={styles.draftListIcon} />
                    &nbsp;{t('기안 리스트')}
                </div>
                <div className={`${selectedTab === 'default' ? styles.topTabFront : styles.topTabBack}`}
                    onClick={() => {
                        setSelectedTab('default');
                        setOnlyMine(false);
                    }}
                >
                    View All Drafts
                </div>
                <div />
                <div className={`${selectedTab === 'myDraft' ? styles.topTabFront : styles.topTabBack}`}
                    onClick={() => {
                        setSelectedTab('myDraft');
                        setOnlyMine(true);
                    }}
                >
                    View My Drafts Only
                </div>
            </div>
            <div className={styles.rightTabBottomBox}>
                <button className={styles.clearFilterButton} onClick={clearSort}>
                    {t('필터링 및 정렬 취소')}
                </button>
                <div className={styles.draftColumnTitle}>
                    <div className={styles.columnTitle}>
                        Draft Registration Date
                        <img src={filterIcon} className={styles.filterIcon} onClick={() => openFilterModal('date')} />
                    </div>
                    <div className={styles.columnTitle} onClick={() => requestSort('draftDocumentId')}>
                        GBCC Key 값
                        <img src={filterIcon} className={styles.filterIcon} />
                        {sortConfig.key === 'draftDocumentId' && (
                            <img
                                src={sortConfig.direction === 'ascending' ? upArrowIcon : downArrowIcon}
                                className={styles.sortIcon}
                            />
                        )}
                    </div>
                    <div className={styles.columnTitle}>
                        Team Name
                        <img src={filterIcon} className={styles.filterIcon} onClick={() => openFilterModal('team')} />
                    </div>
                    <div className={styles.columnTitle} onClick={() => requestSort('draftUserName')}>
                        Reporter
                        <img src={filterIcon} className={styles.filterIcon} />
                        {sortConfig.key === 'draftUserName' && (
                            <img
                                src={sortConfig.direction === 'ascending' ? upArrowIcon : downArrowIcon}
                                className={styles.sortIcon}
                            />
                        )}
                    </div>
                    <div className={styles.columnTitle}>
                        Draft Target
                        <img src={filterIcon} className={styles.filterIcon} />
                    </div>
                    <div className={styles.columnTitle}>
                        Category
                        <img src={filterIcon} className={styles.filterIcon} onClick={() => openFilterModal('category')} />
                    </div>
                    <div className={styles.columnTitle}>
                        Approval Status
                        <img src={filterIcon} className={styles.filterIcon} onClick={() => openFilterModal('status')} />
                    </div>
                </div>
                <div className={styles.draftsList}>
                    {currentDrafts.map(draft => (
                        <div className={styles.draftInfo} key={draft.draftDocumentId} onClick={() => openInfoModal(draft)}>
                            {/* {console.log(draft)} */}
                            <div className={styles.draftDetailValue}>{formatDate(draft.regTime)}</div>
                            <div className={styles.draftDetailValue}>{draft.draftDocumentId}</div>
                            <div className={styles.draftDetailValue}>{draft.draftUserTeamName}</div>
                            <div className={styles.draftDetailValue}>{draft.draftUserName}</div>
                            <div className={styles.draftDetailValue}>{hashmark(draft.targetKey)}</div>
                            <div className={styles.draftDetailValue}>{list[draft.draftCategory]}</div>
                            <div className={styles.draftDetailValue}>{formatStat(draft.authorizedStatus)}</div>
                        </div>
                    ))}
                </div>
                <div className={styles.pagination}>
                    {pageRange[0] > 1 && (
                        <>
                            <div onClick={() => handlePageChange(1)} className={styles.sideButton}>&laquo;</div>
                            <div onClick={handlePreviousPageRange} className={styles.sideButton}>&lt;</div>
                        </>
                    )}
                    {[...Array(totalPages).keys()].slice(pageRange[0] - 1, pageRange[1]).map(index => (
                        <div
                            key={index}
                            className={`${styles.pageButton} ${index + 1 === currentPage ? styles.active : ''}`}
                            onClick={() => handlePageChange(index + 1)}
                            disabled={index + 1 === currentPage}
                        >
                            {index + 1}
                        </div>
                    ))}
                    {pageRange[1] < totalPages && (
                        <>
                            <div onClick={handleNextPageRange} className={styles.sideButton}>&gt;</div>
                            <div onClick={() => handlePageChange(totalPages)} className={styles.sideButton}>&raquo;</div>
                        </>
                    )}
                </div>
            </div>
            <DraftFilterModal
                show={showFilterModal}
                onClose={() => setShowFilterModal(false)}
                onApply={applyFilter}
                filterType={filterType}
            />
            <DraftInfoModal
                show={showInfoModal}
                onClose={() => setShowInfoModal(false)}
                selectedDraft={selectedDraft}
                reDrawView={() => handleCloseModal(true)}
            />
        </div>
    );
}

export default DraftPage;
