import React, { useEffect, useState } from "react";
import styles from "./LineName.module.css";
import plus from "../../img/common/plusIcon.png";
import minus from "../../img/common/minusIcon.png";
import downTriangleIcon from "../../img/icon/DownTriangleIcon.png";
import sideTriangleIcon from "../../img/icon/SideTriangleIcon.png";
import { Link } from 'react-router-dom';
import editImg from "../../img/common/editIcon.png";
import EditLineNameModal from "./editLineNameModal";

function LineName(props) {
    const [visibleFirst, setVisibleFirst] = useState(false);
    const [boxNum, setBoxNum] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [name, setName] = useState(props.name);

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                Vessel: props.vessel,
                Line: props.name,
            })
        };

        fetch("/api/countBankingByBox", reqOption)
            .then((response) => response.json())
            .then(data => {
                setBoxNum(data);
            });
    }, [refresh]);

    const handleModalSubmit = (newText) => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                vessel: props.vessel,
                newLineName: newText,
                lineName: props.name,
            })
        };

        fetch("/api/changeLineName", reqOption)
            .then((response) => response.json())
            .then(data => {
                setName(newText);
            })
            .catch(error => {
                console.error('An error occurred:', error);
            });
    };

    const getCountForBox = (box) => {
        const boxData = boxNum.find(b => b.Box.toUpperCase() === box);
        return boxData ? boxData.count : 0;
    };

    return (
        <div className={styles.lineNameMain}>
            <span className={styles.outlinePdo} onClick={(e) => {
                setVisibleFirst(!visibleFirst);
            }}>
                {!visibleFirst && <img className={styles.plusImg_low} src={sideTriangleIcon} alt="Expand" />}
                {visibleFirst && <img className={styles.minusImg_low} src={downTriangleIcon} alt="Collapse" />}
            </span>

            <span className={styles.pdoName} onClick={(e) => {
                setVisibleFirst(!visibleFirst);
            }}>
                {name}
            </span>
            {props?.authLevel >= 3 &&
                <img
                    src={editImg}
                    className={styles.lineNameEditImg}
                    onClick={() => setIsModalOpen(true)}
                    alt="Edit"
                />
            }
            <EditLineNameModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleModalSubmit}
                lineName={props.name}
            />
            {visibleFirst &&
                Array.from({ length: 13 }, (_, index) => (
                    <div key={`box-${index + 1}`} className={styles.box}>
                        <Link to={`/${props.vessel}/${props.name}/BOX${index + 1}`} className={styles.customLink}>
                            BOX{index + 1} ({getCountForBox(`BOX${index + 1}`)}/100)
                        </Link>
                    </div>
                ))
            }
        </div>
    );
}

export default LineName;
