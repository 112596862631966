// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageBox {
    display: flex;
    width: 60vw;
    height: -moz-fit-content;
    height: fit-content;
    overflow-x: auto;
    margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/organoid/rightTab/ImagePathologyPicture.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".imageBox {\n    display: flex;\n    width: 60vw;\n    height: fit-content;\n    overflow-x: auto;\n    margin-bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
