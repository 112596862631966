// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/Pretendard-SemiBold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../fonts/Pretendard-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "Pretendard-SemiBold";
    font-style: normal;
    font-weight: 600;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}

@font-face {
    font-family: "Pretendard-Regular";
    font-style: normal;
    font-weight: 400;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}

.PatientName_PatientListSecond__G4J6L {
    padding-left: 1.5vw;
    margin-bottom: 3px;
    height: 60%;
    height: 20px;
}

.PatientName_PatientListSecondTitle__9WT43 {
    color: black;
    text-decoration: none;
    font-family: "Pretendard-Regular";
    font-size: 80%;
    margin-left: 10px;
}

.PatientName_PatientListSecondNum__gA2-v{
    font-family: "Pretendard-Regular";
    margin-left: 5px;
    font-size: medium;
    font-weight: 500;
}

.PatientName_PatientListSecondTitle__9WT43:hover {
    color: rgb(150, 150, 212);
}

.PatientName_PatientListSecondTitle__9WT43:active {
    color: rgb(12, 136, 12);
}`, "",{"version":3,"sources":["webpack://./src/organoid/leftTab/PatientName.module.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,gBAAgB;IAChB,+DAAkE;AACtE;;AAEA;IACI,iCAAiC;IACjC,kBAAkB;IAClB,gBAAgB;IAChB,+DAAiE;AACrE;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,iCAAiC;IACjC,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,iCAAiC;IACjC,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["@font-face {\n    font-family: \"Pretendard-SemiBold\";\n    font-style: normal;\n    font-weight: 600;\n    src: url(\"../../fonts/Pretendard-SemiBold.otf\") format(\"opentype\");\n}\n\n@font-face {\n    font-family: \"Pretendard-Regular\";\n    font-style: normal;\n    font-weight: 400;\n    src: url(\"../../fonts/Pretendard-Regular.otf\") format(\"opentype\");\n}\n\n.PatientListSecond {\n    padding-left: 1.5vw;\n    margin-bottom: 3px;\n    height: 60%;\n    height: 20px;\n}\n\n.PatientListSecondTitle {\n    color: black;\n    text-decoration: none;\n    font-family: \"Pretendard-Regular\";\n    font-size: 80%;\n    margin-left: 10px;\n}\n\n.PatientListSecondNum{\n    font-family: \"Pretendard-Regular\";\n    margin-left: 5px;\n    font-size: medium;\n    font-weight: 500;\n}\n\n.PatientListSecondTitle:hover {\n    color: rgb(150, 150, 212);\n}\n\n.PatientListSecondTitle:active {\n    color: rgb(12, 136, 12);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PatientListSecond": `PatientName_PatientListSecond__G4J6L`,
	"PatientListSecondTitle": `PatientName_PatientListSecondTitle__9WT43`,
	"PatientListSecondNum": `PatientName_PatientListSecondNum__gA2-v`
};
export default ___CSS_LOADER_EXPORT___;
