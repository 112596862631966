import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import styles from "./editLineNameModal.module.css"; // CSS 모듈을 임포트합니다.

Modal.setAppElement('#root'); // 모달의 root element를 설정합니다.

function EditLineNameModal({ isOpen, onClose, onSubmit, lineName }) {
    const [text, setText] = useState(lineName);

    useEffect(() => {
        setText(lineName);
    }, [lineName]);


    const handleConfirm = () => {
        onSubmit(text);
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={styles.modal}
            overlayClassName={styles.overlay}
        >
            <div className={styles.container}>
                <input
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    className={styles.textBox}
                />
                <div className={styles.buttons}>
                    <button onClick={handleConfirm} className={styles.confirmButton}>확인</button>
                    <button onClick={onClose} className={styles.cancelButton}>취소</button>
                </div>
            </div>
        </Modal>
    );
}

export default EditLineNameModal;
