import React, { useState, useEffect } from 'react';
import plus from "../../img/common/plusIcon.png";
import minus from "../../img/common/minusIcon.png";
import styles from "./StorageList.module.css";
import LineName from './LineName';
import { useTranslation } from 'react-i18next';
import downTriangleIcon from "../../img/icon/DownTriangleIcon.png";
import sideTriangleIcon from "../../img/icon/SideTriangleIcon.png";

function StorageList(props) {
    const { t } = useTranslation();
    const [visibleFirst, setVisibleFirst] = useState(false);
    const [visibleSecond, setVisibleSecond] = useState(false);
    const [visibleThird, setVisibleThird] = useState(false);
    const [lineList, setLineList] = useState([]);

    useEffect(() => {
        const reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
        };

        fetch("/api/getLineList", reqOption)
            .then((response) => response.json())
            .then(data => {
                setLineList(data);
            });
    }, []);

    return (
        <div className={styles.storageListMain}>
            <div onClick={() => setVisibleFirst(!visibleFirst)} className={styles.storageList_1}>
                <span>
                    {!visibleFirst && <img className={styles.storage_PlusImg} src={sideTriangleIcon} alt="plusImg" />}
                    {visibleFirst && <img className={styles.storage_MinusImg} src={downTriangleIcon} alt="minusImg" />}
                </span>
                <span className={styles.storageVesselTitle}>{t('초저온 저장소')} 1</span>
            </div>
            {visibleFirst &&
                <>
                    {lineList?.filter(bankingInfo => bankingInfo.vesselNo === 1).map((bankingInfo, index) => (
                        <LineName key={index} name={bankingInfo.lineName} vessel={1} authLevel={props.authLevel} />
                    ))}
                </>
            }

            <div onClick={() => setVisibleSecond(!visibleSecond)} className={styles.storageList_1}>
                <span>
                    {!visibleSecond && <img className={styles.storage_PlusImg} src={sideTriangleIcon} alt="plusImg" />}
                    {visibleSecond && <img className={styles.storage_MinusImg} src={downTriangleIcon} alt="minusImg" />}
                </span>
                <span className={styles.storageVesselTitle}>{t('초저온 저장소')} 2</span>
            </div>
            {visibleSecond &&
                <>
                    {lineList?.filter(bankingInfo => bankingInfo.vesselNo === 2).map((bankingInfo, index) => (
                        <LineName key={index} name={bankingInfo.lineName} vessel={2} authLevel={props.authLevel} />
                    ))}
                </>
            }

            <div onClick={() => setVisibleThird(!visibleThird)}>
                <span>
                    {!visibleThird && <img className={styles.storage_PlusImg} src={sideTriangleIcon} alt="plusImg" />}
                    {visibleThird && <img className={styles.storage_MinusImg} src={downTriangleIcon} alt="minusImg" />}
                </span>
                <span className={styles.storageVesselTitle}>{t('초저온 저장소')} 3</span>
            </div>
            {visibleThird &&
                <>
                    {lineList?.filter(bankingInfo => bankingInfo.vesselNo === 3).map((bankingInfo, index) => (
                        <LineName key={index} name={bankingInfo.lineName} vessel={3} authLevel={props.authLevel} />
                    ))}
                </>
            }
        </div>
    );
}

export default StorageList;
