import styles from './PatientInfoTab.module.css';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DraftModal from '../../components/modal/DraftModal';

function PatientInfoTab() {
    const { t } = useTranslation();
    const { tmpKey } = useParams();

    const [isPatientData, setIsPatientData] = useState(false);

    const [draftOpinion, setDraftOpinion] = useState("");

    const [patientInfo, setPatientInfo] = useState({});
    const [isEditmode, setIsEditMode] = useState(false);

    const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);

    const hashmarkToSharp = (word) => {
        if (!word) return "";
        return word.replace('hashmark', '#');
    }

    const [formData, setFormData] = useState({
        PatientCode: "",
        gender: "",
        age: "",
        stage: "",
        histologicType: "",
        site: "",
        specialFeature: "",
        clinicalAnnotation: "",
        treatmentHistory: "",
        organism: "",
        orgin: "",
    });

    const handleEditClick = () => {
        setIsEditMode(!isEditmode);
    }

    const handleSaveClick = () => {
        setIsDraftModalOpen(true);
    }

    const draftFormData = () => {

    }

    const closeDraftModal = () => {
        setIsDraftModalOpen(false);
    }

    const saveDraft = (draft) => {
        setDraftOpinion(draft);  // draftOpinion 상태 업데이트
        setIsDraftModalOpen(false);  // 모달 닫고 데이터 저장 로직 실행
    }

    useEffect(() => {
        if (draftOpinion !== "") {
            const updatedFormData = {
                ...formData,
                draftOpinion
            };
            let reqOption = {
                method: "post",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(updatedFormData)
            }
            fetch('/api/updatePatientInfoPub', reqOption)
                .then(response => response.json())
                .then(data => {
                    alert(data.message);
                    setIsEditMode(false);
                });
        }
    }, [draftOpinion]);

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                param: tmpKey,
            })
        }

        fetch("/api/getPatientInfoAtOrganoid", reqOption)
            .then((response) => response.json())
            .then(data => {
                setPatientInfo(data);
            });
    }, [tmpKey]);

    useEffect(() => {
        if (patientInfo[0]) {
            setIsPatientData(true);
            setFormData({
                PatientCode: tmpKey || "",
                gender: patientInfo[0].Gender || "",
                age: patientInfo[0].Age || "",
                stage: patientInfo[0].Stage || "",
                histologicType: patientInfo[0].HistologicType || "",
                site: patientInfo[0].Site || "",
                specialFeature: patientInfo[0].SpecialFeature || "",
                clinicalAnnotation: patientInfo[0].Annotation || "",
                treatmentHistory: patientInfo[0].TreatmentHistory || "",
                organism: patientInfo[0].organism || "",
                orgin: patientInfo[0].orgin || "",
            });
        }
    }, [patientInfo]);

    return (
        <div className={styles.outerBox}>
            {
                isDraftModalOpen &&
                <DraftModal onClose={closeDraftModal} onSubmit={saveDraft} />
            }
            <div className={styles.basicInformation}>
                <span className={styles.title}>
                    Patient Information (Public)
                </span>
                {
                    isPatientData &&
                    <span className={styles.editButton} onClick={() => { handleEditClick() }}>
                        edit
                    </span>
                }
                {
                    isEditmode &&
                    <span className={styles.editButton} onClick={() => { handleSaveClick() }}>
                        save
                    </span>
                }
            </div>
            <div className={styles.contents}>
                {isPatientData ?
                    (
                        <>
                            <div className={styles.leftContent}>Patient code</div>
                            {
                                isEditmode ? <input value={formData.PatientCode} onChange={(e) => setFormData({ ...formData, PatientCode: e.target.value })} className={styles.rightContent} />
                                    : <div className={styles.rightContent}>{patientInfo[0]?.PatientCode}</div>
                            }

                            <div className={styles.leftContent}>Organism</div>
                            {
                                isEditmode ? <input value={formData.organism} onChange={(e) => setFormData({ ...formData, organism: e.target.value })} className={styles.rightContent} />
                                    : <div className={styles.rightContent}>{patientInfo[0]?.organism}</div>
                            }

                            <div className={styles.leftContent}>Orgin</div>
                            {
                                isEditmode ? <input value={formData.orgin} onChange={(e) => setFormData({ ...formData, orgin: e.target.value })} className={styles.rightContent} />
                                    : <div className={styles.rightContent}>{patientInfo[0]?.orgin}</div>
                            }

                            <div className={styles.leftContent}>Gender</div>
                            {
                                isEditmode ? <input value={formData.gender} onChange={(e) => setFormData({ ...formData, gender: e.target.value })} className={styles.rightContent} />
                                    : <div className={styles.rightContent}>{patientInfo[0]?.Gender}</div>
                            }

                            <div className={styles.leftContent}>Age</div>
                            {
                                isEditmode ? <input value={formData.age} onChange={(e) => setFormData({ ...formData, age: e.target.value })} className={styles.rightContent} />
                                    : <div className={styles.rightContent}>{patientInfo[0]?.Age}</div>
                            }

                            <div className={styles.leftContent}>Stage</div>
                            {
                                isEditmode ? <input value={formData.stage} onChange={(e) => setFormData({ ...formData, stage: e.target.value })} className={styles.rightContent} />
                                    : <div className={styles.rightContent}>{patientInfo[0]?.Stage}</div>
                            }

                            <div className={styles.leftContent}>Histologic type</div>
                            {
                                isEditmode ? <input value={formData.histologicType} onChange={(e) => setFormData({ ...formData, histologicType: e.target.value })} className={styles.rightContent} />
                                    : <div className={styles.rightContent}>{patientInfo[0]?.HistologicType}</div>
                            }

                            <div className={styles.leftContent}>Treatement history</div>
                            {
                                isEditmode ? <textarea value={formData.treatmentHistory} onChange={(e) => setFormData({ ...formData, treatmentHistory: e.target.value })} className={styles.rightContent} />
                                    : <div className={styles.rightContent}>{patientInfo[0]?.TreatmentHistory}</div>
                            }

                            <div className={styles.leftContent}>Clinical annotation</div>
                            {
                                isEditmode ? <input value={formData.clinicalAnnotation} onChange={(e) => setFormData({ ...formData, clinicalAnnotation: e.target.value })} className={styles.rightContent} />
                                    : <div className={styles.rightContent}>{patientInfo[0]?.clinicalAnnotation}</div>
                            }
                        </>
                    ) : (
                        <div>No patient data</div>
                    )
                }

            </div>
        </div >
    );
}

export default PatientInfoTab;
