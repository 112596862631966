// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/Pretendard-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "Pretendard-Regular";
    font-style: normal;
    font-weight: 400;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}

.LineName_lineNameMain__tduXh {
    margin-left: 20px;
}

.LineName_box__fPSB6 {
    margin-left: 30px;
}

.LineName_lineNameEditImg__pYhVH {
    margin-left: 4px;
    width: 10px;
}

.LineName_plusImg_low__\\+Syfp,
.LineName_minusImg_low__rrFlY {
    width: 0.9vw;
    margin-bottom: 3px;
}


.LineName_customLink__mq8kj {
    color: black;
    text-decoration: none;
    font-family: "Pretendard-Regular";
    font-size: small;

}

.LineName_customLink__mq8kj:hover {
    color: rgb(150, 150, 212);
}

.LineName_customLink__mq8kj:active {
    color: rgb(12, 136, 12);
}`, "",{"version":3,"sources":["webpack://./src/organoid/leftTab/LineName.module.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,kBAAkB;IAClB,gBAAgB;IAChB,+DAAiE;AACrE;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;;IAEI,YAAY;IACZ,kBAAkB;AACtB;;;AAGA;IACI,YAAY;IACZ,qBAAqB;IACrB,iCAAiC;IACjC,gBAAgB;;AAEpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["@font-face {\n    font-family: \"Pretendard-Regular\";\n    font-style: normal;\n    font-weight: 400;\n    src: url(\"../../fonts/Pretendard-Regular.otf\") format(\"opentype\");\n}\n\n.lineNameMain {\n    margin-left: 20px;\n}\n\n.box {\n    margin-left: 30px;\n}\n\n.lineNameEditImg {\n    margin-left: 4px;\n    width: 10px;\n}\n\n.plusImg_low,\n.minusImg_low {\n    width: 0.9vw;\n    margin-bottom: 3px;\n}\n\n\n.customLink {\n    color: black;\n    text-decoration: none;\n    font-family: \"Pretendard-Regular\";\n    font-size: small;\n\n}\n\n.customLink:hover {\n    color: rgb(150, 150, 212);\n}\n\n.customLink:active {\n    color: rgb(12, 136, 12);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lineNameMain": `LineName_lineNameMain__tduXh`,
	"box": `LineName_box__fPSB6`,
	"lineNameEditImg": `LineName_lineNameEditImg__pYhVH`,
	"plusImg_low": `LineName_plusImg_low__+Syfp`,
	"minusImg_low": `LineName_minusImg_low__rrFlY`,
	"customLink": `LineName_customLink__mq8kj`
};
export default ___CSS_LOADER_EXPORT___;
