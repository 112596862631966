import React from 'react';
import { ComposedChart, LineChart } from 'recharts';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ErrorBar
} from 'recharts';
import './MobileGraph.css';


const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload } = props;

  if (!payload.HVIRecommendedOrder) {
    return (
      <svg
        x={cx - 5}
        y={cy - 5}
        width={20}
        height={20}
        fill={stroke}
        viewBox="0 0 200 200"
      >
        <circle cx="50" cy="50" r="40" strokeWidth="3" fill={stroke} />
      </svg>
    );
  }

  return (
    <svg x={cx - 20} y={cy - 15} width={40} height={40} viewBox="0 0 200 200">
      <rect
        width="15"
        height="15"
        fill="white"
        stroke={stroke}
        strokeWidth="2"
        transform="matrix(3 3 -3 3 100 25)"
      />
    </svg>
  );
};

const MobileGraph = (props) => {
  var keynames = [];

  const data = props.graphData;
  var scartter_datas = [];
  for (const key in Object.keys(data)) {
    var scartter_data = [];
    var tmpAvg_2 = {};
    var tmpAvg05 = {};
    var tmpAvg12 = {};
    var tmpAvg19 = {};
    var tmpAvg26 = {};
    var tmpAvg33 = {};
    var tmpAvg40 = {};
    tmpAvg_2['num'] = -1.0;
    tmpAvg05['num'] = 0.5;
    tmpAvg12['num'] = 1.2;
    tmpAvg19['num'] = 1.9;
    tmpAvg26['num'] = 2.6;
    tmpAvg33['num'] = 3.3;
    tmpAvg40['num'] = 4.0;
    tmpAvg_2['avg'] = data[key]['AVG_-2.0'];
    tmpAvg05['avg'] = data[key]['AVG_0.5'];
    tmpAvg12['avg'] = data[key]['AVG_1.2'];
    tmpAvg19['avg'] = data[key]['AVG_1.9'];
    tmpAvg26['avg'] = data[key]['AVG_2.6'];
    tmpAvg33['avg'] = data[key]['AVG_3.3'];
    tmpAvg40['avg'] = data[key]['AVG_4.0'];
    tmpAvg_2['std'] = 0;
    tmpAvg05['std'] = data[key]['STDEV_0.5'];
    tmpAvg12['std'] = data[key]['STDEV_1.2'];
    tmpAvg19['std'] = data[key]['STDEV_1.9'];
    tmpAvg26['std'] = data[key]['STDEV_2.6'];
    tmpAvg33['std'] = data[key]['STDEV_3.3'];
    tmpAvg40['std'] = data[key]['STDEV_4.0'];
    scartter_data.push(tmpAvg_2);
    scartter_data.push(tmpAvg05);
    scartter_data.push(tmpAvg12);
    scartter_data.push(tmpAvg19);
    scartter_data.push(tmpAvg26);
    scartter_data.push(tmpAvg33);
    scartter_data.push(tmpAvg40);
    keynames.push(String(data[key]['Dose name']).split(' ')[0]);
    scartter_datas.push(scartter_data);
  }


  const colors = ['red', 'orange', 'green', 'blue', 'purple', 'brown', 'black', 'Orchid', 'gray', 'Navy', 'Maroon', 'Lime', 'Khaki', 'Gold', 'Cyan', 'Coral']

  // const level = 1;//추후 세션에서 가져오기
  // var permission = true;
  // if (props.level != null) {
  //   if (props.level['cancer_level'] != null) {
  //     if (props.level['cancer_level'] < level) {
  //       permission = false;
  //     } else {
  //       permission = true;
  //     }
  //   }
  // }
  // if (!permission) {
  //   return (
  //     <div className='mobileGraphInfo'>
  //       <div className='mobileGraphText'>
  //         {/* Not Available */}
  //         Not Authorized
  //       </div>
  //     </div>
  //   )
  // } else
  if (scartter_datas['length'] == 0) {
    return (
      <div className='mobileGraphInfo'>
        <div className='mobileGraphText'>
          Not Available
          {/* Not authorized */}
        </div>
      </div>
    )
  } else {

    return (
      <div className='mobileGraphBox'>
        <LineChart width={400} height={400}
          margin={{ top: 5, right: 30, left: 20, bottom: 40 }} >
          <CartesianGrid strokeDasharray="3 3" />
          {/* {keynames.map((keyname, index) => (
            <Line dataKey="avg" name={keyname} data={scartter_datas[index]} stroke={colors[index]} type='basis' >
              <ErrorBar dataKey={'std'} width={2} strokeWidth={1} stroke={colors[index]} direction="Y" />
            </Line>
          ))} */}
          <XAxis dataKey="num" ticks={[-1, 0, 1, 2, 3, 4]} domain={[-1, 4.2]} height={65}
            allowDuplicatedCategory={false} allowDataOverflow={true}
            type="number"
            label={{ value: "Drug concentration (log, nmol/L)", dy: 20, position: 'center' }}
            style={{
              fontSize: '0.8rem',
              fontFamily: 'Times New Roman'
            }}>

          </XAxis>
          <YAxis ticks={[0, 50, 100, 150]} domain={[0, 160]} allowDataOverflow={true}
            label={{ value: 'Relative cell viability (% control)', angle: -90, dx: -20, dy: 0, position: 'center' }}
            style={{
              fontSize: '0.8rem',
              fontFamily: 'Times New Roman'
            }}
          />
          <Legend layout="horizontal" verticalAlign="bottom" align="center" />

          {keynames.map((keyname, index) => (
            <Line dataKey="avg" name={keynames[index]} data={scartter_datas[index]} stroke={colors[index]} fill={colors[index]} type="basis" activeDot={true} dot={{ r: 2 }} key={index} >
              <ErrorBar dataKey='std' width={2} strokeWidth={1} stroke={colors[index]} direction="Y" />
            </Line>
          ))}
        </LineChart>

      </div>
    );
  }
};

export default MobileGraph;