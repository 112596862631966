// 원형의 퍼센트 게이지 가져오는 방법
import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from "./CircularBar.module.css";

function CircularBar(props) {

    return (
        <div className={styles.progressBar}>
            <CircularProgressbar
                value={props.percentage}
                text={`${props.percentage}%`}
                styles={{
                    path: {
                        stroke: `rgb(0, 167, 160)`,
                    },
                    text: {
                        fill: 'rgb(0, 167, 160)',
                    }
                }}
            />
            <div className={styles.progressBarTitle}>
                {props.title}
            </div>
        </div>
    );
}

export default CircularBar;