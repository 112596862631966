import React, { useState, useEffect } from 'react';
import styles from "./ManagementModal.module.css";

// 이미지 임포트
import Gmark from "../../img/common/Gmark.png";
import TreeStructureIcon from "../../img/common/TreeStructureIcon.png";
import downTriangle from "../../img/down-triangle.png";

// 컴포넌트 임포트
import InstitutionInfo from "./ManagementSubComponent/InstitutionInfo";
import LevelManagement from "./ManagementSubComponent/LevelManagement";
import MemberManagement from "./ManagementSubComponent/MemberManagement";
import OrganizationChart from "./ManagementSubComponent/OrganizationChart";

function ManagementModal({ onClose }) {

    const [authLevel, setAuthLevel] = useState();

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            }
        }

        fetch("/api/getAuthLevel", reqOption)
            .then((response) => response.json())
            .then(data => {
                setAuthLevel(data);
                console.log(data);
            });
    }, []);

    const [viewMenu, setViewMenu] = useState(true);
    const handleViewMenu = () => {
        setViewMenu(!viewMenu);
    }
    const [activeMenu, setActiveMenu] = useState(null); // 현재 활성화된 메뉴를 저장하는 상태

    const menus = ['기관 정보', '조직도', '멤버', '권한']; // 메뉴 항목

    const menuComponents = {
        '기관 정보': <InstitutionInfo authLevel={authLevel?.authLevel} />,
        '조직도': <OrganizationChart />,
        '멤버': <MemberManagement />,
        '권한': <LevelManagement />
    };

    return (
        <div className={styles.modalBackground}>
            <div className={styles.modalContent}>
                <button onClick={onClose} className={styles.closeButton}>X</button>
                <div className={styles.managementModalFrame}>
                    {/* 왼쪽 */}
                    <div className={styles.leftManagementPage}>
                        <div className={styles.companyTitle}>
                            <span>
                                <img src={Gmark} className={styles.gMark}></img>
                            </span>
                            <span className={styles.companyName}>
                                GBCC
                            </span>
                        </div>
                        <div className={styles.leftTitle} onClick={handleViewMenu}>
                            <span>
                                <img src={TreeStructureIcon} className={styles.treeIcon} />
                            </span>
                            <span className={styles.function} >
                                기관 관리
                            </span>
                            <span>
                                <img src={downTriangle} className={styles.downTriangle} />
                            </span>
                        </div>
                        {viewMenu &&
                            <div className={styles.leftMenu}>
                                {menus.map((menu, index) => (
                                    <div
                                        key={index}
                                        className={`${styles.leftMenuTitle} ${activeMenu === menu ? styles.menuActive : ''}`}
                                        onClick={() => setActiveMenu(menu)}>
                                        · {menu}
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                    {/* 오른쪽 */}
                    <div className={styles.rightManagementPage}>
                        {activeMenu && menuComponents[activeMenu]}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManagementModal;
