import React, { useState, useEffect } from 'react';
import styles from './BankingInfoStatsModal.module.css';
import { useTranslation } from 'react-i18next';

function BankingInfoStatsModal({ onClose, organoidKey }) {
    const { t } = useTranslation();
    const [stockData, setStockData] = useState([]);
    console.log(organoidKey);

    useEffect(() => {
        const reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ organoidKey: organoidKey }),
        };
        fetch('/api/getStockDataList', reqOption)
            .then(response => response.json())
            .then(data => {
                const updatedData = data.map(item => ({
                    ...item,
                    group: item.group?.toLowerCase() === '1' ? 'Master' : 'Working'
                }));
                setStockData(updatedData);
            });
    }, [organoidKey]);

    // 데이터를 그룹, 미디어, 페세지 순으로 정렬
    stockData.sort((a, b) => {
        if (a.group < b.group) return -1;
        if (a.group > b.group) return 1;
        if (a.media < b.media) return -1;
        if (a.media > b.media) return 1;
        if (a.passage < b.passage) return -1;
        if (a.passage > b.passage) return 1;
        return 0;
    });

    // 데이터를 그룹화하고 병합 셀을 계산합니다.
    const groupedData = stockData.reduce((acc, item) => {
        const { group, media, passage } = item;
        if (!acc[group]) acc[group] = { totalRows: 0, medias: {} };
        if (!acc[group].medias[media]) acc[group].medias[media] = [];
        acc[group].medias[media].push({ ...item, rowSpan: 1 });
        acc[group].totalRows++;
        return acc;
    }, {});

    const renderTableBody = () => {
        const tableRows = [];
        Object.entries(groupedData).forEach(([group, { totalRows, medias }]) => {
            let groupRowSpanned = false;
            Object.entries(medias).forEach(([media, mediaData]) => {
                let mediaRowSpan = mediaData.length;
                mediaData.forEach((item, index) => {
                    tableRows.push(
                        <tr key={`${group}-${media}-${item.passage}`}>
                            {!groupRowSpanned && (
                                <td rowSpan={totalRows}>{group}</td>
                            )}
                            {index === 0 && (
                                <td rowSpan={mediaRowSpan}>{media}</td>
                            )}
                            <td>{item.passage}</td>
                            <td>{item.quantity}</td>
                        </tr>
                    );
                    if (!groupRowSpanned) groupRowSpanned = true;
                });
            });
        });
        return tableRows;
    };

    return (
        <table className={styles.statsTable}>
            <thead>
                <tr>
                    <th>Master/Working</th>
                    <th>Culture Media</th>
                    <th>Passage No.</th>
                    <th>Quantity</th>
                </tr>
            </thead>
            <tbody>
                {renderTableBody()}
            </tbody>
        </table>
    );
}

export default BankingInfoStatsModal;
