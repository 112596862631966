import styles from "./ImageComponent.module.css";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ImageManagementModal from "./modal/ImageManagementModal";

function ImageComponent(props) {
    const { tmpKey } = useParams();
    const [urls, setUrls] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [draftOpinion, setDraftOpinion] = useState("");
    const [endPoint, setEndPoint] = useState("/api/getImages");

    useEffect(() => {
        const fetchData = async () => {
            let apiEndpoint = "/api/getImages";  // 기본 API 엔드포인트
            if (props.type !== 'Image') {
                setEndPoint("/api/getPathologyImages");
                apiEndpoint = "/api/getPathologyImages";  // 다른 종류의 이미지를 위한 API 엔드포인트
            }

            const reqOption = {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    name: tmpKey
                }),
            };

            try {
                const response = await fetch(apiEndpoint, reqOption);
                const data = await response.json();
                setUrls(data);  // URL을 저장
            } catch (error) {
                console.error('Failed to fetch images:', error);
            }
        };

        fetchData();
    }, [tmpKey, props.type]);  // props.type 의존성 추가

    const handleEditClick = () => {
        setIsEditModalOpen(!isEditModalOpen);
    };

    const closeDraftModal = () => {
        setIsEditModalOpen(false);
    };

    const saveDraft = (draft) => {
        setDraftOpinion(draft);
        setIsEditModalOpen(false);
    };

    const toNewBlob = (sample) => {
        const newBlob = new Blob([new Uint8Array(sample)], { type: 'image/jpg' });
        return URL.createObjectURL(newBlob);
    };

    // 이미지 이름 변경 로직
    const nameChange = (name) => {
        if (name) {
            name = name.replace('.jpg', '');
            name = name.replace('.jpeg', '');
            name = name.replace(tmpKey + '_', '');
            name = name.replace(tmpKey.replace('hashmark', '#') + '_', '');
            let arr = name.split('_');
            let first = arr[0];
            let second = arr.find(part => part.charAt(0) === 'P');
            let third = arr.find(part => part.charAt(part.length - 1) === 'X');

            if (second === "P99") {
                name = third ? `${first}_${third}` : `${first}`; // `third`가 없는 경우 first만 사용
            } else {
                // second나 third가 없는 경우를 고려하여 조건부로 이름을 구성
                if (second && third) {
                    name = `${first}_${second}_${third}`;
                } else if (second) {
                    name = `${first}_${second}`;
                } else if (third) {
                    name = `${first}_${third}`;
                } else {
                    name = `${first}`;
                }
            }
        }
        return name;
    };

    // 이미지 네비게이션 함수
    const nextImage = () => {
        setCurrentIndex(currentIndex < urls.length - 1 ? currentIndex + 1 : currentIndex);
    };

    const prevImage = () => {
        setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : currentIndex);
    };

    return (
        <div className={styles.outBox}>
            <div className={styles.title}>
                <span>{props.type === 'Image' ? 'PDO Image (Bright Field)' : 'PDO Pathology'}</span>
                <span className={styles.editButton} onClick={handleEditClick}>
                    edit
                </span>
                {isEditModalOpen &&
                    <ImageManagementModal onClose={closeDraftModal} onSubmit={saveDraft} endPoint={endPoint} />
                }
            </div>
            <div className={styles.imageContainer}>
                {urls.length > 0 && (
                    <>
                        <button onClick={prevImage}>&lt;</button>
                        <img src={toNewBlob(urls[currentIndex]?.picture?.data)} alt="Displayed content" width="80%" />
                        <button onClick={nextImage}>&gt;</button>
                    </>
                )}
            </div>
            <div className={styles.pictureName}>
                {nameChange(urls[currentIndex]?.picture_key)}
            </div>
        </div>
    );
}

export default ImageComponent;
