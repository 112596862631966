import styles from './BankingInfoGroup.module.css';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import BankingForm from './BankingForm';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import StockTracing from './StockTracing';
import { useTranslation } from 'react-i18next';
import BankingInfoStatsModal from './BankingInfoStatsModal';
import BankingInfo from './BankingInfo';

//이미지 임포트
import filterIcon from "../../img/common/filter.png";
import masterY from "../../img/common/masterY.png";
import masterN from "../../img/common/masterN.png";

Modal.setAppElement('#root');

function BankingInfoGroup() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    //엑셀 다운로드용 키 list
    const [selectedKeys, setSelectedKeys] = useState([]);

    const { tmpKey, timeKey } = useParams();
    const [bankingInfoGroup, setBankingInfoGroup] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTracingModalOpen, setIsTracingModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [patientCode, setPatientCode] = useState({});
    const [showStatsModal, setShowStatsModal] = useState(false);
    // 모달을 열기 위한 함수
    const openStatsModal = () => setShowStatsModal(!showStatsModal);
    // 모달을 닫기 위한 함수
    const closeStatsModal = () => setShowStatsModal(false);
    const [showFilterModal, setShowFilterModal] = useState(false);

    // 뱅킹 상세 리스트 모달 변수
    const [showBankingList, setShowBankingList] = useState(false);

    // 각 항목의 고유한 값들을 저장할 상태 변수
    const [passageNoOptions, setPassageNoOptions] = useState([]);
    const [cultureMediaOptions, setCultureMediaOptions] = useState([]);
    const [freezingDateOptions, setFreezingDateOptions] = useState([]);
    const [keeperOptions, setKeeperOptions] = useState([]);

    // 페이지네이션을 위한 상태 변수
    const [currentPage, setCurrentPage] = useState(1);  // 현재 페이지
    const itemsPerPage = 6;  // 한 페이지에 보여줄 항목 수

    // 필터 상태 변수
    const [filterOptions, setFilterOptions] = useState({
        passageNo: '',
        cultureMedia: '',
        freezingDate: '',
        keeper: '',
    });

    // 필터 변경 핸들러
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilterOptions(prevOptions => ({
            ...prevOptions,
            [name]: value
        }));
    };

    // 그룹화된 뱅킹 정보 가져오기
    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                param: tmpKey
            }),
        }
        fetch("/api/getBankingGroupInfo", reqOption)
            .then((response) => response.json())
            .then(data => {
                console.log(data);
                setBankingInfoGroup(data);
            });
    }, []);

    // 필터링된 리스트
    const filteredList = bankingInfoGroup.filter(item => {
        return (filterOptions.passageNo ? item.PassageNo === filterOptions.passageNo : true) &&
            (filterOptions.cultureMedia ? item.CultureMedia === filterOptions.cultureMedia : true) &&
            (filterOptions.freezingDate ? item.FreezingDate === filterOptions.freezingDate : true) &&
            (filterOptions.keeper ? item.Keeper === filterOptions.keeper : true);
    });

    const paginatedFiltered = filteredList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const renderFilterModal = () => (
        <Modal isOpen={showFilterModal} onRequestClose={() => setShowFilterModal(false)} style={customStyles}>
            <div>
                <h2>Filter Options</h2>
                <div>
                    <label htmlFor="passageNo">Passage No:</label>
                    <select name="passageNo" value={filterOptions.passageNo} onChange={handleFilterChange}>
                        <option value="">All</option>
                        {passageNoOptions.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="cultureMedia">Culture Media:</label>
                    <select name="cultureMedia" value={filterOptions.cultureMedia} onChange={handleFilterChange}>
                        <option value="">All</option>
                        {cultureMediaOptions.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="freezingDate">Freezing Date:</label>
                    <select name="freezingDate" value={filterOptions.freezingDate} onChange={handleFilterChange}>
                        <option value="">All</option>
                        {freezingDateOptions.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="keeper">Keeper:</label>
                    <select name="keeper" value={filterOptions.keeper} onChange={handleFilterChange}>
                        <option value="">All</option>
                        {keeperOptions.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <button onClick={() => setShowFilterModal(false)}>Apply Filters</button>
            </div>
        </Modal>
    );

    // 페이지 변경 핸들러
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const paginatedList = bankingInfoGroup.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // 체크박스 체크시 list에 추가하는 method
    const handleCheckboxChange = (key, isChecked) => {
        setSelectedKeys(prevKeys => {
            if (isChecked) {
                return [...prevKeys, key];
            } else {
                return prevKeys.filter(k => k !== key);
            }
        });
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    const apiCall = async (endpoint, options) => {
        const response = await fetch(endpoint, options);
        const data = await response.json();
        return data;
    };

    const fetchData = useCallback(async (endpoint, stateSetter) => {
        let reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ param: tmpKey }),
        };
        const data = await apiCall(endpoint, reqOption);
        stateSetter(data);
    }, [tmpKey]);

    useEffect(() => {
        fetchData('/api/getPatientCode', setPatientCode);
    }, [tmpKey, fetchData]);

    useEffect(() => {
        // 각 항목의 고유한 값들을 추출하여 상태 변수에 저장
        const passageNos = new Set(bankingInfoGroup.map(item => item.PassageNo));
        const cultureMedias = new Set(bankingInfoGroup.map(item => item.CultureMedia));
        const freezingDates = new Set(bankingInfoGroup.map(item => item.FreezingDate));
        const keepers = new Set(bankingInfoGroup.map(item => item.Keeper));

        setPassageNoOptions([...passageNos]);
        setCultureMediaOptions([...cultureMedias]);
        setFreezingDateOptions([...freezingDates]);
        setKeeperOptions([...keepers]);
    }, [bankingInfoGroup]);

    const openModal = useCallback((item) => {
        if (item) {
            navigate(`/organoid/${tmpKey}/bank/${formatDate(item.time)}`);
        }
        if (item.exist) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        }
        setIsModalOpen(true);
    }, [tmpKey, navigate]);

    useEffect(() => {
        const matchingItem = bankingInfoGroup.find(item => formatDate(item.time) === timeKey);
        if (matchingItem && !isModalOpen) {
            openModal(matchingItem);
        }
    }, [bankingInfoGroup, isModalOpen, openModal, timeKey]);

    const fetchBankingList = () => {
        let reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ param: tmpKey }),
        };
        fetch('/api/getBankingGroupInfo', reqOption)
            .then((response) => response.json())
            .then((data) => setBankingInfoGroup(data));
    };

    const closeModal = () => {
        navigate(`/organoid/${tmpKey}`);
        setSelectedItem(null);
        setIsModalOpen(false);
    };

    const openTracingModal = (item) => {
        setSelectedItem(item);
        setIsTracingModalOpen(true);
    };

    const closeTracingModal = () => {
        setSelectedItem(null);
        setIsTracingModalOpen(false);
    };

    const handleRetrieve = (newItem) => {
        Object.assign(newItem, { Vessel: '', Line: '', Box: '', Position: '0' });
        newItem.time = formatDate(newItem.time);
        let reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ param: newItem }),
        };

        console.log(newItem);

        fetch('/api/updateBankingInfo', reqOption)
            .then((response) => response.json())
            .then((data) => {
                if (data === "err") alert("업데이트에 실패하였습니다.");
                fetchBankingList();
            });

        closeModal();
    };

    const handleDelete = (itemToDelete) => {
        itemToDelete.time = formatDate(itemToDelete.time);
        let reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ param: itemToDelete }),
        };

        fetch('/api/deleteBankingInfo', reqOption)
            .then((response) => response.json())
            .then((data) => {
                console.log(data?.message);
                if (data?.message) {
                    alert(data?.message);
                }
                if (data === "err") alert("데이터 삭제에 실패하였하였습니다.");
                fetchBankingList();
            });
        closeModal();
    };

    const handleSave = (newItem, isEditMode) => {
        newItem.PatientCode = patientCode[0]?.PatientCode;
        newItem.Organoid_Info_Key = tmpKey;
        // newItem.time = newItem.time;

        const reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ param: newItem }),
        };
        console.log(newItem);

        const apiEndpoint = isEditMode ? '/api/updateBankingInfo' : '/api/insertBankingInfo';

        fetch(apiEndpoint, reqOption)
            .then((response) => response.json())
            .then((data) => {
                if (data?.message === "duplication") {
                    alert("해당 위치에 이미 데이터가 저장되어 있습니다. 다른 위치를 선택해주세요.");
                } else if (data.message === "All positions are occupied") {
                    alert("모든 위치가 차지되어 있습니다. 다른 Vessel, Line, Box를 선택해주세요.");
                } else if (data === "err") {
                    alert(isEditMode ? "업데이트에 실패하였습니다." : "데이터 입력에 실패하였습니다.");
                } else {
                    alert(data?.message);
                    fetchBankingList();
                    closeModal();
                }
            });
    };

    // 엑셀 다운로드 클릭 시 실행하는 method
    const handleDownload = () => {
        const data = selectedKeys.map(key =>
            bankingInfoGroup.find(item => formatDate(item.time) === key)
        );
        // Add URL column to each data row
        data.forEach(row => {
            row.URL = `https://banking.gradiantbio.com/organoid/${row.Organoid_Info_Key}/bank/${formatDate(row.time).replace(' ', '%20')}`;
        });

        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Sheet1");

        const wbout = write(wb, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: "application/octet-stream" }), 'data.xlsx');
    };

    const [radioChecked, setRadioChecked] = useState(false);

    const toggleRadio = () => {
        if (radioChecked) {
            setRadioChecked(false);
        } else {
            setRadioChecked(true);
        }
    }


    return (
        <div className={styles.bankingInfo}>
            <div className={styles.bankingHeaderGrid}>
                <div className={styles.bankingHeader}>
                    {t('뱅킹 정보')}
                </div>
                <button onClick={() => setShowFilterModal(true)} className={styles.bankingHeaderButton}>
                    <img src={filterIcon} className={styles.iconImgSize}></img>
                    &nbsp;{t('필터')}
                </button>
                <button onClick={() => openModal({ exist: 'null', patientCode: patientCode })} className={styles.bankingHeaderButton}>{t('추가')}</button>
                {renderFilterModal()}
                <button onClick={handleDownload} className={styles.bankingHeaderButton}>Download Excel</button>
                <button onClick={openStatsModal} className={styles.bankingHeaderButton}>{t('통계')}</button>
                <Modal
                    isOpen={showStatsModal}
                    onRequestClose={closeStatsModal}
                    overlayClassName={styles.statsModalOverlay}
                    className={styles.statsModalContent}
                    contentLabel="Banking Info Statistics"
                >
                    <BankingInfoStatsModal
                        onClose={closeStatsModal}
                        organoidKey={tmpKey}
                    />
                </Modal>
            </div>
            <div> </div>

            <div className={styles.bankingInfoBottom}>
                <div className={styles.bankingTabLeftSide}>
                    <div>&nbsp;</div>
                    <div className={styles.bankingLeftTitle}>Organoid Key</div>
                    <div className={styles.bankingLeftTitle}>Passage No.</div>
                    <div className={styles.bankingLeftTitle}>Culture Media</div>
                    <div className={styles.bankingLeftTitle}>Freezing Date</div>
                    <div className={styles.bankingLeftTitle}>Keeper</div>
                    <div className={styles.bankingLeftTitle}>Thawing No.</div>

                </div>
                {paginatedFiltered.map((item, index) => (
                    <div onClick={() => {
                        setSelectedItem(item);  // 선택된 아이템 설정
                        setShowBankingList(true)
                    }} className={styles.bankingTabRightSide} key={item.time || index}>
                        <div className={styles.bankingInfoData}>{item.Organoid_Info_Key?.replace('hashmark', '#')}</div>
                        <div className={styles.bankingInfoData}>{item.PassageNo || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.CultureMedia || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.FreezingDate || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.Keeper || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.thawingNo || '-'}</div>
                    </div>
                ))}
                <Modal isOpen={showBankingList} onRequestClose={() => setShowBankingList(false)} style={customStyles}>
                    <BankingInfo item={selectedItem} />
                </Modal>
            </div>


            {/* 페이지네이션 버튼들 */}
            <div className={styles.pagination}>
                {Array.from({ length: Math.ceil(bankingInfoGroup.length / itemsPerPage) }, (_, index) => index + 1).map(page => (
                    <button
                        key={page}
                        className={page === currentPage ? styles.activePage : ""}
                        onClick={() => handlePageChange(page)}
                    >
                        {page}
                    </button>
                ))}
            </div>

            {/* 부모 추적 모달창 */}
            <Modal isOpen={isTracingModalOpen} onRequestClose={closeTracingModal} style={customStyles}>
                <StockTracing
                    time={selectedItem?.time}
                    item={selectedItem}
                    onCancel={closeTracingModal}
                />
            </Modal>
            {/* Display the modal for adding or editing banking info */}
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={customStyles}>
                <h2>{selectedItem?.mode === "add" ? 'Add Banking Information under' : selectedItem ? 'Edit Banking Information' : 'Add Banking Information'}</h2>
                <BankingForm
                    mode={selectedItem?.mode}
                    time={selectedItem?.time}
                    item={selectedItem}
                    onSave={handleSave}
                    onCancel={closeModal}
                    onDelete={handleDelete}
                    onRetrieve={handleRetrieve}
                    isEditMode={selectedItem?.mode === "add" ? false : !!selectedItem}
                    patientCode={patientCode[0]?.PatientCode || ""}
                />
            </Modal>
        </div>
    );
}

const customStyles = {
    content: {
        width: 'fit-content',
        height: 'fit-content',
        margin: 'auto',
    },
};

export default BankingInfoGroup;
