import styles from './LargeFileUploads.module.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function LargeFileUploads({ onClose }) {
    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const { tmpKey } = useParams();

    const handleFileChange = (event) => {
        setFiles(event.target.files);
    };

    const validateFileName = (fileName) => {
        const regex = /^.{15}_\d+\.fastq\.gz$/;
        return regex.test(fileName);
    };

    const handleFileUpload = async () => {
        if (files.length !== 2) {
            alert(t('Please select exactly two files to upload.'));
            return;
        }

        if (!validateFileName(files[0].name) || !validateFileName(files[1].name)) {
            alert(t('File names must be in the format: 15 characters, an underscore, a number, followed by .fastq.gz'));
            return;
        }

        const prefix1 = files[0].name.substring(0, 15);
        const prefix2 = files[1].name.substring(0, 15);
        if (prefix1 !== prefix2) {
            alert(t('The first 15 characters of both file names must be identical.'));
            return;
        }

        setUploading(true);
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
            formData.append(`fileName${i}`, encodeURIComponent(files[i].name)); // 파일명을 URI 인코딩
        }
        // tmpKey 값을 'param' 변수로 추가
        formData.append('param', tmpKey);

        try {
            await axios.post('/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percentCompleted = Math.round((loaded * 100) / total);
                    setUploadProgress(percentCompleted);
                }
            });
            alert(t('Files uploaded successfully.'));
        } catch (error) {
            console.error('Error uploading files:', error);
            alert(t('Failed to upload files.'));
        } finally {
            setUploading(false);
            setFiles([]);
            setUploadProgress(0);
        }
    };

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.modalHeader}>
                    <span>{t('Large File Uploads')}</span>
                    <button className={styles.closeButton} onClick={onClose}>X</button>
                </div>
                <div className={styles.textInput}>
                    <input type="file" multiple onChange={handleFileChange} />
                </div>
                {files.length > 0 && (
                    <div className={styles.uploadDetails}>
                        {[...files].map((file, index) => (
                            <p key={index}>{file.name} - {(file.size / (1024 * 1024)).toFixed(2)} MB</p>
                        ))}
                    </div>
                )}
                <div className={styles.draftButtonBox}>
                    <button className={styles.submitButton} onClick={handleFileUpload} disabled={uploading}>
                        {uploading ? `${t('Uploading')}... (${uploadProgress}%)` : t('Upload')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LargeFileUploads;
