import React, { useState, useEffect } from 'react';
import styles from './DraftPatientDetailInfoComponent.module.css';

function DraftPatientDetailInfoComponent(props) {
    const [patientData, setPatientData] = useState();

    const hashmarkToSharp = (str) => {
        return str.replace('hashmark', '#');
    }

    useEffect(() => {
        let reqOption = {
            method: 'post',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                param: props.Key
            }),
        };

        fetch("/api/getPatientInfo_noLog", reqOption)
            .then((response) => response.json())
            .then(data => {
                if (data && data.length > 0) {
                    setPatientData(data[0]);
                }
            });
    }, []);

    return (
        <>
            {patientData && (
                <div className={styles.main}>
                    <div className={styles.draftBankingTitle}>Patient information</div>
                    <div className={styles.bankingInfoInDraft}>
                        <div className={styles.leftTitle}>Patient code</div>
                        <div className={styles.rightContent}>{patientData.PatientCode}</div>

                        <div className={styles.leftTitle}>Orgin</div>
                        <div className={styles.rightContent}>{patientData.orgin}</div>

                        <div className={styles.leftTitle}>Gender</div>
                        <div className={styles.rightContent}>{patientData.Gender}</div>

                        <div className={styles.leftTitle}>Age</div>
                        <div className={styles.rightContent}>{patientData.Age}</div>

                        <div className={styles.leftTitle}>Stage</div>
                        <div className={styles.rightContent}>{patientData.Stage}</div>

                        <div className={styles.leftTitle}>Histologic type</div>
                        <div className={styles.rightContent}>{patientData.HistologicType}</div>

                        <div className={styles.leftTitle}>Treatement history</div>
                        <div className={styles.rightContent}>
                            {patientData.TreatmentHistory?.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>

                        <div className={styles.leftTitle}>Clinical annotation</div>
                        <div className={styles.rightContent}>
                            {patientData.clinicalAnnotation?.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default DraftPatientDetailInfoComponent;
