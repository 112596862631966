// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditBasicInfoModal_modal-dialog1__9aA0I {
    z-index: 4000 !important;
    /* or any higher value */
}

.EditBasicInfoModal_modal-dialog2__sTICX {
    z-index: 4001 !important;
    /* or any higher value */
}`, "",{"version":3,"sources":["webpack://./src/organoid/rightTab/EditBasicInfoModal.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;IACxB,wBAAwB;AAC5B","sourcesContent":[".modal-dialog1 {\n    z-index: 4000 !important;\n    /* or any higher value */\n}\n\n.modal-dialog2 {\n    z-index: 4001 !important;\n    /* or any higher value */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-dialog1": `EditBasicInfoModal_modal-dialog1__9aA0I`,
	"modal-dialog2": `EditBasicInfoModal_modal-dialog2__sTICX`
};
export default ___CSS_LOADER_EXPORT___;
