// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileGraphBox {
    margin-top: 20px;
}

.mobileGraphInfo {
    margin-top: 25px;
    width: 110vw;
    height: auto;
    padding-bottom: 30px;
    margin-bottom: 30px;
    background-color: rgba(128, 128, 128, 0.2);
}

.mobileGraphText {
    margin-left: 20px;
    padding-top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/right_tab/MobileGraph.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,oBAAoB;IACpB,mBAAmB;IACnB,0CAA0C;AAC9C;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".mobileGraphBox {\n    margin-top: 20px;\n}\n\n.mobileGraphInfo {\n    margin-top: 25px;\n    width: 110vw;\n    height: auto;\n    padding-bottom: 30px;\n    margin-bottom: 30px;\n    background-color: rgba(128, 128, 128, 0.2);\n}\n\n.mobileGraphText {\n    margin-left: 20px;\n    padding-top: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
