import React, { useEffect, useState, useCallback, Component } from "react";
import "./ImagePathologyPicture.css";
import { renderMatches, useParams, } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import Image from './Image';

function ImagePathologyPicture(props) {
    const [urls, setUrls] = useState([{
    }]);


    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                name: props.cancerKey
            }),

        }

        fetch("/api/getPathologyImages", reqOption)
            .then((response) => response.json())
            .then(data => {
                setUrls(data);
            });
    }, []);

    // const level = 1;//추후 세션에서 가져오기
    // var permission = true;
    // if (props.level != null) {
    //     if (props.level['cancer_level'] != null) {
    //         if (props.level['cancer_level'] < level) {
    //             permission = false;
    //         } else {
    //             permission = true;
    //         }
    //     }
    // }
    // if (!permission) {
    //     return (
    //         <div className='graphInfo'>
    //             <div className='graphText'>
    //                 {/* Not Available */}
    //                 Not Authorized
    //             </div>
    //         </div>
    //     )
    // } else 
    if (urls['length'] == 0) {
        return (
            <div className='graphInfo'>
                <div className='graphText'>
                    Not Available
                    {/* Not authorized */}
                </div>
            </div>
        )
    } else {

        return (
            <div className="imageBox">
                {
                    urls.map((url, index) => (
                        <Image picture={url} key={index} tableName={"Pathology_Picture_picture_data"}></Image>
                    ))
                }
            </div>
        );
    }
}



export default ImagePathologyPicture;