// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CircularBar_progressBar__obFwl {
    width: 100%;
}

.CircularBar_progressBarTitle__Z1Ebf {
    font-size: small;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/organoid/managementComponent/CircularBar.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".progressBar {\n    width: 100%;\n}\n\n.progressBarTitle {\n    font-size: small;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": `CircularBar_progressBar__obFwl`,
	"progressBarTitle": `CircularBar_progressBarTitle__Z1Ebf`
};
export default ___CSS_LOADER_EXPORT___;
