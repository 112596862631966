import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from './StorageBox.module.css';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import ExcelDownloadIcon from '../../img/common/excel-download.png';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import { useTranslation } from 'react-i18next';
import EditBoxMemo from "../../components/modal/EditBoxMemo";
import OrganoidInfoTab from "./OrganoidInfoTab";

//image import
import editPen from '../../img/common/orangePen.png';
import downloadIcon from '../../img/common/downloadIcon.png';
import BankingInfoTab from "./BankingInfoTab";

function StorageBox() {
  const { t } = useTranslation();
  const [authLevel, setAuthLevel] = useState("");
  const [refresh, setRefresh] = useState(0);
  // 모달에서 현재 작업 유형을 저장할 상태 추가
  const [currentAction, setCurrentAction] = useState(null);
  const { vessel, line, box } = useParams();
  const [boxData, setBoxData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [positionInput, setPositionInput] = useState("");
  const [draftOpinion, setDraftOpinion] = useState("");
  const [showOpinionModal, setShowOpinionModal] = useState(false);
  const [vesselNum, setVesselNum] = useState();
  const [lineNum, setLineNum] = useState();
  const [boxNum, setBoxNum] = useState();
  const [organoidList, setOrganoidList] = useState([]);
  const [boxMemo, setBoxMemo] = useState('');
  const [editMemo, setEditMemo] = useState('');
  const [selectedOrganoidKey, setSelectedOrganoidKey] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const saveMemo = (text) => {
    setBoxMemo(text);
    const reqOption = {
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        vessel: vessel,
        line: line,
        box: box,
        memo: text,
      })
    }

    fetch("/api/setBoxMemo", reqOption)
      .then((response) => response.json())
      .then(data => {
      });
  }

  const closeEditMemoModal = () => {
    setEditMemo(false);
  }

  const onEditPenClick = () => {
    setEditMemo(true);
  }

  const getColorForOrganoid = (organoidKey) => {
    let hash = 0;
    for (let i = 0; i < organoidKey.length; i++) {
      hash = organoidKey.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00FFFFFF).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };


  const handleSubmit = () => {
    // selectedKeys를 서버에 POST하여 데이터 가져오기
    const reqOption = {
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        vessel: vessel,
        line: line,
        box: box,
      })
    }

    fetch("/api/getBankingListByStorage", reqOption)
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          element.time = formatDate(element.time);
          element.url = "https://banking.gradiantbio.com/organoid/" + element.Organoid_Info_Key + "/bank/" + element.time;
          element.url = element.url.replace(' ', '%20');
          element.Organoid_Info_Key = element.Organoid_Info_Key.replace('hashmark', '#');
        });
        // 가져온 데이터를 엑셀 파일로 만들기
        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Sheet1");

        // 엑셀 파일 다운로드
        const wbout = write(wb, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: "application/octet-stream" }), 'data.xlsx');
      });
  };

  useEffect(() => {
    let reqOption = {
      method: "post",
      headers: {
        "content-type": "application/json"
      },
    }
    fetch("/api/getAuthLevel", reqOption)
      .then((response) => response.json())
      .then(data => {
        setAuthLevel(data.authLevel);
      });
  }, [])

  useEffect(() => {
    fetchData();
    fetchDataOrganoid();
  }, [vessel, line, box, refresh]);

  const fetchDataOrganoid = () => {
    let reqOption = {
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        vessel: vessel,
        line: line,
        box: box,
      })
    };

    fetch("/api/getBoxDataNumber", reqOption)
      .then((response) => response.json())
      .then(data => {
        setOrganoidList(data);
      });

  };
  const fetchData = () => {
    let reqOption = {
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        vessel: vessel,
        line: line,
        box: box,
      })
    };

    fetch("/api/getBoxData", reqOption)
      .then((response) => response.json())
      .then(data => {
        setBoxData(data);
      });

    fetch("/api/getBoxInfo", reqOption)
      .then((response) => response.json())
      .then(data => {
        setBoxMemo(data[0]?.memo);
      });
  };

  const handleCellClick = (data) => {
    if (data === null) {
      return;
    }

    setSelectedOrganoidKey(data.Organoid_Info_Key);
    setSelectedData(data);
    // setBankingKey(data.time);
    setPositionInput(data.Position);
  };

  const getCellData = (position) => {
    const cellData = boxData.find(data => data.Position === position);
    return cellData ? cellData : null;
  };

  const renderGrid = () => {
    const grid = [];
    let position = 1;

    for (let i = 0; i < 10; i++) {
      const row = [];
      for (let j = 0; j < 10; j++) {
        const cellData = getCellData(position.toString());
        const isSelected = cellData && cellData.Organoid_Info_Key === selectedOrganoidKey;
        const cellStyle = cellData
          ? `${styles.gridCell} ${styles.occupiedCell}`
          : `${styles.gridCell} ${styles.emptyCell}`;
        const backgroundColor = cellData ? getColorForOrganoid(cellData.Organoid_Info_Key) : 'transparent';
        row.push(
          <div
            key={position}
            className={cellStyle}
            style={{ backgroundColor }}
            onClick={() => handleCellClick(cellData)}
          >
            <span className={styles.cellNumber}>{position}</span>
          </div>
        );
        position++;
      }
      grid.push(<div key={i} className={styles.gridRow}>{row}</div>);
    }

    return grid;
  };

  const renderSelectedCells = () => {
    if (!selectedOrganoidKey) return null;
    const backgroundColor = getColorForOrganoid(selectedOrganoidKey);
    const selectedCells = boxData.filter(d => d.Organoid_Info_Key === selectedOrganoidKey);
    const cellStyle = `${styles.gridCell2} ${styles.occupiedCell}`;
    return (
      <div className={styles.selectedList}>
        {selectedCells.map((cell, index) => (
          // <div key={index} className={styles.selectedItem}>
          //   {cell.Position}: {cell.Organoid_Info_Key}
          // </div>
          <div
            key={index}
            className={cellStyle}
            style={{ backgroundColor }}
          >
            <span className={styles.cellNumber}>{cell.Position}</span>
          </div>
        ))}
      </div>
    );
  };

  // 삭제 후 데이터를 새로고침하는 콜백 함수
  const handleDelete = () => {
    setRefresh(refresh + 1);
  };

  return (
    <div className={styles.storageBoxMain}>
      <div className={styles.leftBox}>
        <div className={styles.vesselName}>Vessel-{vessel}</div>
        <div className={styles.lineBoxContainer}>
          <div className={styles.lineBoxName}>Line: {line}</div>
          <div className={styles.lineBoxName}>Box: {box}</div>
        </div>
        <div className={styles.memo}>
          Memo : {boxMemo}<img src={editPen} className={styles.editPen} onClick={onEditPenClick} />
        </div>
        {
          editMemo &&
          <EditBoxMemo onClose={closeEditMemoModal} onSubmit={saveMemo} />
        }
        <div className={styles.gridContainer}>{renderGrid()}</div>
        <div className={styles.downloadDiv}>
          <button className={styles.excelDownloadButton} onClick={handleSubmit}>
            <img src={downloadIcon} className={styles.downloadIcon} /> &nbsp;download
          </button>
        </div>
        {renderSelectedCells()}
      </div>
      <div className={styles.rightBox}>
        <BankingInfoTab data={selectedData} authLevel={authLevel} onDelete={handleDelete} />
      </div>
    </div>
  );

}

export default StorageBox;