// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BankingInfoTab_bankingTabMain__8NGMe {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
}

.BankingInfoTab_title__3N9Oy {
    display: grid;
    grid-template-columns: 70% 30%;
    width: 90%;
    border-bottom: solid 3px #dedede;
    padding-bottom: 15px;
    color: #7B878C;
    font-weight: 600;
    font-size: 140%;
}

.BankingInfoTab_editSaveButtonGroup__pXECH {
    height: 30px;
    font-size: 15px;
    text-align: right;
    display: flex;
    align-items: center;
    /* 추가: 버튼들을 상하 중앙으로 정렬 */
    justify-content: flex-end;
    /* 추가: 버튼들을 우측으로 정렬 */
}

.BankingInfoTab_gridBox__SG7Ou {
    padding-top: 30px;
    width: 90%;
    display: grid;
    grid-template-columns: 40% 60%;
}

.BankingInfoTab_leftTitle__DALQi {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    border-right: solid 3px #dedede;
    color: #7B878C;
}

.BankingInfoTab_bankingInput__JLwHy {
    margin-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/organoid/rightTab/BankingInfoTab.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,UAAU;IACV,gCAAgC;IAChC,oBAAoB;IACpB,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,wBAAwB;IACxB,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,UAAU;IACV,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;IAChB,+BAA+B;IAC/B,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":[".bankingTabMain {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 0;\n    margin: 0;\n}\n\n.title {\n    display: grid;\n    grid-template-columns: 70% 30%;\n    width: 90%;\n    border-bottom: solid 3px #dedede;\n    padding-bottom: 15px;\n    color: #7B878C;\n    font-weight: 600;\n    font-size: 140%;\n}\n\n.editSaveButtonGroup {\n    height: 30px;\n    font-size: 15px;\n    text-align: right;\n    display: flex;\n    align-items: center;\n    /* 추가: 버튼들을 상하 중앙으로 정렬 */\n    justify-content: flex-end;\n    /* 추가: 버튼들을 우측으로 정렬 */\n}\n\n.gridBox {\n    padding-top: 30px;\n    width: 90%;\n    display: grid;\n    grid-template-columns: 40% 60%;\n}\n\n.leftTitle {\n    padding-top: 10px;\n    padding-bottom: 10px;\n    font-weight: 600;\n    border-right: solid 3px #dedede;\n    color: #7B878C;\n}\n\n.bankingInput {\n    margin-left: 15px;\n    padding-top: 10px;\n    padding-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bankingTabMain": `BankingInfoTab_bankingTabMain__8NGMe`,
	"title": `BankingInfoTab_title__3N9Oy`,
	"editSaveButtonGroup": `BankingInfoTab_editSaveButtonGroup__pXECH`,
	"gridBox": `BankingInfoTab_gridBox__SG7Ou`,
	"leftTitle": `BankingInfoTab_leftTitle__DALQi`,
	"bankingInput": `BankingInfoTab_bankingInput__JLwHy`
};
export default ___CSS_LOADER_EXPORT___;
