import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function EditPatientInfo_public({ showModal, setShowModal, patientInfo, setPatientInfoPublic }) {
    const defaultState = {
        Gender: '',
        Age: '',
        Stage: '',
        HistologicType: '',
        Site: '',
        SpecialFeature: '',
        TreatmentHistory: ''
    };
    const [formData, setFormData] = useState(patientInfo || defaultState);
    useEffect(() => {
        if (patientInfo) {
            setFormData(patientInfo);
        }
    }, [patientInfo]);
    const [showOpinionModal, setShowOpinionModal] = useState(false);
    const [draftOpinion, setDraftOpinion] = useState("");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = () => {
        // Update patientInfoPublic in parent component
        setPatientInfoPublic(prevState => ({
            ...prevState,
            ...formData,
            draftOpinion
        }));
        const dataToSend = {
            ...formData,
            draftOpinion
        };
        const reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(dataToSend),
        };
        fetch('/api/updatePatientInfoPub', reqOption)
            .then(response => response.json())
            .then(data => {
                alert(data.message);
            });
        setShowOpinionModal(false);
        setShowModal(false);
    }

    return (
        <>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>환자 정보 수정</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Gender</Form.Label>
                            <Form.Control type="text" name="Gender" value={formData.Gender} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Age</Form.Label>
                            <Form.Control type="text" name="Age" value={formData.Age} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Stage</Form.Label>
                            <Form.Control type="text" name="Stage" value={formData.Stage} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Histologic type</Form.Label>
                            <Form.Control type="text" name="HistologicType" value={formData.HistologicType} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Site</Form.Label>
                            <Form.Control type="text" name="Site" value={formData.Site} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Special feature</Form.Label>
                            <Form.Control type="text" name="SpecialFeature" value={formData.SpecialFeature} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Treatment History</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="TreatmentHistory"
                                value={formData.TreatmentHistory}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        닫기
                    </Button>
                    <Button variant="primary" onClick={() => setShowOpinionModal(true)}>
                        기안 등록
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showOpinionModal} onHide={() => setShowOpinionModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>기안의견 입력</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>기안의견</Form.Label>
                        <Form.Control as="textarea" rows={3} value={draftOpinion} onChange={(e) => setDraftOpinion(e.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowOpinionModal(false)}>
                        취소
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        기안 등록
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditPatientInfo_public;
