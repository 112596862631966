import React from "react";
import './Image.css';

function Image(props) {
    var name = props.picture.picture_key;
    var nameString = "";
    var str = String(props.picture.picture_key);
    str = str.replace('.jpg', '');
    str = str.replace('.jpeg', '');
    str = str.replace(String(props.picture.cancer_data_Key) + '_', '');
    var first = '';
    var second = '';
    var third = '';
    var arr = str.split('_');
    first = arr[0];
    for (var i = 1; i < arr.length; i++) {
        if (arr[i].charAt(0) == 'P') {
            second = arr[i];
            break;
        }
    }
    for (var i = 1; i < arr.length; i++) {
        if (arr[i].charAt(arr[i].length - 1) == 'X') {
            third = arr[i];
            break;
        }
    }
    if (second == "P99") {
        var result = first + '_' + third;
    } else {
        var result = first + '_' + second + '_' + third;
    }
    // var arr = str.split('X_');
    // console.log(arr);
    // console.log("arrarrarr");
    // var tmp = "";
    // if (arr.length >= 2) {
    //     for (var i = 0; i < arr.length - 1; i++) {
    //         if (i != 0) {
    //             tmp += 'X_';
    //         }
    //         tmp += arr[i];
    //     }
    //     tmp += 'X';
    //     str = tmp;
    // }
    // arr = str.split("_");
    // if (arr.length == 4) {
    //     str = arr[0] + '_' + arr[1] + '_' + arr[3];
    // }



    // console.log(props.picture.picture);
    const newBlob = new Blob([new Uint8Array(props.picture.picture?.data)], { type: 'image/jpg' });

    // console.log(props.picture.picture);
    const url = URL.createObjectURL(newBlob);

    return (
        <div className="imageOne">
            <img src={url} width="200px"></img>
            <div className="imageSetting">{result}</div>
        </div>
    )
}

export default Image;