import React, { useEffect, useState, useCallback, Component } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import RightTab from "./right_tab/RightTab";


function Navi(props) {

    const location = useLocation().pathname;

    const navigate = useNavigate();
    useEffect(() => {
        navigate(location.replace('1/', ''));
    }, [])

    return (
        <div>
        </div>
    );
}

export default Navi;