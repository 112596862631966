import React, { useEffect, useState } from "react";
// CSS 모듈 import 방식으로 변경
import styles from "./PDO.module.css";
import PDOModel from "./PDOModel";
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import downTriangleIcon from "../../img/icon/DownTriangleIcon.png";
import sideTriangleIcon from "../../img/icon/SideTriangleIcon.png";

function PDO(props) {
    const [visibleFirst, setVisibleFirst] = useState(true);
    const [category, setCategory] = useState([{
        category: '',
    }]);

    useEffect(() => {
        let reqOption = {
            method: "get",
            headers: {
                "content-type": "application/json"
            },
        }

        fetch("/api/GetPDOCategory", reqOption)
            .then((response) => response.json())
            .then(data => {
                setCategory(data);
            });
    }, [props.search]);

    function checkZero(num) {
        if (num === 0 || num === '') {
            return '';
        } else {
            return "(" + num + ")";
        }
    }
    function returnFalse(value) {
        props.returnFalse(value);
    }

    return (
        <>
            <div className={styles.title}>
                <span className={styles.outlinePdo} onClick={(e) => {
                    setVisibleFirst(!visibleFirst);
                }}>
                    {!visibleFirst && <img className={styles.plusImg} src={sideTriangleIcon} alt="sideTriangleIcon" />}
                    {visibleFirst && <img className={styles.minusImg} src={downTriangleIcon} alt="downTriangleIcon" />}
                </span>
                <span className={styles.pdoModels} onClick={(e) => {
                    setVisibleFirst(!visibleFirst);
                }}>
                    PDO models
                </span>
                <span className={styles.PDOnumber}>{checkZero(props.num.num)}</span>
            </div>
            <div className={styles.pdoBox}>
                {category.map((map, index) => (
                    visibleFirst && <PDOModel name={map.category} search={props.search} returnFalse={returnFalse} key={index}></PDOModel>
                ))}
            </div>
        </>
    );
}

export default PDO;
