// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StockTracing_container__N5iLN {
    width: 20vw;
    display: flex;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 80vw;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    /* Add padding for the title */
    overflow-y: auto;
}

.StockTracing_box__5VlZS {
    width: 200px;
    height: 50px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    /* Adjust the gap between boxes */
    cursor: pointer;
    background-color: #f7f7f7;
    /* Light gray background */
    border-radius: 5px;
    /* Rounded corners */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    /* Box shadow for a bit of depth */
}

.StockTracing_line__uL9AV {
    width: 2px;
    height: 20px;
    background-color: blue;
    margin: 0 auto;
    /* This will center the line horizontally */
}


.StockTracing_title__\\+69MW {
    font-size: 24px;
    /* Adjust as needed */
    font-weight: bold;
    margin-bottom: 20px;
    /* Add a gap after the title */
}`, "",{"version":3,"sources":["webpack://./src/organoid/rightTab/StockTracing.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,wBAAmB;IAAnB,mBAAmB;IACnB,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;IACnB,iBAAiB;IACjB,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,iCAAiC;IACjC,eAAe;IACf,yBAAyB;IACzB,0BAA0B;IAC1B,kBAAkB;IAClB,oBAAoB;IACpB,0CAA0C;IAC1C,kCAAkC;AACtC;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,sBAAsB;IACtB,cAAc;IACd,2CAA2C;AAC/C;;;AAGA;IACI,eAAe;IACf,qBAAqB;IACrB,iBAAiB;IACjB,mBAAmB;IACnB,8BAA8B;AAClC","sourcesContent":[".container {\n    width: 20vw;\n    display: flex;\n    height: fit-content;\n    max-height: 80vw;\n    flex-direction: column;\n    align-items: center;\n    padding-top: 20px;\n    /* Add padding for the title */\n    overflow-y: auto;\n}\n\n.box {\n    width: 200px;\n    height: 50px;\n    border: 1px solid black;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 10px 0;\n    /* Adjust the gap between boxes */\n    cursor: pointer;\n    background-color: #f7f7f7;\n    /* Light gray background */\n    border-radius: 5px;\n    /* Rounded corners */\n    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);\n    /* Box shadow for a bit of depth */\n}\n\n.line {\n    width: 2px;\n    height: 20px;\n    background-color: blue;\n    margin: 0 auto;\n    /* This will center the line horizontally */\n}\n\n\n.title {\n    font-size: 24px;\n    /* Adjust as needed */\n    font-weight: bold;\n    margin-bottom: 20px;\n    /* Add a gap after the title */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `StockTracing_container__N5iLN`,
	"box": `StockTracing_box__5VlZS`,
	"line": `StockTracing_line__uL9AV`,
	"title": `StockTracing_title__+69MW`
};
export default ___CSS_LOADER_EXPORT___;
