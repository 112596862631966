import React, { useState, useEffect } from 'react';
import parseText from './Parse';  // parseText 함수 임포트
import ResponsePlot from './ResponsePlot';
import styles from './DrugResponsePage.module.css';
import { useParams } from 'react-router-dom';
import ErrorBarGraph from './ErrorBarGraph';

// App 컴포넌트 정의
const DrugResponsePage = () => {
  const [chemicalData, setChemicalData] = useState(null);  // 초기 상태를 null로 설정
  const [loading, setLoading] = useState(true);  // 로딩 상태 추가

  const { tmpKey } = useParams();

  const handleFileRead = (text) => {
    const { processedData } = parseText(text);
    setChemicalData(processedData);
    setLoading(false);  // 데이터가 로드되면 로딩 상태를 false로 변경
  };

  useEffect(() => {
    let reqOption = {
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        param: tmpKey
      }),
    };

    fetch("/api/getChemicalData", reqOption)
      .then((response) => response.json())
      .then(data => {
        if (data && data.length > 0 && data[0]?.responsValue) {
          handleFileRead(data[0].responsValue);
        } else {
          setLoading(false);  // 데이터가 없을 때도 로딩 상태를 false로 변경
        }
      })
      .catch(() => setLoading(false));  // 오류 발생 시에도 로딩 상태를 false로 변경
  }, [tmpKey]);

  // 데이터가 없거나 로딩 중일 때 처리
  if (loading) {
    return <div>Loading...</div>;  // 데이터를 불러오는 중일 때
  }

  if (!chemicalData) {
    return <div>No data available.</div>;  // 데이터가 없을 때
  }

  // 데이터가 있을 때
  return (
    <div className={styles.drugResponsePageMain}>
      {/* <ResponsePlot data={chemicalData} /> */}
      <ErrorBarGraph data={chemicalData} />
    </div>
  );
};

export default DrugResponsePage;
