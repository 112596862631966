import React from "react";
import organoid from "./img/pdoMainPicture.jpg";
import './MobileMainImage.css';
import { useMediaQuery } from 'react-responsive'


function MobileMainImage() {
    // const isDeskop = useMediaQuery({ minDeviceWidth: 1224 });

    // console.log(isDeskop);

    return (
        <div className="mobileMainImage">
            <img src={organoid} className="mobileMainPicture" />
        </div>
    )
}

export default MobileMainImage;