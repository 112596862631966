// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* editLineNameModal.module.css */
.editLineNameModal_modal__KefUI {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    outline: none;
}

.editLineNameModal_overlay__XZz2q {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
}

.editLineNameModal_container__yOEFZ {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.editLineNameModal_textBox__LnJ6p {
    width: 100%;
    height: 50px;
    padding: 10px;
    margin-bottom: 20px;
}

.editLineNameModal_buttons__\\+HYpw {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.editLineNameModal_confirmButton__79y7t,
.editLineNameModal_cancelButton__3O017 {
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.editLineNameModal_confirmButton__79y7t {
    background-color: #4CAF50;
    color: white;
}

.editLineNameModal_cancelButton__3O017 {
    background-color: #f44336;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/organoid/leftTab/editLineNameModal.module.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,YAAY;IACZ,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;AACf;;AAEA;;IAEI,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":["/* editLineNameModal.module.css */\n.modal {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 300px;\n    background-color: white;\n    padding: 20px;\n    border-radius: 4px;\n    outline: none;\n}\n\n.overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 1001;\n}\n\n.container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.textBox {\n    width: 100%;\n    height: 50px;\n    padding: 10px;\n    margin-bottom: 20px;\n}\n\n.buttons {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.confirmButton,\n.cancelButton {\n    padding: 5px 15px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 16px;\n}\n\n.confirmButton {\n    background-color: #4CAF50;\n    color: white;\n}\n\n.cancelButton {\n    background-color: #f44336;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `editLineNameModal_modal__KefUI`,
	"overlay": `editLineNameModal_overlay__XZz2q`,
	"container": `editLineNameModal_container__yOEFZ`,
	"textBox": `editLineNameModal_textBox__LnJ6p`,
	"buttons": `editLineNameModal_buttons__+HYpw`,
	"confirmButton": `editLineNameModal_confirmButton__79y7t`,
	"cancelButton": `editLineNameModal_cancelButton__3O017`
};
export default ___CSS_LOADER_EXPORT___;
