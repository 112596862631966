import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useEffect, Component, useLayoutEffect } from 'react';
import { Link, BrowserRouter, Routes, Route, json } from 'react-router-dom';
import plus from "../../img/common/plusIcon.png";
import minus from "../../img/common/minusIcon.png";
import styles from "./PatientName.module.css";

function PatientName(props) {
    function hashmark(str) {
        return String(str).replace("hashmark", "#");
    }
    const [visibleFirst, setVisibleFirst] = useState(false);

    const [patientInfo, setPatientInfo] = useState([{}]);
    let str = '';
    if (props.search != '') {
        str = "AND `PatientCode` LIKE '%" + props.search + "%'";
    }
    // useEffect(() => {
    //     let reqOption = {
    //         method: "post",
    //         headers: {
    //             "content-type": "application/json"
    //         },
    //         body: JSON.stringify({
    //             param: str,
    //         })
    //     }

    //     fetch("/api/getPatientInfoList", reqOption)
    //         .then((response) => response.json())
    //         .then(data => {
    //             setPatientInfo(data);
    //         });
    // }, [props.search]);
    let num = "(" + patientInfo.length + ")";
    if (num === "()" || num === "(0)") {
        num = "";
    }

    return (
        <div className={styles.PatientListSecond}>
            <div onClick={(e) => {
                setVisibleFirst(!visibleFirst);
            }}>
                <Link to={"/patient/" + props.name} className={styles.PatientListSecondTitle}>{hashmark(props?.name)}</Link>
                {/* <Link to={"/organoid/" + props.data['Key']} className="customLink">
                    {hashmark(props.data['Key'])}
                </Link> */}
            </div>
        </div>
    );
}

export default PatientName;
