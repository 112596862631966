import React, { useEffect, useState } from "react";
import "./MobileRightTab1.css";
import { useParams, } from 'react-router-dom';
import MobileSummaryData from "./MobileSummaryData";
import MobilePatientData from "./MobilePatientData";
import { useLocation } from 'react-router-dom';
import MobileImageLightMicroscope from './MobileImageLightMicroscope';
import MobileGraph from './MobileGraph';
import MobileImagePathologyPicture from './MobileImagePathologyPicture';

function MobileRightTab1(props) {

	const [graphData, setGraphData] = useState([{
	}]);

	const { tmpKey } = useParams();

	useEffect(() => {
		let reqOption = {
			method: "post",
			headers: {
				"content-type": "application/json"
			},
			body: JSON.stringify({
				name: tmpKey
			}),

		}

		fetch("/api/getGraphData", reqOption)
			.then((response) => response.json())
			.then(data => {
				setGraphData(data);
			});
	}, []);
	const { pathname } = useLocation();

	// useEffect(() => {
	// 	window.scrollTo(0, 0);
	// }, [pathname]);

	const [authNum, setAuthNum] = useState({});

	useEffect(() => {
		let reqOption = {
			method: "post",
			headers: {
				"content-type": "application/json"
			},
			body: JSON.stringify({
				name: tmpKey
			}),
		}

		fetch("/api/getAuth", reqOption)
			.then((response) => response.json())
			.then(data => {
				setAuthNum(data);
			});
	}, []);
	const level = authNum[0];

	return (
		<div className="mobileRightTab">
			<div>
				<div className="mobileTitleRight">Summary</div>
				<div className="mobileSummaryBox">
					<MobileSummaryData cancerKey={tmpKey} level={level}></MobileSummaryData>
				</div>
				<div className="mobileTitleRight">Patient Information</div>
				<div className="mobileTitleBox">
					<MobilePatientData cancerKey={tmpKey} level={level}></MobilePatientData>
				</div>
				<div className="mobileTitleRight">PDO Image(Bright Field)</div>
				<div>
					<MobileImageLightMicroscope cancerKey={tmpKey} level={level}></MobileImageLightMicroscope>
				</div>
				<div className="mobileTitleRight">PDO Histology</div>
				<div>
					<MobileImagePathologyPicture cancerKey={tmpKey} level={level}></MobileImagePathologyPicture>
				</div>
				<div className="mobileTitleRight">Drug Responsiveness</div>
				<div>
					<MobileGraph graphData={graphData} level={level}></MobileGraph>
				</div>
			</div>
		</div>
	);
}



export default MobileRightTab1;