import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styles from './EditBasicInfoModal.module.css';
import { useTranslation } from 'react-i18next';

function EditBasicInfoModal({ showModal, setShowModal, formData, handleInputChange }) {
    const { t } = useTranslation();
    const [draftOpinion, setDraftOpinion] = useState("");
    const [showOpinionModal, setShowOpinionModal] = useState(false);
    const handleSubmit = () => {
        const updatedFormData = {
            ...formData,
            draftOpinion // Add the draft opinion to formData
        };
        const reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(updatedFormData),
        };
        fetch('/api/updateOraganoidInfo', reqOption)
            .then(response => response.json())
            .then(data => {
                alert(data.message);
            });
        setShowModal(false);
        setShowOpinionModal(false);
    }

    function hashMark(str) {
        return (String(str).replace('hashmark', '#'));
    }

    return (
        <>
            <Modal show={showModal} onHide={() => setShowModal(false)} className={styles['modal-dialog1']}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('오가노이드 정보 수정')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Code</Form.Label>
                            <Form.Control type="text" name="Key" value={hashMark(formData.Key)} onChange={handleInputChange} disabled />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Sample type</Form.Label>
                            <Form.Control type="text" name="SampleType" value={formData.SampleType} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Organism</Form.Label>
                            <Form.Control type="text" name="Organism" value={formData.Organism} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Cancer type</Form.Label>
                            <Form.Control type="text" name="Type" value={formData.Type} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Subtype</Form.Label>
                            <Form.Control type="text" name="Subtype" value={formData.Subtype} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Orgin</Form.Label>
                            <Form.Control type="text" name="Orgin" value={formData.Orgin} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Pair</Form.Label>
                            <Form.Control type="text" name="Pair" value={formData.Pair} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Mutation Information</Form.Label>
                            <Form.Control type="text" name="MutationInformation" value={formData.MutationInformation} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Surgery Date</Form.Label>
                            <Form.Control type="text" name="surgeryDate" value={formData.surgeryDate} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Establishment no.</Form.Label>
                            <Form.Control type="text" name="establishmentNo" value={formData.establishmentNo} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>NGS Status</Form.Label>
                            <Form.Control type="text" name="NGSStauts" value={formData.NGSStatus} onChange={handleInputChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        닫기
                    </Button>
                    <Button variant="primary" onClick={() => setShowOpinionModal(true)}>
                        기안 등록
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showOpinionModal} onHide={() => setShowOpinionModal(false)} className={styles['modal-dialog2']}>
                <Modal.Header closeButton>
                    <Modal.Title>기안의견 입력</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>기안의견</Form.Label>
                        <Form.Control as="textarea" rows={3} value={draftOpinion} onChange={(e) => setDraftOpinion(e.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowOpinionModal(false)}>
                        취소
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        기안 등록
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditBasicInfoModal;
